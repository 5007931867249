import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';
import {getAllBranches} from '../../../actions/branchActions';
import LoadingButton from '../../includes/LoadingButton';
import InlineLoading from '../../includes/InlineLoading';
import {formatBranchDisplayText} from "../../../helpers/helpers";
import {getAllUserGroups, getAllUserGroupsPrivileges} from "../../../actions/userGroupActions";
import {loggedInUser} from "../../../helpers/authHelper";

// Material UI imports
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';


export const UserForm = ({
                             user,
                             updateParent,
                             formRef,
                             allFormErrors,
                             passMissMatchError,
                             btnDisabled,
                             submitFunction,
                             updating = false,
                         }) => {

    const dispatch = useDispatch();
    const [filteredGroups, setFilteredGroups] = useState([]);


    const {allBranches, allUserGroups, userGroupsPrivileges, loading} = useSelector(state => (
        {
            allBranches: state.branch.allBranches,
            allUserGroups: state.userGroups.allUserGroups,
            userGroupsPrivileges: state.userGroups.userGroupsPrivileges,
            loading: state.loading,
        }
    ));

    // Get available branches and user groups on page load
    const loadRessources = useRef(() => {
        dispatch(getAllBranches());
        dispatch(getAllUserGroups());
        dispatch(getAllUserGroupsPrivileges());
    });

    // Get logged in user
    const loggedIn = loggedInUser();

    const userGroupsRef = useRef((adminGroups, allGroups) => {
        if (!adminGroups.length || !allGroups.length) return;

        // Remove admin group if user is not an admin
        if (!adminGroups.map(group => group._id).includes(loggedIn?.userGroup._id)) {
            setFilteredGroups(allGroups.filter(group => !adminGroups.map(group => group._id).includes(group._id)));
        } else {
            setFilteredGroups(allGroups);
        }
    }, []);

    useEffect(() => {
        // Get all admin groups (all privileges are true)
        const adminGroups = allUserGroups.filter(group => {

            // Find privileges which are not true
            let isAdmin = true;

            if (Object.keys(group.privileges).length !== Object.keys(userGroupsPrivileges).length) {
                isAdmin = false;
                return isAdmin;
            }

            Object.keys(group.privileges).forEach(privilege => {
                if (!group.privileges[privilege]) {
                    isAdmin = false;
                }
            });

            return isAdmin;
        });

        userGroupsRef.current(adminGroups, allUserGroups);

    }, [allUserGroups, userGroupsPrivileges]);


    useEffect(() => {
        loadRessources.current();
    }, []);

    if (loading) {
        return <InlineLoading/>
    }

    return (

        <form id="user-form" ref={formRef}>
            <div className="row">
                <div className="gr-24 gr-12@small">
                    <FormGroup>
                        <TextField
                            type="text"
                            required
                            label="Benutzername"
                            inputProps={{pattern: '[a-zA-Z0-9]+'}}
                            variant="filled"
                            name="username"
                            value={user?.username ?? ''}
                            error={allFormErrors.includes('username')}
                            helperText={allFormErrors.includes('username') ? "Bitte Benutzernamen ohne Sonderzeichen eingeben" : ""}
                            onChange={e => updateParent('username', e.target.value?.toLowerCase())}
                        /></FormGroup>
                </div>
                <div className="gr-24 gr-12@small">
                    {filteredGroups.length > 0 && <FormGroup>
                        <FormControl
                            required
                            error={allFormErrors.includes('userGroup')}>
                            <InputLabel id="branch-select-label">Benutzergruppe</InputLabel>

                            <Select labelId="group-select-label"
                                    style={{height: '40px'}}
                                    id="group-select-label"
                                    value={user?.userGroup ?? ''}
                                    onChange={e => {
                                        updateParent('userGroup', e.target.value)
                                    }}>

                                {filteredGroups?.map(group =>
                                    <MenuItem key={group._id}
                                              selected={group._id === user?.userGroup}
                                              value={group._id}>
                                        {group.groupTitle}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </FormGroup>}
                </div>

                <div className="gr-24 gr-12@small">
                    <FormGroup>
                        <TextField
                            label="Vorname"
                            inputProps={{pattern: '^[a-zA-Z0-9_ ]*$'}}
                            variant="filled"
                            name="firstname"
                            value={user?.firstname ?? ''}
                            error={allFormErrors.includes('firstname')}
                            onChange={e => updateParent('firstname', e.target.value)}
                            helperText={allFormErrors.includes('firstname') ? "Bitte nur Buchstaben verwenden" : ""}
                        /></FormGroup>
                </div>
                <div className="gr-24 gr-12@small">
                    <FormGroup>
                        <TextField
                            label="Nachname"
                            inputProps={{pattern: '^[a-zA-Z0-9_ ]*$'}}
                            variant="filled"
                            name="surname"
                            value={user?.surname ?? ''}
                            onChange={e => updateParent('surname', e.target.value)}
                            error={allFormErrors.includes('surname')}
                            helperText={allFormErrors.includes('surname') ? "Bitte nur Buchstaben verwenden" : ""}

                        /></FormGroup>
                </div>
                <div className="gr-24 gr-12@small">
                    <FormGroup>
                        <TextField
                            label="Passwort"
                            type="password"
                            required={!updating}
                            inputProps={{
                                pattern: '(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[+-_@#$%&]).{8,}',
                                autoComplete: 'on'
                            }}
                            variant="filled"
                            name="password"
                            value={user?.password ?? ''}
                            error={passMissMatchError || allFormErrors.includes('password')}
                            onChange={e => updateParent('password', e.target.value)}
                            helperText={allFormErrors.includes('password') ? "Bitte mindestens 8 Zeichen sowie einen Groß- und Kleinbuchstaben verwenden." : ""}
                        /></FormGroup>
                </div>
                <div className="gr-24 gr-12@small">
                    <FormGroup>
                        <TextField
                            label="Passwort bestätigen"
                            type="password"
                            required={!updating}
                            inputProps={{
                                pattern: '(?=.*[a-z])(?=.*[A-Z])(?!.* )(?=.*[+-_@#$%&]).{8,}',
                                autoComplete: 'on'
                            }}
                            variant="filled"
                            name="password_confirm"
                            value={user?.passwordConfirm ?? ''}
                            error={passMissMatchError}
                            helperText={passMissMatchError ? "Passwörter stimmen nicht überein" : ""}
                            onChange={e => updateParent('passwordConfirm', e.target.value)}
                        />
                    </FormGroup>

                </div>

                <div className="gr-24 gr-12@small suffix-12@small">
                    {allBranches.length > 0 && <FormGroup>
                        <FormControl>
                            <InputLabel id="branch-select-label">Filiale</InputLabel>

                            <Select labelId="branch-select-label"

                                    id="branch-select-label"
                                    value={user?.branch ?? ''}
                                    onChange={e => updateParent('branch', e.target.value)}>

                                <MenuItem value={null}
                                          selected={!user?.branch}
                                >Keine Filiale</MenuItem>

                                {allBranches?.map(branch =>
                                    <MenuItem key={branch._id}
                                              selected={branch._id === user?.branch}
                                              value={branch._id}>
                                        {formatBranchDisplayText(branch)}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </FormGroup>}
                </div>

                {/* <div className="gr-24 gr-12@small">
                    <FormControlLabel
                        label="Ist Administrator"
                        control={
                            <Checkbox
                                checked={user?.isAdmin ?? false}
                                name="isAdmin"
                                color="default"
                                onChange={() => updateParent('isAdmin', !user?.isAdmin)}
                            />
                        }
                    />
                </div>*/}

                <div className="gr-24 gr-12@small">
                    <FormControlLabel
                        label="Login aktiv"
                        control={
                            <Checkbox
                                checked={user?.active ?? true}
                                name="active"
                                color="default"
                                onChange={() => updateParent('active', !user?.active)}
                            />
                        }
                    />
                </div>
            </div>
            <div className="gr-24 tac">
                <Box mt={2} mb={5}>
                    <LoadingButton type="submit" color="primary"
                                   onClick={submitFunction}
                                   form={'user-form'}
                                   disabled={btnDisabled}
                                   text={updating ? 'Aktualisieren' : 'Speichern'}
                    />
                </Box>
            </div>
        </form>
    )
}

export default UserForm;
