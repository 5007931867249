import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {updateTaskConfig} from "../../../../helpers/taskHelpers";


const TireSelection = ({task, taskConfig, setTaskConfig, updateMainTask}) => {
    return (
        <div className="config__grid config__grid-mobile--2 config__grid--4">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={taskConfig.frontLeft}
                        onChange={() => {
                            updateTaskConfig('frontLeft', !taskConfig.frontLeft, setTaskConfig, taskConfig, updateMainTask, task)
                        }}
                        name="frontLeft"
                        color="default"
                    />
                }
                label="Vorne links"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={taskConfig.frontRight}
                        onChange={() => updateTaskConfig('frontRight', !taskConfig.frontRight, setTaskConfig, taskConfig, updateMainTask, task)}
                        name="frontRight"
                        color="default"
                    />
                }
                label="Vorne rechts"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={taskConfig.backLeft}
                        onChange={() => updateTaskConfig('backLeft', !taskConfig.backLeft, setTaskConfig, taskConfig, updateMainTask, task)}
                        name="backLeft"
                        color="default"
                    />
                }
                label="Hinten links"
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={taskConfig.backRight}
                        onChange={() => updateTaskConfig('backRight', !taskConfig.backRight, setTaskConfig, taskConfig, updateMainTask, task)}
                        name="backRight"
                        color="default"
                    />
                }
                label="Hinten rechts"
            />
        </div>
    );
};

export default TireSelection;
