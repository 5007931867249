/**
 * This component wraps the complete app and provides the feedback context.
 */
import InlineFeedback from "../components/includes/InlineFeedback";
import {clearSuccess} from "../actions/successActions";
import {clearErrors} from "../actions/errorActions";
import {useDispatch, useSelector} from "react-redux";

const FeedbackProvider = ({children}) => {
    const dispatch = useDispatch();

    const {error, success} = useSelector(state => ({
        error: state.error,
        success: state.success,
    }));

    return (
        <>
            <InlineFeedback error={false}
                            message={success?.msg}
                            open={!!success && success?.msg !== ''}
                            closeFunc={() => dispatch(clearSuccess())}/>


            <InlineFeedback message={error?.msg}
                            open={!!error}
                            error={true}
                            closeFunc={() => dispatch(clearErrors())}/>

            {children}
        </>
    );
};

export default FeedbackProvider;
