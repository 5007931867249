import {
    CLEAR_USER_GROUPS_LOADING,
    GET_USER_GROUPS,
    GET_USER_GROUPS_PRIVILEGES,
    USER_GROUPS_ERROR,
    USER_GROUPS_LOADING
} from "../actions/types";

const initialState = {
    // All user groups
    allUserGroups: [],
    userGroupsLoading: false,
    userGroupsPrivileges: {},
}

const userGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_GROUPS:
            return {
                ...state,
                allUserGroups: action.payload,
            }

        case GET_USER_GROUPS_PRIVILEGES:
            return {
                ...state,
                userGroupsPrivileges: action.payload,
            }

        case USER_GROUPS_ERROR:
            return {
                ...state,
                allUserGroups: [],
            }

        case USER_GROUPS_LOADING:
            return {
                ...state,
                userGroupsLoading: true,
            }

        case CLEAR_USER_GROUPS_LOADING:
            return {
                ...state,
                userGroupsLoading: false,
            }

        default:
            return state;
    }
}

export default userGroupReducer;