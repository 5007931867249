import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import UserForm from './UserForm';
import Loading from '../../includes/Loading';
import {validateForm} from '../../../helpers/helpers';
import {clearUser, getSingleUser, registerUser, updateUser} from '../../../actions/userActions';
import PageHeader from '../../includes/PageHeader';
import {useParams} from "react-router-dom";
import {checkUserPermission, loggedInUser} from "../../../helpers/authHelper";

// Material UI Imports
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import {logoutUser} from "../../../actions/authActions";
import NoAdmin from "../dashboard/NoAdmin";

export const ManageUser = () => {
    // Backend data handling
    const dispatch = useDispatch();


    const {loading, userToUpdate} = useSelector(state => (
        {
            loading: state.loading,
            userToUpdate: state.user.singleUser,
        }));

    // Form values
    const formRef = useRef(null);

    const [user, setUser] = useState({
        username: '',
        password: '',
        passwordConfirm: '',
        active: true
    });

    // User feedback & control flow
    const [allFormErrors, setAllFormErrors] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [passMissMatchError, setPassMissMatchError] = useState(false);
    const [selfUpdate, setSelfUpdate] = useState(loggedInUser()?.id === user?._id);

    // Check if there is an url param to update a user
    const {id} = useParams();

    const getUser = useRef(id => {
        dispatch(getSingleUser(id));
    });

    const removeUserData = useRef(() => {
        resetInputValues();
        dispatch(clearUser());
    });

    const handleLogout = async () => {
        await dispatch(logoutUser());
        window.location.reload();
    }

    useEffect(() => {
        if (id) {
            getUser.current(id);
        } else {
            removeUserData.current();
        }
    }, [id]);

    useEffect(() => {
        if (userToUpdate) {
            setUser(userToUpdate);
            setSelfUpdate(loggedInUser()?.id === userToUpdate._id);
        } else {
            removeUserData.current();
        }
    }, [userToUpdate]);

    const updateLocalUser = (index, value) => {
        setUser({...user, [index]: value});
    }

    const save = async e => {
        e.preventDefault();

        const {errors, inputsValid} = validateForm(formRef.current);
        setAllFormErrors(errors);

        if (!user.userGroup) {
            setAllFormErrors([...allFormErrors, 'userGroup']);
        }

        if (user.password && user.password !== user.passwordConfirm) {
            setPassMissMatchError(true);
            return;
        } else {
            setPassMissMatchError(false);
        }

        if (!inputsValid) return;

        user.username = user.username.toLowerCase();

        if (userToUpdate) {
            // If a user updates himself, he gets logged out
            await dispatch(updateUser(user));
        } else {
            await dispatch(registerUser(user));
        }


        if (userToUpdate) {
            resetPassword();

            if (selfUpdate) {
                // When a user updates himself, he gets logged out
                setTimeout(() => {
                    handleLogout();
                }, 3000);
            }


        } else {
            resetInputValues();
        }
    }

    // Reset all state items
    const resetInputValues = () => {
        setUser({
            username: '',
            password: '',
            passwordConfirm: '',
            branch: '',
            firstname: '',
            surname: '',
            isAdmin: false,
            active: true,
        });
    }

    const resetPassword = () => {
        setUser({
            ...user,
            password: '',
            passwordConfirm: '',
        });
    }

    // Enable submit btn when required fields are filled
    useEffect(() => {
        if ((!userToUpdate && (!user.password || !user.passwordConfirm)) || !user.username) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [user, userToUpdate, setBtnDisabled]);

    if (!checkUserPermission(loggedInUser(), 'editUsers') || (checkUserPermission(userToUpdate, 'isAdmin') && !checkUserPermission(loggedInUser(), 'isAdmin'))) {
        return <NoAdmin/>
    }

    return (

        <>
            <PageHeader pageTitle={`Benutzer ${userToUpdate ? "aktualisieren" : "erstellen"}`} backRoute="/admin"/>

            {loading ? <Loading/> :


                <div className="container">
                    <div className="row row-justify-center">


                        <div className="gr-24">
                            <UserForm
                                user={user}
                                formRef={formRef}
                                allFormErrors={allFormErrors}
                                passMissMatchError={passMissMatchError}
                                btnDisabled={btnDisabled}
                                submitFunction={save}
                                updateParent={updateLocalUser}
                                updating={userToUpdate}
                            />
                        </div>

                        {selfUpdate && <div className="gr-24 gr-16@small">
                            <Box mt={3}>
                                <Alert severity="info">
                                    Sie müssen Sich neu anmelden, wenn Sie Änderungen am eigenen
                                    Benutzer vornehmen.
                                </Alert>
                            </Box>
                        </div>}
                    </div>
                </div>}
        </>
    );
};
