import {useEffect, useRef, useState, useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    createUserGroup,
    deleteUserGroup,
    getAllUserGroups,
    getAllUserGroupsPrivileges,
    updateUserGroup
} from "../../../actions/userGroupActions";


// Material UI Imports
import {DataGridPro} from "@mui/x-data-grid-pro";
import PageHeader from "../../includes/PageHeader";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import {validateForm} from "../../../helpers/helpers";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoadingButton from "../../includes/LoadingButton";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

// Licensed Data Grid Pro
import {LicenseInfo} from '@mui/x-data-grid-pro';
import {checkUserPermission, loggedInUser} from "../../../helpers/authHelper";
import NoAdmin from "./NoAdmin";

LicenseInfo.setLicenseKey(
    'f46eec4ce6a948aab2dc3b60e13984d0T1JERVI6Mjk4MDQsRVhQSVJZPTE2NjM5NDQxMTEwMDAsS0VZVkVSU0lPTj0x',
);


const ManageUserGroups = () => {
    const user = loggedInUser();
    const dispatch = useDispatch();
    const [allFormErrors, setAllFormErrors] = useState([]);
    const [groupTitle, setGroupTitle] = useState('');
    const [privileges, setPrivileges] = useState({});
    const [groupId, setGroupId] = useState(null);
    const [toDelete, setToDelete] = useState(null);
    const [rows, setRows] = useState([]);

    const form = useRef(null);

    const {allUserGroups, userGroupsLoading, userGroupsPrivileges, success} = useSelector(state => ({
        allUserGroups: state.userGroups.allUserGroups,
        userGroupsLoading: state.userGroups.userGroupsLoading,
        userGroupsPrivileges: state.userGroups.userGroupsPrivileges,
        success: state.success,
    }));


    // Update privileges
    const handlePrivilegesChange = e => {
        e.preventDefault();

        setPrivileges({
            ...privileges,
            [e.target.value]: e.target.checked
        });
    }


    const save = async e => {
        e.preventDefault();

        const {errors, inputsValid} = validateForm(form.current);
        setAllFormErrors(errors);

        if (!inputsValid) return;

        if (groupId) {
            dispatch(updateUserGroup({
                _id: groupId,
                groupTitle,
                privileges,
            }))
        } else {
            dispatch(createUserGroup({
                groupTitle,
                privileges,
            }))
        }
    }

    const resetValues = () => {
        setGroupTitle('');
        setPrivileges(userGroupsPrivileges);
        setGroupId(null);
        setToDelete(null);
    }

    const handleDelete = async e => {
        e.preventDefault();
        dispatch(deleteUserGroup(toDelete));
    }

    const setGroupToUpdate = (param, e) => {
        e.preventDefault();

        const id = param.id;
        const group = allUserGroups.find(group => group._id === id);

        setGroupId(id);
        setGroupTitle(group.groupTitle);
        setPrivileges(group.privileges);
    }

    const setGroupToDelete = (param, e) => {
        e.preventDefault();

        const id = param.id;
        const group = allUserGroups.find(group => group._id === id);

        setToDelete(group);
    }
    // Check checkbox state if key is in privileges and true
    const checkCheckbox = key => {
        return !!privileges[key];
    }

    const loadAllGroups = useRef(() => {
        dispatch(getAllUserGroups());
    });

    const loadAllPrivileges = useRef(() => {
        dispatch(getAllUserGroupsPrivileges());
    });

    const resetCallBack = useCallback(() => {
        setGroupTitle('');
        setPrivileges(userGroupsPrivileges);
        setGroupId(null);
        setToDelete(null);
    }, [userGroupsPrivileges]);


    useEffect(() => {
        if (success) {
            resetCallBack();
        }
    }, [success, resetCallBack]);

    // Set privileges to state object as soon as they are loaded
    useEffect(() => {
        if (userGroupsPrivileges) {
            setPrivileges(userGroupsPrivileges);
        }
    }, [userGroupsPrivileges]);

    // Set data grid rows
    useEffect(() => {
        if (userGroupsLoading || allUserGroups.length === 0) return;

        const localGroups = [];

        allUserGroups?.forEach(group => {
            localGroups.push({
                ...group,
                id: group._id,
                edit: group,
                update: group,
            });
        });

        setRows(localGroups);
    }, [allUserGroups, setRows, userGroupsLoading]);


    // Fetch api
    useEffect(() => {
        loadAllGroups.current();
    }, []);

    useEffect(() => {
        loadAllPrivileges.current();
    }, []);


    // Data grid config
    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
        },
        {
            field: 'groupTitle',
            headerName: 'Gruppenname',
            width: 250,
        },
        {
            field: 'privileges',
            headerName: 'Berechtigungen',
            width: 430,
            valueFormatter: params => {
                const rights = [];

                Object.keys(params.value).forEach(key => {
                    if (params.value[key]) {
                        rights.push(key);
                    }
                });

                if (!rights.length && !Object.keys(privileges).length) return 'Keine Berechtigungen';

                if (rights.length === Object.keys(privileges).length) {
                    return 'Alle Berechtigungen';
                } else if (rights.length > 0) {
                    return rights.join(', ');
                } else {
                    return 'Keine Berechtigungen';
                }
            }
        },
        {
            field: 'update',
            width: 25,
            flex: true,
            headerClassName: 'hide__header',
            renderHeader: () => (<></>),
            renderCell: params => {
                return (<IconButton
                    color="secondary"
                    onClick={e => setGroupToUpdate(params, e)}
                    size="small">
                    <EditIcon/>
                </IconButton>)
            },
        },
        {
            field: 'delete',
            width: 25,
            flex: true,
            headerClassName: 'hide__header',
            renderHeader: () => (<></>),
            renderCell: params => {
                return (<IconButton
                    onClick={e => setGroupToDelete(params, e)}
                    size="small">
                    <DeleteIcon color="error"/>
                </IconButton>)
            },
        },
    ];

    if (!checkUserPermission(user, 'editUserGroups')) {
        return <NoAdmin/>
    }

    return (
        <>
            <PageHeader pageTitle="Benutzergruppen" backRoute="/admin"/>

            <Dialog open={!!toDelete} onClose={() => setToDelete(null)}>
                <DialogTitle>Gruppe «{toDelete?.groupTitle}» löschen</DialogTitle>
                <DialogContent>
                    <div className="page__text">
                        {toDelete?.groupTitle} wird gelöscht.
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setToDelete(null)} color="secondary">Abbrechen</Button>
                    <LoadingButton loading={userGroupsLoading} onClick={handleDelete} text="Bestätigen"/>
                </DialogActions>
            </Dialog>

            <div className="container">
                <div className="row">
                    <div className="gr-24">
                        <h1 className="page__title">Benutzergruppen</h1>
                        <DataGridPro
                            columns={columns}
                            rows={rows}
                            disableSelectionOnClick={true}
                            disableMultipleSelection={true}
                            autoHeight={true}
                            loading={userGroupsLoading}
                            onCellDoubleClick={setGroupToUpdate}
                            style={{width: '100%', marginBottom: '2rem'}}
                        />
                    </div>

                    <div className="gr-24">
                        <h2 className="page__subtitle">Benutzergruppe {groupId ? 'aktualisieren' : 'erfassen'}</h2>
                        <form onSubmit={save} id="user-group-form" ref={form}>
                            <div className="row">
                                <div className="gr-24 gr-12@small suffix-12@small">
                                    <FormGroup>
                                        <TextField
                                            label="Gruppenname"
                                            type="text"
                                            name="groupTitle"
                                            variant="filled"
                                            required
                                            inputProps={{pattern: '[a-zA-Z]+'}}
                                            value={groupTitle}
                                            error={allFormErrors.includes('groupTitle')}
                                            helperText={allFormErrors.includes('groupTitle') ? 'Gruppenname eingeben (Nur Buchstaben ohne Abstände)' : ''}
                                            onChange={e => setGroupTitle(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>

                                {Object.keys(userGroupsPrivileges)?.map(key => {
                                    return <div className="gr-24 gr-12@small" key={key}>
                                        <FormControlLabel
                                            label={key}
                                            control={<Checkbox/>}
                                            checked={checkCheckbox(key)}
                                            value={key}
                                            onChange={handlePrivilegesChange}
                                        />
                                    </div>
                                })}

                                <div className="gr-24">
                                    <div className="branch__buttons branch__buttons--mt">
                                        <LoadingButton type="submit"
                                                       loading={userGroupsLoading}
                                                       variant="contained"
                                                       text="Speichern"
                                                       color="primary"
                                                       onClick={save}/>
                                        <Button type="reset"
                                                variant="contained"
                                                color="default"
                                                onClick={resetValues}>Zurücksetzen</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageUserGroups;
