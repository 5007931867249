import {
    CLEAR_BRANCH_LOADING,
    BRANCH_ERROR,
    BRANCH_LOADING,
    GET_BRANCHES,
} from "../actions/types";

const initialState = {
    allBranches: [],
    singleBranch: {},
    branchLoading: false,
};

const branchReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRANCHES:
            return {
                ...state,
                allBranches: action.payload,
            }

        case BRANCH_ERROR:
            return {
                ...state,
                allBranches: [],
            }
        case BRANCH_LOADING:
            return {
                ...state,
                branchLoading: true,
            }
        case CLEAR_BRANCH_LOADING:
            return {
                ...state,
                branchLoading: false,
            }

        default:
            return {
                ...state,
            }
    }
}

export default branchReducer;