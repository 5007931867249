import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {formatDate} from "../../helpers/helpers";
import StatusChip from "../includes/StatusChip";
import {deleteTask} from "../../actions/taskActions";
import InlineFeedback from "../includes/InlineFeedback";
import {clearErrors} from "../../actions/errorActions";

// Material UI Imports
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import LoadingButton from "../includes/LoadingButton";


const DeleteTaskButton = ({task}) => {
    const dispatch = useDispatch();

    const [showDialog, setShowDialog] = useState(false);
    const [jobs, setJobs] = useState('');

    const {error, loading} = useSelector(state => (
        {
            error: state.error,
            loading: state.tasks.taskloading,
        }
    ));

    useEffect(() => {
        const tasks = task.tasks;
        let jobsString = '';

        task.tasks.forEach((item, index) => {
            if (index < tasks.length - 1) {
                jobsString += `${item.task.title}, `
            } else {
                jobsString += `${item.task.title}`
            }
        });

        setJobs(jobsString);
    }, [task]);

    const handleDelete = async () => {
        await dispatch(deleteTask(task));
    }

    return (
        <>
            {task && <Dialog open={showDialog} fullWidth={true}>
                <DialogTitle>
                    Auftrag {task.taskId} löschen?
                </DialogTitle>

                <DialogContent>
                    <ul className="delete-task__list">
                        <li className="list__item">
                            <span className="item__title">Erstellt</span>
                            <span className="item__value">{formatDate(task.createdAt)}</span>
                        </li>

                        {task.state && <li className="list__item">
                            <span className="item__title">Status</span>
                            <span className="item__value"><StatusChip size="small" state={task.state}/></span>
                        </li>}

                        {task.paymentState && <li className="list__item">
                            <span className="item__title">Bezahlstatus</span>
                            <span className="item__value"><StatusChip size="small" state={task.paymentState}/></span>
                        </li>}

                        <li className="list__item">
                            <span className="item__title">Aufgaben</span>
                            <span className="item__value">{jobs}</span>
                        </li>

                    </ul>

                    <InlineFeedback
                        open={!!error}
                        error={true}
                        message={error?.msg}
                        closeFunc={() => dispatch(clearErrors())}
                    />


                </DialogContent>

                <DialogActions>
                    <Button size="medium" onClick={() => setShowDialog(false)} variant="outlined"
                            color="secondary">Abbrechen</Button>
                    <LoadingButton text="Bestätigen"
                                   size="medium"
                                   loading={loading}
                                   onClick={handleDelete}
                    />
                </DialogActions>

            </Dialog>}

            <IconButton color="secondary" onClick={() => setShowDialog(true)}>
                <DeleteIcon color="error"/>
            </IconButton>
        </>
    );
};

export default DeleteTaskButton;
