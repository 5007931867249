import {checkUserPermission, loggedInUser} from "../../../helpers/authHelper";
import NoAdmin from "./NoAdmin";
import PaymentStates from "./PaymentStates";
import TaskStates from "./TaskStates";
import PageHeader from "../../includes/PageHeader";
import React from "react";

const ManageStates = () => {
    const user = loggedInUser();

    if (!checkUserPermission(user, 'editStates')) {
        return <NoAdmin/>
    }

    return (
        <>
            <PageHeader pageTitle={`Zustände bearbeiten`} backRoute="/admin"/>

            <div className="container">
                <div className="row">
                    <div className="gr-24 gr-12@small">
                        <TaskStates/>
                    </div>


                    <div className="gr-24 gr-12@small">
                        <PaymentStates/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageStates;
