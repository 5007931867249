import {useState, useRef, useEffect} from "react";
import {CirclePicker} from "react-color";

import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {isToday} from "../../../helpers/helpers";

import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    selectLabel: {
        left: '1rem'

    },
}));
const CarCustomer = ({
                 hide,
                 setNextBtnDisabled,
                 updateParent,
                 updateCustomer,
                 updateOrder,
                 updateKilometre,
                 kilometreToUpdate,
                 orderToUpdate,
                 carToUpdate,
                 customerToUpdate,
                 updateParentAdditional
             }) => {
    const [canton, setCanton] = useState(carToUpdate?.canton ?? '');
    const [controlSign, setControlSign] = useState(carToUpdate?.controlSign ?? '');
    const [leased, setLeased] = useState(carToUpdate?.leased ?? false);
    const [maker, setMaker] = useState(carToUpdate?.maker ?? '');
    const [carOrder, setCarOrder] = useState(orderToUpdate ?? '');
    const [gender, setGender] = useState(customerToUpdate?.gender ?? '');
    const [firstname, setFirstname] = useState(customerToUpdate?.firstname ?? '');
    const [surname, setSurname] = useState(customerToUpdate?.surname ?? '');
    const [street, setStreet] = useState(customerToUpdate?.street ?? '');
    const [plz, setPlz] = useState(customerToUpdate?.plz ?? '');
    const [place, setPlace] = useState(customerToUpdate?.place ?? '');
    const [phone, setPhone] = useState(customerToUpdate?.phone ?? '');
    const [email, setEmail] = useState(customerToUpdate?.email ?? '');
    const [company, setCompany] = useState(customerToUpdate?.company ?? '');
    const [kilometre, setKilometre] = useState('');
    const [deliveryNote, setDeliveryNote] = useState(updateParentAdditional.deliveryNote ?? false)

    const form = useRef(null);
    const classes = useStyles();
    let currentColor = useRef(null);

    const colors = [
        '#000000',
        '#656565',
        '#d9d9d9',
        '#F8F7F7',
        '#F98E1D',
        '#F98',
        '#93c958',
        '#004225',
        '#418dd3',
        '#00A0C3',
        '#804ae5',
        '#DC2528',
        '#FFC900',
        '#FD4BC7'

    ]

    // Check if a kilometre value was set today
    useEffect(() => {
        const kilometresToday = kilometreToUpdate?.filter(standing => isToday(standing.date));

        if (kilometresToday?.length > 1) {
            // Get the last standing from array because there are multiple values today
            setKilometre(kilometresToday[kilometresToday.length - 1].reading);

        } else if (kilometresToday?.length === 1) {
            setKilometre(kilometresToday[0].reading);
        }
    }, [kilometreToUpdate]);


    useEffect(() => {
        if (hide) return;
        setNextBtnDisabled(true);

        if (controlSign && canton) {
            setNextBtnDisabled(false);
        }
    }, [setNextBtnDisabled, hide, controlSign, canton]);

    // Set current color on load
    useEffect(() => {
        if (carToUpdate?.color) {
            const activeColor = document.querySelector(`div[title="${carToUpdate.color}"]`)

            if (activeColor) {
                activeColor.classList.add('active');
                currentColor.current = activeColor;
            }
        }

    }, [carToUpdate]);


    return (
        <div className={hide ? "container container--mb hide" : "container container--mb"}>
            <div className="row row-justify-center">
                <div className="gr-24">
                <form ref={form} style={{width: '100%'}}>
                    <div className="row">
                        <div className="gr-4">
                            <div className="page__label">Kanton</div>
                        </div>
                        <div className="gr-8">
                            <FormGroup>
                                <TextField
                                    label="Kanton"
                                    inputProps={{pattern: '[a-zA-Z]+'}}
                                    required
                                    variant="filled"
                                    value={canton}
                                    name="canton"
                                    onChange={e => {
                                        setCanton(e.target.value.toUpperCase());
                                        updateParent('canton', e.target.value.toUpperCase());
                                    }}
                                /></FormGroup>
                        </div>
                        <div className="gr-4">
                            <div className="page__label">Anrede</div>
                        </div>
                        <div className="gr-8">
                            <FormGroup>
                                <FormControl>
                                    <InputLabel id="gender-select-label"
                                                className={classes.selectLabel}>Anrede</InputLabel>
                                    <Select
                                        labelId="gender-select-label"
                                        onChange={e => {
                                            setGender(e.target.value);
                                            updateCustomer('gender', e.target.value);
                                        }}
                                        variant="filled"
                                        name="maker"
                                        value={gender}>
                                        <MenuItem value="female">Frau</MenuItem>
                                        <MenuItem value="male">Herr</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="gr-4">
                            <div className="page__label">Nummernschild</div>
                        </div>
                        <div className="gr-8">
                        <FormGroup>
                            <TextField
                                label="Nummernschild"
                                inputProps={{pattern: '[a-zA-Z0-9_]+'}}
                                required
                                variant="filled"
                                value={controlSign}
                                name="controlSign"
                                helperText={'Keine Autonummer? Verwende eine Nummer mit einer führenden Null (z. B. LU 000001)'}
                                onChange={e => {
                                    setControlSign(e.target.value);
                                    updateParent('controlSign', e.target.value);
                                }}
                            />
                        </FormGroup></div>
                        <div className="gr-4">
                            <div className="page__label">Name</div>
                        </div>
                        <div className="gr-8">
                            <FormGroup>
                                <TextField
                                    label="Name"
                                    inputProps={{pattern: '[a-zA-Z0-9_]+'}}
                                    variant="filled"
                                    value={surname}
                                    onChange={e => {
                                        setSurname(e.target.value);
                                        updateCustomer('surname', e.target.value);
                                    }}
                                />
                            </FormGroup></div>
                    </div>
                    <div className="row">
                        <div className="gr-4">
                            <div className="page__label">Automarke & Model</div>
                        </div>
                        <div className="gr-8">
                            <FormGroup>
                                <TextField
                                    label="Hersteller"
                                    inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                    variant="filled"
                                    value={maker}
                                    name="maker"
                                    onChange={e => {
                                        setMaker(e.target.value);
                                        updateParent('maker', e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <div className="gr-4">
                            <div className="page__label">Vorname</div>
                        </div>
                        <div className="gr-8">
                            <FormGroup>
                                <TextField
                                    label="Vorname"
                                    inputProps={{pattern: '[a-zA-Z]+'}}
                                    variant="filled"
                                    value={firstname}
                                    onChange={e => {
                                        setFirstname(e.target.value);
                                        updateCustomer('firstname', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                    </div>
                        <div className="row">
                            <div className="gr-4">
                            <div className="page__label">Kilometerstand</div>
                                </div>
                            <div className="gr-8">
                            <FormGroup>
                                <TextField
                                    label="Kilometerstand"
                                    variant="filled"
                                    value={kilometre}
                                    name="kilometre"
                                    type="text"
                                    onChange={e => {
                                        setKilometre(e.target.value);
                                        updateKilometre({
                                            reading: e.target.value,
                                            date: new Date(),
                                        });
                                    }}
                                /></FormGroup>
                            </div>
                            <div className="gr-4">
                                <div className="page__label">Strasse und Nr.</div>
                            </div>
                            <div className="gr-8">
                                <FormGroup>
                                    <TextField
                                        label="Strasse und Hausnummer"
                                        inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                        variant="filled"
                                        value={street}
                                        onChange={e => {
                                            setStreet(e.target.value);
                                            updateCustomer('street', e.target.value);
                                        }}
                                    /></FormGroup>

                            </div>
                        </div>
                    <div className="row row-justify-end">
                        <div className="gr-4">
                        <FormControlLabel
                            label="Leasing-Fahrzeug"
                            control={
                                <Checkbox
                                    checked={leased}
                                    onChange={() => {
                                        setLeased(!leased);
                                        updateParent('leased', !leased);
                                    }}
                                    name="leased"
                                    color="secondary"
                                />}
                        />
                        </div>
                        <div className="gr-4">
                            <FormControlLabel
                            label="Lieferschein"
                            control={
                                <Checkbox
                                    checked={deliveryNote}
                                    onChange={() => {
                                        setDeliveryNote(!deliveryNote);
                                        updateParentAdditional('deliveryNote', !deliveryNote)
                                    }}
                                    name="leased"
                                    color="secondary"
                                />}/>
                        </div>
                        <div className="gr-4">
                            <div className="page__label">PLZ und Ort</div>
                        </div>
                        <div className="gr-8">
                            <div className="row">
                                <div className="gr-8">
                                    <FormGroup>
                                        <TextField
                                            label="PLZ"
                                            inputProps={{pattern: '[a-zA-Z0-9]+', maxLength: 6}}
                                            variant="filled"
                                            value={plz}
                                            onChange={e => {
                                                setPlz(e.target.value);
                                                updateCustomer('plz', e.target.value);
                                            }}
                                        /></FormGroup>
                                </div>
                                <div className="gr-16">
                                    <FormGroup>
                                        <TextField
                                            label="Ort"
                                            inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                            variant="filled"
                                            value={place}
                                            onChange={e => {
                                                setPlace(e.target.value);
                                                updateCustomer('place', e.target.value);
                                            }}
                                        /></FormGroup>
                                </div>
                            </div>
                        </div>
                        </div>
                    <div className="row row-justify-start">
                        <div className="gr-8 prefix-4">
                        <CirclePicker
                            colors={colors}
                            circleSize={36}
                            onChange={(data, event) => {

                                if (currentColor.current) {
                                    currentColor.current?.classList.remove('active');
                                }

                                currentColor.current = event.target;
                                currentColor.current?.classList.add('active');

                                updateParent('color', data.hex);
                            }}
                        /></div>
                        <div className="gr-4">
                            <div className="page__label">Firma</div>
                        </div>
                        <div className="gr-8">
                        <FormGroup>
                            <TextField
                                label="Firma"
                                inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                variant="filled"
                                value={company}
                                onChange={e => {
                                    setCompany(e.target.value);
                                    updateCustomer('company', e.target.value);
                                }}
                            /></FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="gr-4">
                            <div className="page__label">Reihenfolge Nr.</div>
                        </div>
                        <div className="gr-8">
                        <FormGroup>
                            <TextField
                                label="Reihenfolge"
                                variant="filled"
                                value={carOrder}
                                name="carOrder"
                                type="number"
                                onChange={e => {
                                    setCarOrder(e.target.value);
                                    updateOrder(e.target.value);
                                }}
                            /></FormGroup>
                        </div>
                        <div className="gr-4">
                            <div className="page__label">E-Mail</div>
                        </div>
                        <div className="gr-8">
                        <FormGroup>
                            <TextField
                                label="Email"
                                inputProps={{pattern: '[0-9-]+'}}
                                variant="filled"
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value);
                                    updateCustomer('email', e.target.value);
                                }}
                            /></FormGroup>
                        </div>
                    </div>
                    <div className="row row-justify-end">
                        <div className="gr-4">
                            <div className="page__label">Telefon</div>
                        </div>
                        <div className="gr-8">
                            <FormGroup>
                                <TextField
                                    label="Telefon"
                                    inputProps={{pattern: '[0-9-]+'}}
                                    variant="filled"
                                    value={phone}
                                    onChange={e => {
                                        setPhone(e.target.value);
                                        updateCustomer('phone', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </div>
    );
}

export default CarCustomer;