import DoneIcon from "@material-ui/icons/Done";

const CheckIconWithTitle = ({title}) => {
    return (
        <div className="to-check">
            <DoneIcon style={{marginRight: '.5rem'}}/>
            <p>{title}</p>
        </div>
    );
};

export default CheckIconWithTitle;
