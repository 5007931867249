import {useContext, useEffect, useRef, useState} from "react";
import Progress from "./create/Progress";
import Customer from "./create/Customer";
import Car from "./create/Car"
import CarCustomer from "./create/CarCustomer";
import Tasks from "./create/Tasks";
import {useDispatch, useSelector} from "react-redux";
import {clearTask, createTask, getTaskById, updateTask} from "../../actions/taskActions";
import {useHistory, useParams} from "react-router-dom";
import PageHeader from "../includes/PageHeader";
import Loading from "../includes/Loading";
import {WebSocketContext} from "../../context/Websocket";

const CreateTask = ({step}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {updateWsTaskList} = useContext(WebSocketContext);

    const {singleTask, loading, taskLoading, success} = useSelector(state => ({
        loading: state.loading,
        taskLoading: state.tasks.taskLoading,
        singleTask: state.tasks.singleTask,
        success: state.success,
    }));

    const [currentStep, setCurrentStep] = useState(step ?? 1);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
    const [newCustomer, setNewCustomer] = useState(false);
    const [taskGetsUpdated, setTaskGetsUpdated] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [car, setCar] = useState(null);
    const [kilometre, setKilometre] = useState(null);
    const [carOrder, setCarOrder] = useState('');
    const [customer, setCustomer] = useState(null);
    const [comment, setComment] = useState('');
    const [additional, setAdditional] = useState(null);

    // Get update route params
    const {id} = useParams();
    const {edit} = useParams();


    // Complete Task Object
    const [task, setTask] = useState({
        tasks: [],
        customer: {},
        car: {},
        carOrder: '',
        additional: {
            preferredLift: '',
        },
        comment: '',
    });

    const steps = [
        {id: 1, title: 'Fahrzeugerfassung'},
        {id: 2, title: 'Kundenerfassung'},
        {id: 3, title: 'Aufgaben'},
    ];

    const updateStepFromProgress = async direction => {
        if (currentStep === 2 && direction > 0 && customer?.new) {
            if (customer.firstname && customer.surname) {
                setCurrentStep(currentStep + direction);
            } else {
                setNewCustomer(true);
            }
        } else {
            setCurrentStep(currentStep + direction);
        }
    }

    // Update functions triggered in children components to update the parent state
    const updateCar = (index, value) => {
        setCar({
            ...car,
            [index]: value,
        });
    }

    const setCustomerFromChild = customer => {
        setCustomer(customer);
    }

    // Update kilometre from leasing cars
    const updateKilometre = reading => {
        setKilometre(reading);
    }

    const updateCustomer = (index, value) => {
        if (index === 'customer') {
            setCustomer(value)
        } else {
            setCustomer({
                ...customer,
                [index]: value,
            });
        }
    }

    const cancelNewCustomer = () => {
        setNewCustomer(false);
        setCustomer(null);
    }

    const updateTaskList = newConfig => {
        setTaskList(newConfig.tasks);
    }

    const updateComment = (comment) => {
        setComment(comment);
    }

    const updateOrder = order => {
        setCarOrder(order);
    }

    const updateAdditional = (index, value) => {
        setAdditional({
            ...additional,
            [index]: value,
        });
    }

    const setNextBtn = state => {
        setNextBtnDisabled(state);
    }

    const saveTask = async () => {
        // Check if a customer already has a kilometre reading and add it to the customer
        if (customer?.kilometreReading?.length > 0 && kilometre && kilometre.reading !== '') {
            customer.kilometreReading = [...customer.kilometreReading, kilometre];
        } else if (customer && kilometre && kilometre.reading !== '') {
            customer.kilometreReading = [kilometre];
        }


        const taskToSave = {
            ...task,
            car: {
                ...task.car,
                ...car
            },
            customer,
            carOrder,
            tasks: taskList,
            additional: {
                ...task.additional,
                ...additional,
            },
            comment,
        }


        if (!taskGetsUpdated) {
            await dispatch(createTask(taskToSave));
        } else {
            await dispatch(updateTask(taskToSave));
        }
    }


    // Query all task details for updating the task
    const getTaskToUpdate = useRef(async (id) => {
        await dispatch(getTaskById(id));
        setCurrentStep(parseInt(edit));
    });

    // Remove singleTask object from redux state
    const clearTaskRef = useRef(async () => {
        dispatch(clearTask());
    });

    // Check if action was a success and navigate back
    const navigateBack = useRef(() => {
        history.goBack();
        updateWsTaskList();
    });

    useEffect(() => {
        if (success) {
            navigateBack.current();
        }
    }, [success]);

    // Check if url params are defined and task gets updated
    useEffect(() => {
        const clean = clearTaskRef.current;

        if (id && edit) {
            getTaskToUpdate.current(id);
            setTaskGetsUpdated(true);
        }
        return () => {
            clean();
        }
    }, [id, edit]);


    useEffect(() => {
        // If singeTask has a value, an existing task gets updated.
        if (singleTask) {
            setTask(singleTask);
            setCar(singleTask.car ?? null);
            setAdditional(singleTask.additional ?? null);
            setCustomer(singleTask.customer ?? null);
            setComment(singleTask.comment ?? '');
            setTaskList(singleTask.tasks ?? []);
            setCarOrder(singleTask.carOrder ?? '');
        }
    }, [singleTask]);


    if (loading) {
        return (
            <Loading/>
        )
    }

    if(currentStep === 1){
        return (
            <>
            <PageHeader pageTitle={"Auftrag erstellen"}/>
            <Car hide={currentStep !== 1}
                         setNextBtnDisabled={setNextBtn}
                         updateParent={updateCar}
                         updateCustomer={updateCustomer}
                         carToUpdate={car}
                         updateOrder={updateOrder}
                         orderToUpdate={carOrder}
                         updateKilometre={updateKilometre}
                         customerToUpdate={customer}
                         setCustomer={setCustomerFromChild}
                         kilometreToUpdate={customer?.kilometreReading}
                         updateParentAdditional={updateAdditional}
            />

            <Progress step={steps.filter(s => s.id === currentStep)[0]} btnDisabled={nextBtnDisabled}
                      total={steps.length}
                      saveNewTask={saveTask}
                      loading={taskLoading}
                      updateStep={updateStepFromProgress}/>
            </>
        )
    }
    if(currentStep === 2){
        return (
            <>
                <PageHeader pageTitle={"Auftrag erstellen"}/>
                <Customer hide={currentStep !== 2}
                          setNewCustomer={setNewCustomer}
                          cancelNewCustomer={cancelNewCustomer}
                          setNextBtnDisabled={setNextBtn}
                          updateParent={updateCustomer}
                          setCustomer={setCustomerFromChild}
                          customerToUpdate={customer}
                          carToSearch={car}
                          taskGetsUpdated={taskGetsUpdated}
                />

                <Progress step={steps.filter(s => s.id === currentStep)[0]} btnDisabled={nextBtnDisabled}
                          total={steps.length}
                          saveNewTask={saveTask}
                          loading={taskLoading}
                          updateStep={updateStepFromProgress}/>
            </>
        )
    }
    return (
        <>
            <PageHeader pageTitle={"Auftrag erstellen"}/>

            <Tasks hide={currentStep !== 3}
                   updateParent={updateTaskList}
                   setNextBtnDisabled={setNextBtn}
                   taskListFromParent={taskList}
                   comment={comment}
                   updateParentComment={updateComment}
                   additionalToUpdate={additional}
                   updateParentAdditional={updateAdditional}
            />
            <Progress step={steps.filter(s => s.id === currentStep)[0]} btnDisabled={nextBtnDisabled}
                      total={steps.length}
                      saveNewTask={saveTask}
                      loading={taskLoading}
                      updateStep={updateStepFromProgress}/>
        </>
    )
}

export default CreateTask;