import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import Header from "../includes/Header";
import {clearTask, getTasks} from "../../actions/taskActions";

// Material UI Imports
import AddIcon from '@material-ui/icons/Add';
import InlineFeedback from "../includes/InlineFeedback";
import GridConfig from "./GridConfig";
import {isWarehouseTask} from "../../helpers/helpers";
import Button from "@material-ui/core/Button";
import {clearErrors} from "../../actions/errorActions";
import {loggedInUser} from "../../helpers/authHelper";


const Overview = React.memo( function ({feedback, setFeedback, warehouse = false}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [rows, setRows] = useState([]);
    const [userBranch, setUserBranch] = useState('');

    const handleClose = () => {
        if (feedback.error) {
            dispatch(clearErrors());
        }
        setFeedback(null);
    }

    const {allTasks, listLoading, user} = useSelector(state => (
        {
            allTasks: state.tasks.allTasks,
            listLoading: state.tasks.taskListLoading,
            user: state.auth.user,
        }
    ));


    const getTasksRef = useRef(() => {
            dispatch(getTasks());
        }
    );

    // Set users branch from user object or from the token in localstorage
    useEffect(() => {
        if (user?.branch?.title) {
            setUserBranch(user.branch.title);
        } else if (loggedInUser()) {
            setUserBranch(loggedInUser()?.branch?.title);
        }
    }, [user]);


    useEffect(() => {
        getTasksRef.current();
    }, [warehouse]);


    useEffect(() => {
        const local = [];

        allTasks.forEach(task => {
            const taskTime = new Date(task.createdAt);
            const now = new Date();
            const today = taskTime.setHours(0, 0, 0, 0) === now.setHours(0, 0, 0, 0);

            if (!today && task.payed && task?.state?.stateTitle === 'abgeschlossen') return;
            if (task?.state?.stateTitle === 'abgeschlossen' && task?.paymentState?.stateTitle === 'bezahlt') return;

            if (today || (!today && task?.state?.stateTitle !== 'abgeschlossen') || (!today && !task.payed) || (!today && task?.paymentState?.stateTitle !== 'bezahlt')) {
                if (!today && !task.payed && task?.state?.stateTitle === 'abgeschlossen' && task?.paymentState?.stateTitle === 'bezahlt') return;
                if (warehouse) {
                    // Check if a warehouse task is in the task list
                    if (task.tasks.filter(task => isWarehouseTask(task)).length <= 0) return;
                }

                local.push({
                    ...task,
                    id: task.taskId,
                    // Warehouse overview needs an additional task array to display the warehouse tasks
                    tasksStorage: [...task.tasks],
                    // Adding tire details to warehouse tasks if a new tire task is in the tasklist
                    newTires: task,
                    // Adding a quick edit button to set a storage task to ready
                    storageQuickBtn: task,
                    // Car leased and delivery note are displayed as column
                    isLeased: task?.car?.leased,
                    hasDeliveryNote: task?.additional?.deliveryNote,
                    update: {state: task.state, id: task.taskId, customer: task?.customer},
                    delete: task,
                });
            }
        });
        setRows(local.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) );

    }, [allTasks, warehouse]);

    // Remove Feedback message on component unload
    useEffect(() => {
        return () => {
            setFeedback(null);
        };
    }, [setFeedback]);

    return (
        <>
            <Header/>
            <div className="container">
                <div className="row row-align-between">
                    <div className="gr-24 gr-16@small">
                        <div className="page__title">Auftragsübersicht {userBranch}</div>
                    </div>
                    <div className="gr-24 gr-8@small tac tar@small">
                        <div className="overview__new">
                            <Button endIcon={<AddIcon/>} variant="contained" color="primary"
                                    onClick={async () => {
                                        await dispatch(clearTask());
                                        history.push('/create');
                                    }}
                            >Neuer Auftrag</Button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="gr-24">
                        <GridConfig feedback={feedback} setFeedback={setFeedback} rows={rows} loading={listLoading}
                                    warehouse={warehouse}/>
                    </div>
                </div>
            </div>

            {feedback &&
                <InlineFeedback open={true} message={feedback?.text} closeFunc={handleClose} error={feedback?.error}/>}
        </>
    )
});

export default Overview;
