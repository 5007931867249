import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import FeedbackProvider from "./context/Feedback";
import WebSocketProvider from "./context/Websocket";
import store from './store';

import './assets/scss/style.scss';

// All necessary components
import GuardedRoute from './components/GuardedRoute';
import Login from './components/auth/Login';
import Overview from './components/overview/Overview';
import CreateTask from './components/task/CreateTask';
import TaskDetails from './components/task/TaskDetails';
import DisplayError from './components/includes/DisplayError';
import NotFound from './components/includes/NotFound';
import UserProfile from "./components/auth/UserProfile";
import PageHeader from "./components/includes/PageHeader";
import UpdateCustomer from "./components/customer/UpdateCustomer";
import AdminDashboard from "./components/admin/AdminDashboard";
import {createMaterialTheme} from './components/includes/MaterialTheme';
import {ThemeProvider} from '@material-ui/core/styles';
import ManageStates from "./components/admin/dashboard/ManageStates";
import {ManageUser} from "./components/admin/user/ManageUser";
import ManageBranches from "./components/admin/dashboard/ManageBranches";
import ManageUserGroups from "./components/admin/dashboard/ManageUserGroups";
import DoneTask from "./components/task/DoneTask";

import Box from '@material-ui/core/Box';


const App = () => {
    // Check if darkmode is set in localstorage
    const darkmode = localStorage.getItem('darkmode');
    const theme = createMaterialTheme(darkmode === "true");
    const [feedback, setFeedback] = useState(null);

    if (darkmode) {
        document.body.classList.add('darkmode');
    }

    return (

        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <WebSocketProvider>
                    <Box>
                        <FeedbackProvider>
                            <Router>
                                <Switch>
                                    {/* Home route */}
                                    <GuardedRoute exact path='/' comp={Overview} feedback={feedback}
                                                  setFeedback={setFeedback}/>

                                    {/* Warehouse route */}
                                    <GuardedRoute exact path='/warehouse' comp={Overview}
                                                  warehouse={true}
                                                  feedback={feedback}
                                                  setFeedback={setFeedback}/>

                                    {/* Customer routes */}
                                    <GuardedRoute exact path='/customer/:id' comp={UpdateCustomer}/>

                                    {/* User routes */}
                                    <GuardedRoute exact path='/user/' comp={UserProfile}/>

                                    {/* Admin routes */}
                                    <GuardedRoute exact path='/admin/' comp={AdminDashboard}/>
                                    <GuardedRoute exact path='/admin/states' comp={ManageStates}/>
                                    <GuardedRoute exact path='/admin/user/manage/:id?' comp={ManageUser}/>
                                    <GuardedRoute exact path='/admin/branches' comp={ManageBranches}/>
                                    <GuardedRoute exact path='/admin/usergroups' comp={ManageUserGroups} />

                                    {/* Task routes */}
                                    <GuardedRoute exact path='/create' comp={CreateTask} setFeedback={setFeedback}
                                                  w/>
                                    <GuardedRoute exact path='/update/:id/:edit' comp={CreateTask}
                                                  setFeedback={setFeedback}/>
                                    <GuardedRoute exact path='/task/:id' comp={TaskDetails} feedback={feedback}
                                                  setFeedback={setFeedback}/>
                                    <GuardedRoute exact path='/task/:id/done' comp={DoneTask}/>
                                    {/* Auth routes */}
                                    <Route exact path='/auth'><Login/></Route>


                                    {/* Error & 404 routes */}
                                    <GuardedRoute exact path='/error' comp={DisplayError}/>

                                    <Route>
                                        <PageHeader pageTitle={'Lost in space?'} backRoute={'/'}/>
                                        <NotFound/>
                                    </Route>

                                </Switch>
                            </Router>
                        </FeedbackProvider>
                    </Box>
                </WebSocketProvider>
            </ThemeProvider>
        </Provider>

    );
}

export default App;
