import {checkUserPermission, loggedInUser} from '../../helpers/authHelper';
import Header from '../includes/Header';
import {useDispatch, useSelector} from "react-redux";
import {getAllBranches} from "../../actions/branchActions";
import {useEffect, useRef} from "react";
import {getAllStates} from "../../actions/taskStateActions";
import {getAllPaymentStates} from "../../actions/paymentStateActions";
import UserOverview from "./user/UserOverview";
import NoAdmin from "./dashboard/NoAdmin";


// Material UI imports
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import StatusChip from "../includes/StatusChip";
import {formatBranchDisplayText} from "../../helpers/helpers";
import {getAllUserGroups} from "../../actions/userGroupActions";


const DashboardStates = ({items, title, href}) => {
    return (
        <Box mb={2}>
            <Card variant="outlined">
                <CardContent>
                    <h2 className="page__subtitle">{title}</h2>

                    <Box mb={4}>
                        <List>
                            {items.map(state => (
                                <div key={state._id}>
                                    <Divider/>
                                    <ListItem key={state._id}>
                                        <ListItemText primary={<StatusChip state={state}
                                                                           size="small"/>}/>
                                    </ListItem>
                                </div>
                            ))}
                            <Divider/>
                        </List>
                    </Box>

                    <Box mt={2}>
                        <Button href={href} size="small" variant="contained"
                                color="primary">Bearbeiten</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

const DashboardList = ({items, title, href}) => {
    return (
        <Box mb={2}>
            <Card variant="outlined">
                <CardContent>
                    <h2 className="page__subtitle">{title}</h2>

                    <Box mb={4}>
                        <List>
                            {items.map(item => (
                                <div key={item.key}>
                                    <Divider/>
                                    <ListItem key={item.key}>
                                        <ListItemText primary={item.title}/>
                                    </ListItem>
                                </div>
                            ))}
                            <Divider/>
                        </List>
                    </Box>

                    <Box mt={2}>
                        <Button href={href} size="small" variant="contained"
                                color="primary">Bearbeiten</Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

const AdminDashboard = () => {
    const user = loggedInUser();
    const dispatch = useDispatch();

    const {allTaskStates, allPaymentStates, allBranches, allUserGroups} = useSelector(state => ({
        allTaskStates: state.taskStates.allStates,
        allPaymentStates: state.paymentStates.allPaymentStates,
        allBranches: state.branch.allBranches,
        allUserGroups: state.userGroups.allUserGroups,
    }));


    const load = useRef(() => {
        dispatch(getAllStates());
        dispatch(getAllPaymentStates());
        dispatch(getAllBranches());
        dispatch(getAllUserGroups());
    });

    useEffect(() => {
        load.current();
    }, []);


    if (!checkUserPermission(user, 'showAdminPage')) {
        return <NoAdmin/>
    }

    return (
        <>
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="gr-24">
                        <h1 className="page__title">Admin Dashboard</h1>
                    </div>

                    {checkUserPermission(user, 'editUsers') && <div className="gr-24">
                        <h2 className="page__title">Benutzer</h2>
                        <UserOverview/>
                    </div>}

                    <div className="gr-24">
                        <div className="admin__cards">
                            <div className="row">
                                {checkUserPermission(user, 'editUserGroups') &&
                                    <div className="gr-24 gr-12@small">
                                        <DashboardList
                                            items={allUserGroups?.map(group => ({
                                                title: group.groupTitle,
                                                key: group._id,
                                            }))}
                                            href={"/admin/usergroups"}
                                            title="Benutzergruppen"/>
                                    </div>}

                                {checkUserPermission(user, 'editBranches') && <div className="gr-24 gr-12@small">
                                    <DashboardList
                                        items={allBranches.map(branch => ({
                                            title: formatBranchDisplayText(branch),
                                            key: branch._id,
                                        }))}
                                        href={"/admin/branches"}
                                        title="Filialen"/>
                                </div>}

                                {checkUserPermission(user, 'editStates') && <div className="gr-24 gr-12@small">
                                    <DashboardStates items={allTaskStates?.sort((a, b) => a?.order - b?.order)}
                                                     href={"/admin/states"}
                                                     title="Taskzustände"/>
                                </div>}

                                {checkUserPermission(user, 'editStates') && <div className="gr-24 gr-12@small">
                                    <DashboardStates items={allPaymentStates?.sort((a, b) => a?.order - b?.order)}
                                                     href={"/admin/states"}
                                                     title="Bezahlzustände"/>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDashboard;
