const NotFound = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="gr-24 gr-12@small">
                    <div className="page__title">
                        Die angeforderte Seite konnte nicht gefunden werden.
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;