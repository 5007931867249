import CarSvg from "../../../assets/img/CarSvg";
import SommerSVG from "../../../assets/img/SommerSVG";
import WinterSVG from "../../../assets/img/WinterSVG";
import GanzSVG from "../../../assets/img/GanzSVG";


const TireDetail = ({config, customContent}) => {
    return (
        <div>
            <div className="tac">
                <div className="task__top">
                    {config.tireType === "winterTires" && <div><WinterSVG/> Winterreifen</div>}
                    {config.tireType === "summerTires" && <div><SommerSVG/>Sommerreifen</div>}
                    {config.tireType === "fullYearTires" && <div><GanzSVG/>Allwetterreifen</div>}
                    {config.tireType === "" && <div>Nicht spezifiziert</div>}
                    {config.size !== "" && <div>{config.size} Zoll</div> }
                </div>
            </div>
            <div className="config__grid config__grid--1">


                <div className="config__car" style={{alignSelf: "end", justifySelf: "center"}}>
                    <div
                        className={config.frontLeft ? "circle circle--top circle--left circle--primary" : "circle circle--top circle--left circle--gray"}>VL
                    </div>
                    <div
                        className={config.frontRight ? "circle circle--top circle--right circle--primary" : "circle circle--top circle--right circle--gray"}>VR
                    </div>
                    <div
                        className={config.backLeft ? "circle circle--bottom circle--left circle--primary" : "circle circle--bottom circle--left circle--gray"}>HL
                    </div>
                    <div
                        className={config.backRight ? "circle circle--bottom circle--right circle--primary" : "circle circle--bottom circle--right circle--gray"}>HR
                    </div>
                    <CarSvg/>
                </div>
            </div>
        </div>
    );
};

export default TireDetail;
