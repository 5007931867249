import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {useState, useEffect} from "react";
import LoadingButton from "../../includes/LoadingButton";


const Progress = ({step, total, updateStep, btnDisabled, loading, saveNewTask}) => {
    const history = useHistory();
    const [textNextBtn, setTextNextBtn] = useState('Weiter');

    const handleNextClick = (direction, e) => {
        e.preventDefault();

        if (step.id === total && direction === 1) {
            saveNewTask();
        } else {
            updateStep(direction);
            setTextNextBtn('Weiter');
        }
    }

    useEffect(() => {
        if (step?.id === total) {
            setTextNextBtn('Speichern');
        }
    }, [step, total]);


    return (
        <div className="progress__wrapper">
            <div className="container">
                <div className="row row-align-around">
                    <div className="gr-12 gr-6@small order-1">
                        <div className="row">
                            {step?.id > 1 &&
                                <Button variant="contained" size="medium" color="secondary"
                                        style={{marginRight: '1rem'}}
                                        onClick={e => handleNextClick(-1, e)}> zurück < /Button>
                            }
                            {step.id === 1 &&
                                <Button variant="outlined" size="medium"
                                        onClick={() => history.push('/')}>Abbrechen</Button>}
                        </div>

                    </div>
                    <div className="gr-24 gr-12@small order-3 order-2@small">
                        <div className="progress__header tac">
                            <div className="progress__title">Neuer Auftrag erstellen</div>
                            <div className="progress__subtitle">Schritt {step?.id} von {total} - {step?.title}</div>
                        </div>
                    </div>

                    <div className="gr-12 gr-6@small order-2 order-3@small tar">
                        <LoadingButton color="primary" variant="contained" size="medium" loading={loading}
                                       disabled={btnDisabled}
                                       onClick={e => handleNextClick(1, e)} text={textNextBtn}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Progress;