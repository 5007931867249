import AwesomeDebouncePromise from "awesome-debounce-promise";

/**
 * Validates a form element
 *
 * @param {Object} form
 * @returns {{inputsValid: boolean, errors: *[]}}
 */
export const validateForm = form => {
    const inputs = form?.querySelectorAll(['select', 'input']);
    const errors = [];

    let fieldsAreFilled = true;
    let firstFalseInput = null;

    inputs?.forEach(i => {
        if (!i.checkValidity()) {
            errors.push(i.name);
            fieldsAreFilled = false;
            if (!firstFalseInput) {
                firstFalseInput = i;
                firstFalseInput.focus();
            }
        } else {
            errors.filter(er => er !== i.name);
        }
    });

    return {
        inputsValid: fieldsAreFilled,
        errors: errors
    };
}
/**
 * Validates a form input
 *
 * @param {Object} input
 * @returns {boolean}
 */
export const validateFormInput = input => {
    if (!input) return false;


    if (!input.checkValidity()) {
        input?.parentElement?.classList.add('invalid');
        input.classList.add('error');
        return false;
    } else {
        input?.parentElement?.classList.remove('invalid');
        input.removeAttribute('error');
        return true;
    }

}


/**
 *
 * Formats a date string like 01.01.2022, 17.00
 *
 * @param {string} date
 * @returns {string}
 */
export const formatDate = date => {
    if (!date) return '';

    const utc = new Date(date);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    }
    return new Intl.DateTimeFormat('de-CH', options).format(utc);
}

/**
 * Formats a date string to a Date like 01.01.2022
 *
 * @param {string} date
 * @returns {string}
 */

export const formatDateWithMonth = date => {
    if (!date) return '';

    const utc = new Date(date);
    const options = {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    }
    return new Intl.DateTimeFormat('de-CH', options).format(utc);
}

/**
 * Formats a date string like 17:00
 *
 * @param {string} date
 * @returns {string}
 */
export const formatTime = date => {
    const utc = new Date(date);
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
    }
    return new Intl.DateTimeFormat('de-CH', options).format(utc);
}

/**
 * Debounce form inputs
 *
 */
export const debounceSearchAfterInput = AwesomeDebouncePromise(
    (dispatch, dispatchFunction) => dispatch(dispatchFunction),
    500, {
        onlyResolvesLast: true,
    });

/**
 * Unused task formatting function
 * @param {string} num
 * @param {number} size

 * @returns {string}
 */
export const numberWithLeadingZero = (num, size) => {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}


/**
 * Check if a task is a warehouse task
 * Must be updated, when a new warehouse task is created
 *
 * @param {Object} task
 * @returns {boolean}
 */
export const isWarehouseTask = task => {
    const taskId = task?.task?.id;

    if (!taskId) return false;

    // Task 1 and 2 could have warehouse tasks
    if (taskId === 1 || taskId === 2) {
        return task?.taskConfig?.storageOption === 'inStorage';
    }

    // Warehouse tasks have the ids 3, 5 and 9
    return taskId === 3 || taskId === 5;
}

/**
 * Check if a date is today
 *
 * @param date
 * @returns {boolean}
 */
export const isToday = date => {
    if (!date) return false;

    const today = new Date();
    const utc = new Date(date);
    return today.getFullYear() === utc.getFullYear() && today.getMonth() === utc.getMonth() && today.getDate() === utc.getDate();
}

/**
 * Get the last kilometre standing in the array
 *
 * @param readings
 * @returns {*}
 */
export const getLastKilometreReading = readings => {
    if (!readings || !readings.length) return;
    return readings.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
}

/**
 * Formats the display text for a branch
 *
 * @params {Object} branch
 * @returns {string}
 */
export const formatBranchDisplayText = branch => {
    if (!branch) return '';
    let string = branch.title;

    if (branch.street || branch.plz || branch.place) {
        string += ', ';
        string += branch.street;
    }

    if (branch.plz || branch.place) {
        if (branch.street) {
            string += ', ';
        }

        if (branch.plz) {

            string += branch.plz;

            if (branch.place) {
                string += ' ';
            }
        }

        string += branch.place;
    }

    return string;
}
