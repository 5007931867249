import {formatDateWithMonth, formatTime, isWarehouseTask} from "../../helpers/helpers";
import {useHistory} from "react-router-dom";
import StorageQuickBtn from "./StorageQuickBtn";
import React from "react";
// Material ui imports
import StatusChip from "../includes/StatusChip";
import CustomMenuButton from "./CustomMenuButton";
import {
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import Chip from "@material-ui/core/Chip";
import {DataGridPro} from '@mui/x-data-grid-pro';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "@material-ui/core/FormControl";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// Licensed Data Grid Pro
import {LicenseInfo} from '@mui/x-data-grid-pro';
import DeleteTaskButton from "./DeleteTaskButton";


LicenseInfo.setLicenseKey(
    'f46eec4ce6a948aab2dc3b60e13984d0T1JERVI6Mjk4MDQsRVhQSVJZPTE2NjM5NDQxMTEwMDAsS0VZVkVSU0lPTj0x',
);

// Variable outside of react components to filter task with checkboxes
let filterTasks = [];

// Formatting function
const dateToString = date => {

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const yesterday = new Date(new Date().setDate(now.getDate() - 1));
    const twoDaysAgo = new Date(new Date().setDate(now.getDate() - 2));

    const toCheck = new Date(date);

    // Reset all hours to 0 - so it's easy comparable
    toCheck.setHours(0, 0, 0, 0);
    yesterday.setHours(0, 0, 0, 0);
    twoDaysAgo.setHours(0, 0, 0, 0);

    if (toCheck.getTime() === now.getTime()) {
        return 'Heute';
    } else if (toCheck.getTime() === yesterday.getTime()) {
        return 'Gestern';
    } else if (toCheck.getTime() === twoDaysAgo.getTime()) {
        return 'Vor 2 Tagen';
    } else {
        return formatDateWithMonth(toCheck);
    }
}

// Custom filter param for state
/*const StatusValueInput = ({item, applyValue}) => {
    const handleFilterChange = (event, val) => {
        applyValue({...item, value: val});
    };

    return (
        <div style={{display: 'flex', flexFlow: 'column', alignItems: 'flex-start'}}>
            <Chip color="primary"
                  label="neu"
                  onClick={e => handleFilterChange(e, 'neu')}
                  style={{margin: '.25rem .5rem 0 .5rem'}}/>
            <Chip color="default"
                  label="abgeschlossen"
                  style={{margin: '.25rem .5rem 0 .5rem'}}
                  onClick={e => handleFilterChange(e, 'abgeschlossen')}/>
            <Chip
                color="default"
                label="in Bearbeitung"
                style={{margin: '.25rem .5rem 0 .5rem'}}
                onClick={e => handleFilterChange(e, 'in Bearbeitung')}/>
        </div>
    );
};*/

// Custom filter param for Bezahlt
/*const PayedValueInput = ({item, applyValue}) => {
    const handleFilterChange = (event, val) => {
        applyValue({...item, value: val});
    };

    return (
        <div style={{display: 'flex', flexFlow: 'column', alignItems: 'flex-start'}}>
            <Chip color="primary"
                  label="bezahlt"
                  onClick={e => handleFilterChange(e, '1')}
                  style={{margin: '.25rem .5rem 0 .5rem'}}/>
            <Chip color="default"
                  label="nicht bezahlt"
                  style={{margin: '.25rem .5rem 0 .5rem'}}
                  onClick={e => handleFilterChange(e, '0')}/>
        </div>
    );
};*/

// Custom filter for tasks
const TaskValueInput = ({item, applyValue}) => {
    const handleFilterChange = async (e) => {
        if (e.target.checked) {
            filterTasks.push(e.target.value);
            applyValue({...item, value: filterTasks});
        } else {
            filterTasks = [...filterTasks.filter(val => val !== e.target.value)];
            applyValue({...item, value: filterTasks});
        }
    };
    return (
        <FormControl>
            <FormControlLabel label="Einlagern" value="5"
                              onChange={e => handleFilterChange(e)}
                              control={<Checkbox checked={filterTasks.includes('5')}/>}/>
            <FormControlLabel label="Neue Pneu / Räder" value="3"
                              onChange={e => handleFilterChange(e)}
                              control={<Checkbox checked={filterTasks.includes('3')}/>}/>
        </FormControl>
    );
};

// Custom Toolbar without export function
const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
        </GridToolbarContainer>
    )
}

const GridConfig = React.memo( function  ({rows, loading, warehouse, feedback, setFeedback}) {
    const history = useHistory();

    const ratingOnlyOperators = [
        {
            label: 'ist',
            value: '=',
            getApplyFilterFn: (filterItem) => {
                if (
                    !filterItem.columnField ||
                    !filterItem.value ||
                    !filterItem.operatorValue
                ) {
                    return null;
                }

                return (params) => {
                    return params.value === filterItem.value;
                };
            },
            // InputComponent: StatusValueInput,
            InputComponentProps: {type: 'string'},
        },
    ];

    const taskOnlyOperators = [
        {
            label: 'sind',
            value: "=",
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.columnField ||
                    !filterItem.value ||
                    !filterItem.operatorValue || !filterItem.value?.length > 0) {
                    return null;
                }
                return (params) => {
                    return filterItem.value.includes(params.value[0]?.task?.id.toString());
                };
            },
            InputComponent: TaskValueInput,
            InputComponentProps: {type: 'string'},
        },
    ];

    const payedOnlyOperators = [
        {
            label: 'ist',
            value: '=',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem.columnField ||
                    !filterItem.value ||
                    !filterItem.operatorValue) {
                    return null;
                }
                return (params) => {
                    return params.value === filterItem.value;
                };
            },
            // InputComponent: PayedValueInput,
            InputComponentProps: {type: 'string'},
        },
    ];


    const columns = [
        {
            field: 'createdAt',
            headerName: 'Zeit',
            width: 140,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            renderCell: (params) => (

                <div className="overview__custom">
                    <span className="overview__day">{dateToString(params.value)}</span>
                    <span>{formatTime(params.value)}</span>
                </div>
            )
        },
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
            width: 100,
            disableColumnMenu: true,
            filterable: false,
            headerClassName: 'overview__data',
        },
        {
            field: 'tasks',
            headerName: 'Aufgaben',
            width: 170,
            disableColumnMenu: true,
            filterable: true,
            headerClassName: 'overview__data',
            renderCell: (params) => (
                <div className="overview__task-list">
                    {params.value?.length > 0 &&
                        <div style={{marginRight: '.5rem'}}>{params?.value[0]?.task?.title}</div>}
                    {params.value?.length > 1 &&
                        <Chip color="default" size="small" label={`+ ${params.value?.length - 1}`}/>
                    }
                </div>
            ),
            filterOperators: taskOnlyOperators,
            InputComponentProps: {type: 'string'},
        },
        {
            field: 'car',
            headerName: 'Fahrzeug',
            width: 140,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            renderCell: params => (
                <div className="overview__custom">
                    <div className="overview__custom--top">{params.value?.canton} {params.value?.controlSign}</div>
                    <div className="overview__custom--bottom">
                        {params.value?.color &&
                            <div style={{background: params.value.color}} className="overview__badge"/>}
                        <span>{params.value?.maker} {params.value?.model}</span></div>
                </div>
            ),
        },
        {
            field: 'carOrder',
            headerName: 'Reihenfolge',
            hide: warehouse,
            width: 140,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            renderCell: params => (
                <div className="overview__custom">
                    {params.value && `Nr. ${params.value}`}
                </div>
            ),
        },
        {
            field: 'customer',
            headerName: 'Kunde',
            width: 175,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            renderCell: params => (
                <div className="overview__custom">
                    <span>{params.value?.firstname} {params.value?.surname}</span>
                    <span>{params.value?.plz} {params.value?.place}</span>
                </div>
            ),
        },
        {
            field: 'state',
            headerName: 'Status',
            width: 120,
            disableColumnMenu: true,
            valueGetter: params => params.value,
            headerClassName: 'overview__data',
            renderCell: params => (<StatusChip state={params.value} size="small"/>),
            filterOperators: ratingOnlyOperators,
            InputComponentProps: {type: 'string'},
        },
        {
            field: 'paymentState',
            headerName: 'Bezahlt',
            width: 120,
            hide: warehouse,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            // valueGetter: params => params.value,
            renderCell: params => {
                const state = params.value;

                // Check if state is an object
                if (state?.stateTitle) {
                    return (<StatusChip state={params.value} size={"small"}/>);
                } else {
                    return (<Chip label={params.value ? 'bezahlt' : 'unbezahlt'}
                                  color={params.value ? 'primary' : 'default'}
                                  size="small"/>)
                }
            },
            filterOperators: payedOnlyOperators,
            InputComponentProps: {type: 'string'},
        },
        {
            field: 'hasDeliveryNote',
            headerName: 'Lieferschein',
            width: 140,
            hide: warehouse,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            renderCell: params => {
                if (params.value) {
                    return (<div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                        <CheckCircleOutlineIcon style={{color: '#84cc00'}}/>
                    </div>)
                } else {
                    return (<></>)
                }
            }
        },
        {
            field: 'isLeased',
            headerName: 'Leasing',
            width: 125,
            hide: warehouse,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            renderCell: params => {
                if (params.value) {
                    return (
                        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                            <CheckCircleOutlineIcon style={{color: '#87B2E0'}}/>
                        </div>)
                } else {
                    return (<></>)
                }
            }
        },
        {
            field: 'additional',
            headerName: 'Lift',
            hide: warehouse,
            width: 100,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            valueGetter: params => {
                if (params.value?.preferredLift) {
                    return 'Lift ' + params.value?.preferredLift;
                }
            }
        },
        {
            field: 'update',
            minWidth: 50,
            width: 100,
            flex: true,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerClassName: 'hide__header',
            renderHeader: () => (<></>),
            renderCell: params => {
                return (<CustomMenuButton task={params.value}/>)
            },
        },
        {
            field: 'delete',
            width: 100,
            minWidth: 75,
            flex: true,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            headerClassName: 'hide__header',
            renderHeader: () => (<></>),
            renderCell: params => {
                return (<DeleteTaskButton task={params.value}/>)
            },
        },
    ];

    // Adding some warehouse specific columns
    if (warehouse) {
        columns.splice(columns.length - 2, 0, {
            field: 'newTires',
            headerName: 'Neue Reifen',
            minWidth: 400,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            renderCell: params => {

                const task = params.value?.tasks?.filter(t => t?.task?.id === 3)[0]

                if (task) {
                    const config = task.taskConfig;
                    const front = config?.tiresFront;
                    const back = config?.tiresBack;

                    return (
                        <>
                            <div className="overview__tire-details">
                                <span>{front && <>VL VR: {front.size} {front.capacity} {front.speedIndex}</>}</span>
                                <span>{back && <>HL HR: {back.size} {back.capacity} {back.speedIndex}</>}</span>
                            </div>

                            <div className="overview__tire-details">
                                <StorageQuickBtn task={params.value}
                                                 taskId={3}
                                                 feedback={feedback}
                                                 setFeedback={setFeedback}/>
                            </div>
                        </>
                    );
                } else {
                    return <></>
                }
            },
        });

        columns.splice(columns.length - 2, 0, {
            field: 'storageQuickBtn',
            headerName: 'Einlagerung',
            width: 150,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            valueGetter: params => {
                // Get all tasks
                const tasks = params.value.tasks?.filter(task => {
                    // Check if the task is Einlagern (id 5)
                    return isWarehouseTask(task) && task.task.id === 5;
                });

                // Set the field value based on how many tasks are not ready
                if (tasks.length === 1) {
                    return params.value;
                } else {
                    return null;
                }
            },
            renderCell: params => {
                return <StorageQuickBtn task={params.value} taskId={5} feedback={feedback} setFeedback={setFeedback}/>;
            },
        });

        columns.splice(columns.length - 2, 0, {
            field: 'tasksStorage',
            headerName: 'Lager',
            width: 175,
            disableColumnMenu: true,
            headerClassName: 'overview__data',
            valueGetter: params => {
                // Get all tasks
                const tasks = params.value?.filter(task => isWarehouseTask(task));

                // Check which tasks are not ready
                const tasksNotReady = tasks.filter(task => !task?.taskConfig?.storageReady)?.length;

                // Set the field value based on how many tasks are not ready
                return tasksNotReady > 0 ? tasks.length === tasksNotReady ? 'notReady' : 'partlyReady' : 'ready';
            },
            renderCell: params => {
                let classes = '';
                let text = 'nicht bereit'

                switch (params.value) {
                    case 'notReady':
                        classes = 'overview__badge--red';
                        text = 'nicht bereit';
                        break;
                    case 'partlyReady':
                        classes = 'overview__badge--orange';
                        text = 'teilweise bereit';
                        break;

                    case 'ready':
                        classes = 'overview__badge--green';
                        text = 'vollständig bereit';
                        break;
                    default:
                        break;
                }

                return (
                    <>
                        <div className={`overview__badge ${classes}`}/>
                        {text}</>
                )
            },
        },)
    }

    return (
        <DataGridPro
            onCellDoubleClick={(task) => {
                history.push(`/task/${task.id}`)
            }}
            components={{
                Toolbar: CustomToolbar,
            }}
            pagination={true}
            autoHeight={true}
            loading={loading}
            style={{width: '100%'}}
            rows={rows}
            columns={columns}
            isRowSelectable={() => false}
        />
    );
});

export default GridConfig;
