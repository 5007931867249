import {GET_TIRES, TIRE__ERROR, TIRES_LOADING, CLEAR_TIRES_LOADING} from "../actions/types";

const initialState = {
    tires: [],
    tiresLoading: false,
}

const tireReducer = (state = initialState, action) => {
    switch (action.type) {
        // Loading state on tires
        case TIRES_LOADING:
            return {
                ...state,
                tiresLoading: true,
            }

        case CLEAR_TIRES_LOADING:
            return {
                ...state,
                tiresLoading: false
            }

        // Query tires
        case GET_TIRES:
            return {
                ...state,
                tires: action.payload,
            }
        case TIRE__ERROR:
            return {
                ...state,
                tires: [],
            }
        default:
            return {...state}
    }
}

export default tireReducer;