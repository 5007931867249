import {useState} from 'react';
import {updateTaskConfig} from '../../../../helpers/taskHelpers';

// Material UI imports
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Add from '@material-ui/icons/Add';
import {Remove} from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Checkbox from "@material-ui/core/Checkbox";


const DumpTires = ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList}) => {
    const defaultConfig = {
        amount: 1,
        tireType: '',
        dumpPrice: '',
    }

    const [taskConfig, setTaskConfig] = useState(taskToUpdate.filter(t => t.task.id === task.id)[0]?.taskConfig ?? defaultConfig);

    return (
        <div className="row row-align-center">
            <div className=" gr-4 gr-2@small task__config--checkbox">
                <Checkbox
                    checked={taskList.filter(t => t.id === task.id).length}
                    onChange={(e) => {
                        if(taskList.filter(t => t.id === task.id).length){
                            updateTaskConfig('tireType', '', setTaskConfig, taskConfig, updateMainTask, task)
                        }
                        toggleTask(task, setTaskConfig, taskConfig, updateMainTask, 'tireType', 'wheels')}}
                    name="allTyres"
                    color="default"
                />
            </div>
            <div className="task__config config__title task__config--round gr-8 gr-4@small suffix-1">
                {task.title}
            </div>
            <div className="config__content task__config gr-24 gr-16@small gr-17@large">
                <Box mb={3}>
                    <RadioGroup>
                        <div className="config__group config__group--3">
                            <FormControlLabel value="wheels"
                                              checked={taskConfig.tireType === 'wheels'}
                                              onChange={() => updateTaskConfig('tireType', 'wheels', setTaskConfig, taskConfig, updateMainTask, task)}
                                              control={<Radio/>}
                                              label="Räder"/>

                            <FormControlLabel value="tires"
                                              checked={taskConfig.tireType === 'tire'}
                                              onChange={() => updateTaskConfig('tireType', 'tire', setTaskConfig, taskConfig, updateMainTask, task)}
                                              control={<Radio/>}
                                              label="Pneu"/>


                            <FormControlLabel value="rims"
                                              checked={taskConfig.tireType === 'rims'}
                                              onChange={() => updateTaskConfig('tireType', 'rims', setTaskConfig, taskConfig, updateMainTask, task)}
                                              control={<Radio/>}
                                              label="Felgen"/>
                        </div>
                    </RadioGroup>
                </Box>
                {taskConfig.tireType &&
                    <div>
                        <div className="config__grid config__grid--2">
                            <TextField
                                label="Preis"
                                variant="filled"
                                type="text"
                                value={taskConfig?.dumpPrice ?? ''}
                                name="dumpPrice"
                                onChange={e => {
                                    updateTaskConfig('dumpPrice', e.target.value, setTaskConfig, taskConfig, updateMainTask, task);
                                }}
                            />
                        </div>

                        <div className="config__grid config__grid--2">
                            <TextField
                                label="Anzahl"
                                inputProps={{pattern: '[a-zA-Z0-9]+', min: 1}}
                                variant="filled"
                                type="number"
                                value={taskConfig.amount}
                                name="amount"
                                onChange={e => {
                                    updateTaskConfig('amount', parseInt(e.target.value), setTaskConfig, taskConfig, updateMainTask, task);
                                }}
                            />

                            <Box>
                                <IconButton onClick={() => {
                                    updateTaskConfig('amount', taskConfig.amount + 1, setTaskConfig, taskConfig, updateMainTask, task);
                                }}>
                                    <Add/>
                                </IconButton>
                                <IconButton disabled={taskConfig.amount === 1} onClick={() => {
                                    const newValue = taskConfig.amount - 1;
                                    if (newValue >= 1) {
                                        updateTaskConfig('amount', taskConfig.amount - 1, setTaskConfig, taskConfig, updateMainTask, task);
                                    }

                                }}>
                                    <Remove/>
                                </IconButton>
                            </Box>
                        </div>
                    </div>}

            </div>
        </div>
    );
};

export default DumpTires;
