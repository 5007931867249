import {useEffect, useState} from 'react';
import Chip from '@material-ui/core/Chip';


const StatusChip = ({state, size = 'medium'}) => {
    const [customColor, setCustomColor] = useState('');

    useEffect(() => {
        if (state?.stateColor) {
            setCustomColor(state.stateColor);
        } else {
            setCustomColor('');
        }
    }, [state]);

    if (state?.stateTitle) {
        return (
            <Chip color="default" style={{backgroundColor: customColor}} size={size} label={state.stateTitle}/>
        )
    } else {
        return <></>;
    }
}

export default StatusChip;