import {
    CLEAR_USER_LOADING,
    CLEAR_USERS_LOADING,
    REGISTER_SUCCESS,
    USER_LOADING,
    USERS_LOADING,
    GET_USERS,
    GET_USER,
    CLEAR_USER,
} from "../actions/types";

const initialState = {
    userLoading: false,
    usersLoading: false,
    allUsers: [],
    singleUser: null,
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        // Register handling
        case USER_LOADING:
            return {
                ...state,
                userLoading: true,
                registerError: null,
            }
        case REGISTER_SUCCESS:
            return {
                ...state,
            }
        case CLEAR_USER_LOADING:
            return {
                ...state,
                userLoading: false,
            }

        // User get request handling
        case GET_USERS:
            return {
                ...state,
                allUsers: action.payload,
            }
        case GET_USER:
            return {
                ...state,
                singleUser: action.payload,
            }
        case USERS_LOADING :
            return {
                ...state,
                usersLoading: true,
                usersError: null,
            }
        case CLEAR_USERS_LOADING:
            return {
                ...state,
                usersLoading: false,
            }
        case CLEAR_USER:
            return {
                ...state,
                singleUser: null,
            }
        default:
            return {
                ...state
            }
    }
}
export default userReducer;