import {CLEAR_SUCCESS, SET_SUCCESS} from "../actions/types";

const initialState = null;

const successReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUCCESS:
            return action.payload;
        case CLEAR_SUCCESS:
            return null;
        default:
            return state;
    }
}

export default successReducer;