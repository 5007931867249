/**
 *
 * Update function for task config state object.
 * A custom index is only used when a config object has own child-objects.
 *
 */

export const updateTaskConfig = (index, value, setTaskConfig, taskConfig, updateMainTask, task, customIndex = '') => {
    let newConfig = {};
    switch (index) {
        // New Tire task
        case 'tiresFrontLeft':
            setTaskConfig({
                ...taskConfig,
                tiresFrontLeft: {
                    ...taskConfig.tiresFrontLeft,
                    [customIndex]: value,
                }
            });
            newConfig = {
                ...taskConfig,
                tiresFrontLeft: {
                    ...taskConfig.tiresFrontLeft,
                    [customIndex]: value,
                }
            }
            break;
        case 'tiresFrontRight':
            setTaskConfig({
                ...taskConfig,
                tiresFrontRight: {
                    ...taskConfig.tiresFrontRight,
                    [customIndex]: value,
                }
            });
            newConfig = {
                ...taskConfig,
                tiresFrontRight: {
                    ...taskConfig.tiresFrontRight,
                    [customIndex]: value,
                }
            }
            break;
        case 'tiresBackLeft':
            setTaskConfig({
                ...taskConfig,
                tiresBackLeft: {
                    ...taskConfig.tiresBackLeft,
                    [customIndex]: value,
                }
            });
            newConfig = {
                ...taskConfig,
                tiresBackLeft: {
                    ...taskConfig.tiresBackLeft,
                    [customIndex]: value,
                }
            }
            break;
        case 'tiresBackRight':
            setTaskConfig({
                ...taskConfig,
                tiresBackRight: {
                    ...taskConfig.tiresBackRight,
                    [customIndex]: value,
                }
            });
            newConfig = {
                ...taskConfig,
                tiresBackRight: {
                    ...taskConfig.tiresBackRight,
                    [customIndex]: value,
                }
            }
            break;
        case 'all':
            setTaskConfig({
                ...taskConfig,
                tiresFrontRight: {
                    ...taskConfig.tiresFrontRight,
                    [customIndex]: value,
                },
                tiresBackLeft: {
                    ...taskConfig.tiresBackLeft,
                    [customIndex]: value,
                },
                tiresBackRight: {
                    ...taskConfig.tiresBackRight,
                    [customIndex]: value,
                }
            });
            newConfig = {
                ...taskConfig,
                tiresFrontRight: {
                    ...taskConfig.tiresFrontRight,
                    [customIndex]: value,
                },
                tiresBackLeft: {
                    ...taskConfig.tiresBackLeft,
                    [customIndex]: value,
                },
                tiresBackRight: {
                    ...taskConfig.tiresBackRight,
                    [customIndex]: value,
                }
            }
            break;
        case 'allTyres':
            setTaskConfig({
                ...taskConfig,
                frontLeft: value,
                frontRight: value,
                backLeft: value,
                backRight: value,
            });
            newConfig = {
                ...taskConfig,
                frontLeft: value,
                frontRight: value,
                backLeft: value,
                backRight: value,
            }
            break;
        default:
            setTaskConfig({
                ...taskConfig,
                [index]: value,
            });
            newConfig = {
                ...taskConfig,
                [index]: value,
            }
    }

    if (!newConfig) throw new Error('New configured state is empty!');
    if (!task) throw new Error('Task is not defined');

    updateMainTask(newConfig, task);
}


// Every non false value needs to be updated in the main task on component load
export const updateMainTaskOnLoad = (setTaskConfig, taskConfig, updateMainTask, task, customIndex) => {
    updateTaskConfig(customIndex ?? 'default', taskConfig.frontLeft, setTaskConfig, taskConfig, updateMainTask, task);
}

export const updateMainTaskOnAdd = (config, task, updateMainTask) => {
    updateMainTask(config, task);
}