// Custom menu buttons
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearTask} from "../../actions/taskActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const CustomMenuButton = ({task}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleRedirect = async (e, target, step) => {
        e.preventDefault();
        if (target.id) {
            setAnchorEl(null);
            await dispatch(clearTask());
            if (target?.state?.stateTitle === 'abgeschlossen') {
                history.push(`/customer/${target?.customer?._id}`);
            } else {
                history.push(`/update/${target.id}/${step}`);
            }
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (

        <div style={{position: "relative"}} className="edit-task">
            <IconButton
                className="icon__background"
                color="secondary"
                onClick={e => setAnchorEl(e.currentTarget)}
                size="small">
                <EditIcon/>
            </IconButton>


            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem disabled={task?.state?.stateTitle === 'abgeschlossen'}
                          onClick={e => handleRedirect(e, task, 1)}>
                    Fahrzeug bearbeiten</MenuItem>
                <MenuItem disabled={!task?.customer && task?.state?.stateTitle === 'abgeschlossen'}
                          onClick={e => handleRedirect(e, task, 2)}>Kunde bearbeiten</MenuItem>
                <MenuItem disabled={task?.state?.stateTitle === 'abgeschlossen'}
                          onClick={e => handleRedirect(e, task, 3)}>Aufgaben bearbeiten</MenuItem>
            </Menu>
        </div>


    )
}

export default CustomMenuButton;