import {useState} from "react";
import {updateTaskConfig} from "../../../../helpers/taskHelpers"
import TireSelection from "./TireSelection";

// Material UI imports
import Checkbox from "@material-ui/core/Checkbox";

const DefaultTireSelection = ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList}) => {
    const defaultConfig = {
        frontLeft: false,
        frontRight: false,
        backLeft: false,
        backRight: false,
        inBoot: false,
    }

    const [taskConfig, setTaskConfig] = useState(taskToUpdate.filter(t => t.task.id === task.id)[0]?.taskConfig ?? defaultConfig);

    return (
        <div className="row row--align-center">
            <div className="gr-4 gr-2@small task__config--checkbox">
                <Checkbox
                    checked={taskList.filter(t => t.id === task.id).length}
                    onChange={(e) =>{
                        if(taskList.filter(t => t.id === task.id).length){
                            updateTaskConfig('allTyres', false, setTaskConfig, taskConfig, updateMainTask, task)
                        }
                         toggleTask(task, setTaskConfig, taskConfig, updateMainTask, 'allTyres', e.target.checked)
                        }}
                    name="allTyres"
                    color="default"
                />
            </div>
            <div className="task__config config__title task__config--round gr-8 gr-4@small suffix-1">
                {task.title}
            </div>
            <div className=" task__config gr-24 gr-16@small gr-17@large">
                <TireSelection
                    taskConfig={taskConfig}
                    setTaskConfig={setTaskConfig}
                    task={task}
                    updateMainTask={updateMainTask}
                />
            </div>
        </div>
    );
};

export default DefaultTireSelection;
