import PageHeader from "../includes/PageHeader";
import {loggedInUser} from "../../helpers/authHelper";

const UserProfile = () => {
    const user = loggedInUser();

    if (user) {
        return (
            <>
                <PageHeader pageTitle={"Benutzerprofil"} backRoute={"/"}/>

                <div className="container">
                    <div className="row">
                        <div className="gr-24">
                            <div className="page__title">
                                Hey {user?.username}
                            </div>
                            {user.branch &&
                                <div className="page__text">
                                    Deine Filiale: {user.branch.title}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <PageHeader pageTitle={"Benutzerprofil"} backRoute={"/"}/>

                <div className="container">
                    <div className="row">
                        <div className="gr-24">
                            <div className="page__title">
                                User not in store.
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }

}

export default UserProfile;