import {useEffect, useRef, useState} from "react";
import PageHeader from "../includes/PageHeader";
import {useDispatch, useSelector} from "react-redux";
import DisplayError from "../includes/DisplayError";
import Loading from "../includes/Loading";
import {getCustomerById, updateCustomer} from "../../actions/customerActions";
import {useParams} from "react-router-dom";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import LoadingButton from "../includes/LoadingButton";
import Button from "@material-ui/core/Button";

const UpdateCustomer = () => {
    const dispatch = useDispatch();
    const {id} = useParams();

    const [createNewCustomer, setCreateNewCustomer] = useState(false);

    // State objects
    const {loading, error, singleCustomer, customerLoading} = useSelector(state => ({
        loading: state.loading,
        singleCustomer: state.customer.singleCustomer,
        error: state.error,
        customerLoading: state.customer.customerLoading,
    }));

    // Form Field Values
    const [gender, setGender] = useState('');
    const [firstname, setFirstname] = useState('');
    const [surname, setSurname] = useState('');
    const [street, setStreet] = useState('');
    const [plz, setPlz] = useState('');
    const [place, setPlace] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState();
    const [company, setCompany] = useState('');

    const getCustomerRef = useRef((customerId) => {
        if (customerId) {
            dispatch(getCustomerById(customerId));
        }
    });

    const setCustomerValues = useRef((customer) => {
        // Set values from store
        setGender(customer?.gender ?? '');
        setFirstname(customer?.firstname ?? '');
        setSurname(customer?.surname ?? '');
        setStreet(customer?.street ?? '');
        setPlz(customer?.plz ?? '');
        setPlace(customer?.place ?? '');
        setEmail(customer?.email ?? '');
        setPhone(customer?.phone ?? '');
        setCompany(customer?.company ?? '');
    });

    useEffect(() => {
        getCustomerRef.current(id);
    }, [id]);


    useEffect(() => {
        if (!singleCustomer && loading && error) return;
        setCustomerValues.current(singleCustomer);
    }, [singleCustomer, loading, error]);

    const saveCustomer = async () => {
        const updatedCustomer = {
            _id: id,
            gender,
            firstname,
            surname,
            street,
            plz,
            place,
            email,
            phone,
            company,
        }

        await dispatch(updateCustomer(updatedCustomer));
    }


    if (error) {

        return <DisplayError error={error} component={"Update Customer"}/>
    }

    if (loading) {
        return (
            <>
                <PageHeader pageTitle="Kunden bearbeiten" backRoute={'/'}/>
                <Loading/>
            </>
        )
    }

    if (!loading && !singleCustomer && !createNewCustomer) {
        return (
            <>
                <PageHeader pageTitle="Kunden bearbeiten" backRoute={'/'}/>

                <div className="container">
                    <div className="row row-justify-center">

                        {!loading && !singleCustomer &&
                            <>
                                <div className="gr-24 gr-18@small">
                                    <div className="page__title">
                                        Kunde konnte nicht gefunden werden oder ist noch nicht erfasst.
                                    </div>


                                </div>
                                <div className="gr-24 tac">
                                    <Button
                                        onClick={() => setCreateNewCustomer(true)}
                                        variant="contained"
                                        size="large"
                                        color="primary">Neuen Kunden erfassen</Button>
                                </div>
                            </>
                        }

                    </div>
                </div>


            </>
        )
    } else {
        return (
            <>

                <PageHeader pageTitle="Kunden bearbeiten" backRoute={'/'}/>

                <div className="container">
                    <div className="row row-justify-center">
                        <div className="gr-24 gr-18@small">
                            <FormGroup>
                                <TextField
                                    label="Firma"
                                    inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                    variant="filled"
                                    value={company}
                                    onChange={e => setCompany(e.target.value)}
                                /></FormGroup>
                        </div>
                        <div className="gr-24 gr-18@small">
                            <div className="row row-justify-center">
                                <div className="gr-24 gr-6@small">
                                    <FormGroup>
                                        <Select
                                            onChange={e => setGender(e.target.value)}
                                            variant="filled"
                                            name="maker"
                                            value={gender}>
                                            <MenuItem value="Anrede" disabled>Anrede</MenuItem>
                                            <MenuItem value="female">Frau</MenuItem>
                                            <MenuItem value="male">Herr</MenuItem>
                                        </Select>
                                    </FormGroup>
                                </div>
                                <div className="gr-24 gr-9@small">
                                    <FormGroup>
                                        <TextField
                                            label="Vorname"
                                            inputProps={{pattern: '[a-zA-Z]+'}}
                                            variant="filled"
                                            required
                                            value={firstname}
                                            onChange={e => setFirstname(e.target.value)}
                                        /></FormGroup>
                                </div>
                                <div className="gr-24 gr-9@small">
                                    <FormGroup>
                                        <TextField
                                            label="Nachname"
                                            inputProps={{pattern: '[a-zA-Z]+'}}
                                            variant="filled"
                                            value={surname}
                                            required
                                            onChange={e => setSurname(e.target.value)}
                                        /></FormGroup>
                                </div>
                                <div className="gr-24">
                                    <FormGroup>
                                        <TextField
                                            label="Strasse und Hausnummer"
                                            inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                            variant="filled"
                                            value={street}
                                            onChange={e => setStreet(e.target.value)}
                                        /></FormGroup>
                                </div>

                                <div className="gr-24 gr-6@small">
                                    <FormGroup>
                                        <TextField
                                            label="PLZ"
                                            inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                            variant="filled"
                                            value={plz}
                                            onChange={e => setPlz(e.target.value)}
                                        /></FormGroup>
                                </div>
                                <div className="gr-24 gr-18@small">
                                    <FormGroup>
                                        <TextField
                                            label="Ort"
                                            inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                            variant="filled"
                                            value={place}
                                            onChange={e => setPlace(e.target.value)}
                                        /></FormGroup>
                                </div>

                                <div className="gr-24 gr-12@small">
                                    <FormGroup>
                                        <TextField
                                            label="Telefon"
                                            inputProps={{pattern: '[0-9-]+'}}
                                            variant="filled"
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                        /></FormGroup>
                                </div>
                                <div className="gr-24 gr-12@small">
                                    <FormGroup>
                                        <TextField
                                            label="Email"
                                            inputProps={{pattern: '[0-9-]+'}}
                                            variant="filled"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        /></FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="gr-24 gr-18@small tac">
                            <LoadingButton color="primary" loading={customerLoading} onClick={saveCustomer}
                                           text="Kunden speichern" variant="contained"/>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default UpdateCustomer;
