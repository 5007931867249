import TireDetail from "./TireDetail";
import SuspensionSVG from "../../../assets/img/AirSVG";
import OBDSVG from "../../../assets/img/OBDSVG";
import CheckIconWithTitle from "./CheckIconWithTitle";
import InventoryIconWithTitle from "./InventoryIconeWithTitle";
import DumpIconWithTitle from "./DumpIconWithTitle";
import React from "react";
import ScrewsSVG from "../../../assets/img/ScrewsSVG";
import WarehouseSVG  from "../../../assets/img/WarehouseSVG";
import TrunkSVG from "../../../assets/img/TrunkSVG";

const tyres = {frontRight: [], frontLeft: [], rearRight: [], rearLeft: [], tireType: "", frontLeftTireName: [], frontRightTireName: [], rearLeftTireName: [], rearRightTireName: [], airSuspension: false, resetEOBD: false, screws: false, warehouse: false, trunk: false }
let clean = []
let store = []
let buy = []
let dump = []
let change = []

const DetailHandler = React.memo(function ({tasks}) {
    tyres.frontRight = []
    tyres.frontLeft = []
    tyres.rearRight = []
    tyres.rearLeft = []
    tyres.frontLeftTireName = []
    tyres.frontRightTireName = []
    tyres.rearLeftTireName = []
    tyres.rearRightTireName = []
    tyres.screws = false;
    tyres.airSuspension = false;
    tyres.warehouse = false;
    tyres.trunk = false;
    tyres.resetEOBD = false;
    clean = [];
    buy = [];
    store = [];
    dump = [];
    change = [];

    tasks?.forEach((task) => {
    switch (task.task?.category) {
        case 'newTires':
            tyres.rimSize = task?.taskConfig.rimSize
            tyres.screws = task?.taskConfig.screws
            tyres.airSuspension = task?.taskConfig.airSuspension
            tyres.resetEOBD = task?.taskConfig.resetEOBD
            tyres.warehouse = task?.taskConfig.storageOption === "inStorage"
            tyres.trunk = task?.taskConfig.storageOption === "inBoot"
            if(task?.taskConfig.frontRight){
                if(!tyres.frontRight.includes(task.task.title) && task?.taskConfig.tiresFrontRight.name !== ""){
                    tyres.frontRight.push(task.task.title)
                    tyres.frontRightTireName.push(task?.taskConfig.tiresFrontRight.name)
                }
            }
            if(task?.taskConfig.frontLeft && task?.taskConfig.tiresFrontLeft.name !== ""){
                if(!tyres.frontLeft.includes(task.task.title)){
                    tyres.frontLeft.push(task.task.title)
                    tyres.frontLeftTireName.push(task?.taskConfig.tiresFrontLeft.name)
                }
            }
            if(task?.taskConfig.backRight && task?.taskConfig.tiresBackRight.name !== ""){
                if(!tyres.rearRight.includes(task.task.title)){
                    tyres.rearRight.push(task.task.title)
                    tyres.rearRightTireName.push(task?.taskConfig.tiresBackRight.name)
                }
            }
            if(task?.taskConfig.backLeft && task?.taskConfig.tiresBackLeft.name !== ""){
                if(!tyres.rearLeft.includes(task.task.title)){
                    tyres.rearLeft.push(task.task.title)
                    tyres.rearLeftTireName.push(task?.taskConfig.tiresBackLeft.name)
                }
            }
            if(task?.taskConfig.tireType){
                tyres.tireType = task.taskConfig.tireType;
            }
            break;
        case 'cleaning' :
            if(task?.taskConfig){
                if(clean.length === 0){
                    const a = Object.keys(task.taskConfig);
                     a.forEach((key, index) =>{
                        switch (key) {

                            case 'cleanInterior':
                                if(task.taskConfig[key]){
                                clean.push('Innenreinigung');
                                }
                                break;

                            case 'cleanExterior':
                                if(task.taskConfig[key]){
                                    clean.push('Aussenreinigung');
                                }
                                break;
                            case 'cleanSmell':
                                if(task.taskConfig[key]){
                                    clean.push('Geruchsneutralisierung (Ozonbehandlung)');
                                }
                                break;
                            case 'cleanHair':
                                if(task.taskConfig[key]){
                                    clean.push('Hundehaare entfernen');
                                }
                                break;
                            case 'cleanFittings':
                                if(task.taskConfig[key]){
                                    clean.push('Leder Armaturen & Lenkrad');
                                }
                                break;
                            case 'shampooSeats':
                                if(task.taskConfig[key]){
                                    if(task.taskConfig[key].front && task.taskConfig[key].back){
                                        clean.push('Sitze schampoonieren vorne & hinten');
                                    }else if(task.taskConfig[key].front){
                                        clean.push('Sitze schampoonieren vorne');
                                    }else{
                                        clean.push('Sitze schampoonieren hinten');
                                    }
                                }
                                break;
                            case 'cleanLeatherSeats':
                                if(task.taskConfig[key]){
                                    if(task.taskConfig[key].front && task.taskConfig[key].back){
                                        clean.push('Ledersitz, Pflege & Versiegelung vorne & hinten');
                                    }else if(task.taskConfig[key].front){
                                        clean.push('Ledersitz, Pflege & Versiegelung vorne');
                                    }else{
                                        clean.push('Ledersitz, Pflege & Versiegelung hinten');
                                    }
                                }
                                break;
                            case 'cleanLeatherDoors':
                                if(task.taskConfig[key]){
                                    if(task.taskConfig[key].front && task.taskConfig[key].back){
                                        clean.push('Leder Türverkleidung, Pflege und Versiegelung vorne & hinten');
                                    }else if(task.taskConfig[key].front){
                                        clean.push('Leder Türverkleidung, Pflege und Versiegelung vorne');
                                    }else{
                                        clean.push('Leder Türverkleidung, Pflege und Versiegelung hinten');
                                    }
                                }
                                break;
                            case 'cleanLeatherBudget':
                                if(task.taskConfig[key]){
                                    if(task.taskConfig[key].front && task.taskConfig[key].back){
                                        clean.push('Budget Ledersitzpflege vorne & hinten');
                                    }else if(task.taskConfig[key].front){
                                        clean.push('Budget Ledersitzpflege vorne');
                                    }else{
                                        clean.push('Budget Ledersitzpflege hinten');
                                    }
                                }
                                break;
                            default:
                                break;
                        }
                    })
                }
            }
            break;
        case 'buy':
            // Kauf details
            if(task?.taskConfig.products.length && !buy.length){
                task?.taskConfig.products.forEach((p) =>{
                    buy.push(p)
                })
            }
            break;
        case 'dumpTires':
            if(task?.taskConfig && !dump.length){
                dump.push(task?.taskConfig)
            }
            break;
        case 'storeTires':
            task?.taskConfig.tiresToStore.forEach((tyre) =>{
                if(tyre.tirePosition === "VR"){
                    if(!tyres.frontRight.includes(task.task.title)){
                        tyres.frontRight.push(task.task.title + " (" + tyre.brand + ", " + tyre.dot + ", mm: " + tyre.profile + ")")
                    }
                }
                if(tyre.tirePosition === "VL"){
                    if(!tyres.frontLeft.includes(task.task.title)){
                        tyres.frontLeft.push(task.task.title + " (" + tyre.brand + ", " + tyre.dot + ", mm: " + tyre.profile + ")")
                    }
                }
                if(tyre.tirePosition === "HR"){
                    if(!tyres.rearRight.includes(task.task.title)){
                        tyres.rearRight.push(task.task.title + " (" + tyre.brand + ", " + tyre.dot + ", mm: " + tyre.profile + ")")
                    }
                }
                if(tyre.tirePosition === "HL"){
                    if(!tyres.rearLeft.includes(task.task.title)){
                        tyres.rearLeft.push(task.task.title + " (" + tyre.brand + ", " + tyre.dot + ", mm: " + tyre.profile + ")")
                    }
                }

            })
            if(task?.taskConfig){
                if(store.length === 0){
                    const a = Object.keys(task.taskConfig);
                    a.forEach((key, index) => {
                        switch (key){
                            case "montageKitInCar":
                                if(key){
                                    if(task.taskConfig[key]){
                                        store.push("Montage Kit im Auto")
                                    }
                                }
                            break;
                            case "offerWished":
                                if(key){
                                    if(task.taskConfig[key]){
                                        store.push("Offerte gewünscht")
                                    }
                                }
                                break;
                            case "storageReady":
                                if(key){
                                    if(task.taskConfig[key]){
                                        store.push("Bereit")
                                    }
                                }
                                break;
                            case "storeNumber":
                                if(task.taskConfig[key]) {
                                    store.push("Lagernummer/Ort: " + task.taskConfig[key])
                                }
                                break;
                            case "tireType":
                                if(task.taskConfig[key] === "winterTires") {
                                   store.push("Winterreifen")
                                }else if(task.taskConfig[key] === "summerTires"){
                                    store.push("Sommerreifen")
                                }else if(task.taskConfig[key] === "fullYearTires"){
                                    store.push("Allwetterreifen")
                                }else{
                                    store.push("Nicht spezifiziert")
                                }
                                break;
                            case "wheelType":
                                if(task.taskConfig[key] === "tires") {
                                    store.push("Reifen")
                                }else if(task.taskConfig[key] === "steel"){
                                    store.push("Rad-Stahl")
                                }else if(task.taskConfig[key] === "aluminium"){
                                    store.push("Rad-Aluminium")
                                }else{
                                    store.push("Kein Radtyp angegeben")
                                }
                                break;
                        }
                    });
                }
            }
            break;
        case 'cleanTire':
            if(task?.taskConfig.frontRight){
                if(!tyres.frontRight.includes(task.task.title)){
                    if(task?.taskConfig.cleanTireSummer && task?.taskConfig.cleanTireWinter){
                        tyres.frontRight.push(task.task.title + " (Sommer- & Winterrad)")
                    }else if(task?.taskConfig.cleanTireWinter){
                        tyres.frontRight.push(task.task.title + " (Winterrad)")
                    }else if(task?.taskConfig.cleanTireSummer){
                        tyres.frontRight.push(task.task.title + " (Sommerrad)")
                    }else{
                        tyres.frontRight.push(task.task.title)
                    }
                }
            }
            if(task?.taskConfig.frontLeft){
                if(!tyres.frontLeft.includes(task.task.title)){
                    if(task?.taskConfig.cleanTireSummer && task?.taskConfig.cleanTireWinter){
                        tyres.frontLeft.push(task.task.title + " (Sommer- & Winterrad)")
                    }else if(task?.taskConfig.cleanTireWinter){
                        tyres.frontLeft.push(task.task.title + " (Winterrad)")
                    }else if(task?.taskConfig.cleanTireSummer){
                        tyres.frontLeft.push(task.task.title + " (Sommerrad)")
                    }else{
                        tyres.frontLeft.push(task.task.title)
                    }
                }
            }
            if(task?.taskConfig.backRight){
                if(!tyres.rearRight.includes(task.task.title)){
                    if(task?.taskConfig.cleanTireSummer && task?.taskConfig.cleanTireWinter){
                        tyres.rearRight.push(task.task.title + " (Sommer- & Winterrad)")
                    }else if(task?.taskConfig.cleanTireWinter){
                        tyres.rearRight.push(task.task.title + " (Winterrad)")
                    }else if(task?.taskConfig.cleanTireSummer){
                        tyres.rearRight.push(task.task.title + " (Sommerrad)")
                    }else{
                        tyres.rearRight.push(task.task.title)
                    }
                }
            }
            if(task?.taskConfig.backLeft){
                if(!tyres.rearLeft.includes(task.task.title)){
                    if(task?.taskConfig.cleanTireSummer && task?.taskConfig.cleanTireWinter){
                        tyres.rearLeft.push(task.task.title + " (Sommer- & Winterrad)")
                    }else if(task?.taskConfig.cleanTireWinter){
                        tyres.rearLeft.push(task.task.title + " (Winterrad)")
                    }else if(task?.taskConfig.cleanTireSummer){
                        tyres.rearLeft.push(task.task.title + " (Sommerrad)")
                    }else{
                        tyres.rearLeft.push(task.task.title)
                    }
                }
            }
            break;
        case 'changeTire':
            if(task?.taskConfig.frontRight){
                if(!tyres.frontRight.includes(task.task.title)){
                    tyres.frontRight.push(task.task.title)
                }
            }
            if(task?.taskConfig.frontLeft){
                if(!tyres.frontLeft.includes(task.task.title)){
                    tyres.frontLeft.push(task.task.title)
                }
            }
            if(task?.taskConfig.backRight){
                if(!tyres.rearRight.includes(task.task.title)){
                    tyres.rearRight.push(task.task.title)
                }
            }
            if(task?.taskConfig.backLeft){
                if(!tyres.rearLeft.includes(task.task.title)){
                    tyres.rearLeft.push(task.task.title)
                }
            }
            if(task?.taskConfig.newTiresTrunk){
                if(!change.length){
                    change.push("Mitgebrachte Neureifen")
                }
            }

            break;

        case 'repair':
        case 'balance':
        default:
            if(task?.taskConfig.frontRight){
                if(!tyres.frontRight.includes(task.task.title)){
                    tyres.frontRight.push(task.task.title)
                }
            }
            if(task?.taskConfig.frontLeft){
                if(!tyres.frontLeft.includes(task.task.title)){
                    tyres.frontLeft.push(task.task.title)
                }
            }
            if(task?.taskConfig.backRight){
                if(!tyres.rearRight.includes(task.task.title)){
                    tyres.rearRight.push(task.task.title)
                }
            }
            if(task?.taskConfig.backLeft){
                if(!tyres.rearLeft.includes(task.task.title)){
                    tyres.rearLeft.push(task.task.title)
                }
            }
            break;
        }
    })
    const tyreconfig = {frontLeft : tyres.frontLeft.length > 0, frontRight : tyres.frontRight.length > 0, backLeft : tyres.rearLeft.length > 0, backRight : tyres.rearRight.length > 0, tireType: tyres.tireType, size: tyres.rimSize }
    const fl = tyres.frontLeft.map((a) => <div>{a}</div>);
    const fr = tyres.frontRight.map((a) => <div>{a}</div>);
    const rl = tyres.rearLeft.map((a) => <div>{a}</div>);
    const rr = tyres.rearRight.map((a) => <div>{a}</div>);
    return(
        <div className="container">
        <div className="row row-justify-center">
            <div className="gr-12">
                <div className="task__detail--additional--wrapper">
                {tyres.resetEOBD && <div className="task__detail--additional">OBD Reset <OBDSVG/>  </div>}
                {tyres.airSuspension && <div className="task__detail--additional">  Luftfedern Sperren <SuspensionSVG/>  </div>}
                </div>
            </div>
        </div>
        <div className="row">
            <div className="gr-7 task__detail--overview--left">
                <div className="task__detail--front">
                    {tyres.frontLeftTireName}
                    {fl}
                </div>
                <hr style={{
                    background: 'grey',
                    color: 'grey',
                    borderColor: 'transparent',
                    height: '3px',
                    width: '100%',

                }}/>
                <div className="task__detail--rear">
                    {tyres.rearLeftTireName}
                    {rl}
                </div>
            </div>
            <div className="gr-10 task__config--frame">
                <div className="task__config">
                    <div className="config__content">
                    <TireDetail config={tyreconfig}
                            customContent={""}/>
                    </div>
                    <div className="config__additional">
                        {tyres.screws && <ScrewsSVG/>}
                        {tyres.warehouse && <WarehouseSVG/>}
                        {tyres.trunk && <TrunkSVG/>}
                    </div>

                </div>
            </div>
            <div className="gr-7 task__detail--overview--right">
                <div className="task__detail--front">
                {tyres.frontRightTireName}
                {fr}
                </div>
                <hr style={{
                    background: 'grey',
                    color: 'grey',
                    borderColor: 'transparent',
                    height: '3px',
                    width: '100%',
                }}/>
                <div className="task__detail--rear">
                {tyres.rearRightTireName}
                {rr}
                </div>
            </div>
        </div>
        {change.length !== 0 &&
            <div className="row task__config">
                <div className="gr-24">
                    <div className="page__subtitle">Reifenwechsel</div>
                    <div className="task__detail--clean">
                        {change.map(e => {
                            return <CheckIconWithTitle title={e}/>
                        })}
                    </div>
                </div>
            </div>}
        {clean.length !== 0 &&
        <div className="row task__config">
            <div className="gr-24">
                <div className="page__subtitle">Reinigung</div>
                <div className="task__detail--clean">
                    {clean.map(e => {
                        return <CheckIconWithTitle title={e}/>
                    })}
                </div>
            </div>
        </div>}
        {store.length !== 0 &&
        <div className="row task__config">
            <div className="gr-24">
                <div className="page__subtitle">Einlagern</div>

                <div className="task__detail--clean">
                    {store.map(e => {
                        return <CheckIconWithTitle title={e}/>
                    })}
                </div>
            </div>
        </div>}
        {buy.length !== 0 &&
        <div className="row task__config">
            <div className="gr-24">
                <div className="page__subtitle">Zubehör</div>

                <div className="task__detail--clean">
                    {buy.map(e => {
                        return <InventoryIconWithTitle title={e.productDescription} amount={e.amount.toString()} prodnr={e.productId}/>
                    })}
                </div>
            </div>
        </div>}
        {dump.length !== 0 &&
        <div className="row task__config">
            <div className="gr-24">
                <div className="page__subtitle">Entsorgen</div>
                <div className="task__detail--clean">
                    {dump.map(e => {
                        return <DumpIconWithTitle title={e.tireType} amount={e.amount} price={e.dumpPrice}/>
                    })}
                </div>
            </div>
        </div>}
    </div>

    );
});

export default DetailHandler;
