import {createContext, useEffect, useRef, useState} from "react";
import {isAuthenticated} from "../helpers/authHelper";
import {useDispatch, useSelector} from "react-redux";
import {updateTaskList} from "../actions/taskActions";
import {io} from "socket.io-client";

export const WebSocketContext = createContext({
    socket: null,
    sendMessage: null,
});

export const WebSocketProvider = ({children}) => {
    const dispatch = useDispatch();
    const [stateSocket, setStateSocket] = useState(null);


    // Get user from store
    const {user} = useSelector(state => ({
        user: state.auth.user,
    }));


    const createSocket = useRef(() => {
        const token = localStorage.getItem('token') ?? '';

        if (token && isAuthenticated()) {

            let host = window.location.origin.replace(/^http/, 'ws');

            if (process.env.NODE_ENV === 'development') {
                host = 'ws://localhost:5000'
            }

            const socket = io(host, {
                auth: {
                    token: token,
                },
                reconnection: true,
                reconnectionDelay: 5000,
            });

            socket.on('connect', () => {
                console.info('Socket is connected');
            });

            socket.on('pushUpdate', res => {
                try {

                    const data = JSON.parse(res);

                    if (data.success) {
                        dispatch(updateTaskList(data));
                    }

                } catch (e) {
                    console.info('pushUpdate could not be parsed');
                }
            });

            socket.on('disconnect', (reason) => {
                console.info('Socket is closed. Reason: ', reason);
            });

            setStateSocket(socket);
        }
    });

    const updateWsTaskList = () => {
        if (stateSocket && stateSocket.connected) {
            const token = localStorage.getItem('token');
            stateSocket.emit('requestUpdate', {token});
        }
    }

    useEffect(() => {
        createSocket.current();
    }, [user]);


    return (
        <WebSocketContext.Provider value={{
            socket: stateSocket,
            updateWsTaskList: updateWsTaskList,
        }}>
            {children}
        </WebSocketContext.Provider>
    )
}

export default WebSocketProvider;
