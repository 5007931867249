import {useEffect, useRef, useState} from "react";


// Material UI imports
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
    input: {
        background: "#fff"
    }
});

const Comment = ({title, removeFunction, updateMainTask, commentToUpdate}) => {
    const [comment, setComment] = useState('');
    const classes = useStyles();

    const updateOnLoad = useRef(() => {
        if (commentToUpdate) {
            setComment(commentToUpdate);
        }
    });

    useEffect(() => {
        updateOnLoad.current();
    }, []);

    return (
        <div className="task__config">
            <div className="config__title">
                {title}
                <div className="config__remove">
                    <IconButton onClick={removeFunction}><DeleteIcon/></IconButton>
                </div>
            </div>
            <div className="config__content">
                <FormGroup>
                    <TextField
                        InputProps={{className: classes.input}}
                        label={title}
                        inputProps={{pattern: '[a-zA-Z0-9]+'}}
                        variant="filled"
                        name="comment"
                        multiline
                        rows={5}
                        value={comment}
                        onChange={e => {
                            setComment(e.target.value);
                            updateMainTask(e.target.value);
                        }}
                    />
                </FormGroup>
            </div>
        </div>
    );
};

export default Comment;
