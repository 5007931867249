import {CLEAR_LOADING, SET_LOADING} from "../actions/types";

const initialState = false;

const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOADING:
            return true;
        case CLEAR_LOADING:
            return false;
        default:
            return state;
    }
}

export default loadingReducer;