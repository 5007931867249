import {
    CAR_ERROR, CAR_LOADING,
    CLEAR_CAR_LOADING,
    GET_CAR

} from "../actions/types";

const initialState = {
    allCars: [],
    singleCar: null,
    carLoading: false,
};

const carReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CAR:
            return {
                ...state,
                allCars: action.payload,
            }
        case CAR_ERROR:
            return {
                ...state,
                singleCar: null,
                allBranches: [],
            }
        case CAR_LOADING:
            return {
                ...state,
                carLoading: true,
            }
        case CLEAR_CAR_LOADING:
            return {
                ...state,
                carLoading: false,
            }
        default:
            return {
                ...state,
            }
    }
}

export default carReducer;