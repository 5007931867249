import {useState, useEffect, useRef} from 'react';
import {updateTaskConfig} from '../../../../helpers/taskHelpers';

// Material UI imports
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Box from "@material-ui/core/Box";

const Cleaning = ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList}) => {
    // This config comes from https://pneufrank.ch/autoreinigung/
    let defaultConfig = {
        cleanInterior: false,
        cleanExterior: false,
        cleanSmell: false,
        cleanHair: false,
        cleanFittings: false,
        shampooSeats: null,
        cleanLeatherSeats: null,
        cleanLeatherDoors: null,
        cleanLeatherBudget: null,
    }
    if(taskToUpdate.filter(t => t.task.id === task.id).length){
        defaultConfig =taskToUpdate.filter(t => t.task.id === task.id)[0].taskConfig;
    }

    const [taskConfig, setTaskConfig] = useState(taskToUpdate?.taskConfig ?? defaultConfig);


    const [displayShampooSeats, setDisplayShampooSeats] = useState(false);
    const [displayLeatherSeats, setDisplayLeatherSeats] = useState(false);
    const [displayLeatherDoors, setDisplayLeatherDoors] = useState(false);
    const [displayLeatherBudget, setDisplayLeatherBudget] = useState(false);

    const handleDisplay = (val, setValue, index) => {
        if (val) {
            updateTaskConfig(index, null, setTaskConfig, taskConfig, updateMainTask, task)
        }

        setValue(!val);
    }


    const handlePositionChange = (e, index) => {
        const val = e.target.value;
        const checked = e.target.checked;

        const oldConfig = taskConfig[index] ?? {front: false, back: false};

        let newConfig = {
            ...oldConfig,
            [val]: checked,
        }

        if (!newConfig.front && !newConfig.back) {
            newConfig = null;
        }

        updateTaskConfig(index, newConfig, setTaskConfig, taskConfig, updateMainTask, task);
    }

    const showCheckboxes = useRef(() => {
        const cleanLeatherSeats = taskConfig.cleanLeatherSeats;
        const shampooSeats = taskConfig.shampooSeats;
        const doors = taskConfig.cleanLeatherDoors;
        const budget = taskConfig.cleanLeatherBudget;

        if (cleanLeatherSeats?.front || cleanLeatherSeats?.back) {
            setDisplayLeatherSeats(true);
        }

        if (shampooSeats?.front || shampooSeats?.back) {
            setDisplayShampooSeats(true);
        }

        if (doors?.front || doors?.back) {
            setDisplayLeatherDoors(true);
        }

        if (budget?.front || budget?.back) {
            setDisplayLeatherBudget(true);
        }
    });


    useEffect(() => {
        showCheckboxes.current();
    }, []);


    return (
        <div className="row row-align-center">
            <div className="gr-4 gr-2@small task__config--checkbox">
                <Checkbox
                    checked={taskList.filter(t => t.id === task.id).length}
                    onChange={(e) =>{
                        if(taskList.filter(t => t.id === task.id).length){
                            updateTaskConfig('cleanInterior', false, setTaskConfig, taskConfig, updateMainTask, task)
                        }
                        toggleTask(task, setTaskConfig, taskConfig, updateMainTask, 'cleanInterior', e.target.checked)
                    }}
                    name="allTyres"
                    color="default"
                />
            </div>
            <div className="config__title task__config task__config--round gr-8 gr-4@small suffix-1">
                {task.title}
            </div>
            <div className="config__content task__config gr-24 gr-16@small gr-17@large">
                    <FormControlLabel
                        label="Innenreinigung"
                        control={
                            <Checkbox
                                checked={taskConfig.cleanInterior}
                                onChange={() => updateTaskConfig('cleanInterior', !taskConfig.cleanInterior, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="cleanInterior"
                                color="default"
                            />
                        }/>

                    <FormControlLabel
                        label="Aussenreinigung"
                        control={
                            <Checkbox
                                checked={taskConfig.cleanExterior}
                                onChange={() => updateTaskConfig('cleanExterior', !taskConfig.cleanExterior, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="cleanExterior"
                                color="default"
                            />
                        }/>
                {taskList.filter(t => t.id === task.id).length === 1 &&
                <div>
                <hr style={{
                    background: 'grey',
                    color: 'grey',
                    borderColor: 'transparent',
                    height: '3px',
                }}/>
                    <FormControlLabel
                        label="Hundehaare entfernen"
                        control={
                            <Checkbox
                                checked={taskConfig.cleanHair}
                                onChange={() => updateTaskConfig('cleanHair', !taskConfig.cleanHair, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="cleanHair"
                                color="default"
                            />
                        }/>



                    <FormControlLabel
                        label="Geruchsneutralisierung (Ozonbehandlung)"
                        control={
                            <Checkbox
                                checked={taskConfig.cleanSmell}
                                onChange={() => updateTaskConfig('cleanSmell', !taskConfig.cleanSmell, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="cleanSmell"
                                color="default"
                            />
                        }/>
                    <FormControlLabel
                        label="Sitze schampoonieren"
                        control={
                            <Checkbox
                                checked={displayShampooSeats}
                                onChange={() => handleDisplay(displayShampooSeats, setDisplayShampooSeats, 'shampooSeats')}
                                name="shampooSeats"
                                color="default"
                            />
                        }/>
                    {displayShampooSeats &&
                        <Box mb={2}>
                            <FormLabel component="legend">Position Sitze</FormLabel>

                            <FormControlLabel
                                label="Vorne"
                                control={<Checkbox
                                    onChange={e => handlePositionChange(e, 'shampooSeats')}
                                    checked={taskConfig?.shampooSeats?.front ?? false}
                                    value="front"
                                    color="default"
                                />}
                            />

                            <br/>

                            <FormControlLabel
                                label="Hinten"
                                control={<Checkbox
                                    onChange={e => handlePositionChange(e, 'shampooSeats')}
                                    checked={taskConfig?.shampooSeats?.back ?? false}
                                    value="back"
                                    color="default"
                                />}
                            />
                        </Box>}

                    <FormControlLabel
                        label="Leder Armaturen & Lenkrad"
                        control={
                            <Checkbox
                                checked={taskConfig.cleanFittings ?? false}
                                onChange={() => updateTaskConfig('cleanFittings', !taskConfig.cleanFittings, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="cleanFittings"
                                color="default"
                            />
                        }/>

                    <Box>
                        <FormControlLabel
                            label="Ledersitz, Pflege & Versiegelung"
                            control={
                                <Checkbox
                                    checked={displayLeatherSeats}
                                    onChange={() => handleDisplay(displayLeatherSeats, setDisplayLeatherSeats, 'cleanLeatherSeats')}
                                    name="cleanLeatherSeats"
                                    color="default"
                                />
                            }/>

                        {displayLeatherSeats &&
                            <Box mb={2}>
                                <FormLabel component="legend">Position Ledersitze</FormLabel>

                                <FormControlLabel label="Vorne"
                                                  control={<Checkbox
                                                      onChange={e => handlePositionChange(e, 'cleanLeatherSeats')}
                                                      value="front"
                                                      checked={taskConfig?.cleanLeatherSeats?.front ?? false}
                                                      color="default"
                                                  />}
                                />
                                <br/>
                                <FormControlLabel label="Hinten"
                                                  control={<Checkbox
                                                      value="back"
                                                      onChange={e => handlePositionChange(e, 'cleanLeatherSeats')}
                                                      checked={taskConfig?.cleanLeatherSeats?.back ?? false}
                                                      color="default"
                                                  />}

                                />

                            </Box>}
                    </Box>

                    <Box>
                        <FormControlLabel
                            label="Leder Türverkleidung, Pflege und Versiegelung"
                            control={
                                <Checkbox
                                    checked={displayLeatherDoors}
                                    onChange={() => handleDisplay(displayLeatherDoors, setDisplayLeatherDoors, 'cleanLeatherDoors')}
                                    name="leatherDoors"
                                    color="default"
                                />
                            }/>
                        {displayLeatherDoors &&
                            <Box mb={2}>
                                <FormLabel component="legend">Position Türen</FormLabel>

                                <FormControlLabel
                                    label="Vorne"
                                    control={<Checkbox
                                        onChange={e => handlePositionChange(e, 'cleanLeatherDoors')}
                                        checked={taskConfig?.cleanLeatherDoors?.front ?? false}
                                        value="front"
                                        color="default"
                                    />}
                                />

                                <br/>

                                <FormControlLabel
                                    label="Hinten"
                                    control={<Checkbox
                                        onChange={e => handlePositionChange(e, 'cleanLeatherDoors')}
                                        checked={taskConfig?.cleanLeatherDoors?.back ?? false}
                                        value="back"
                                        color="default"
                                    />}
                                />
                            </Box>}

                    </Box>

                    <Box>
                        <FormControlLabel
                            label="Budget Ledersitzpflege"
                            control={
                                <Checkbox
                                    checked={displayLeatherBudget}
                                    onChange={() => handleDisplay(displayLeatherBudget, setDisplayLeatherBudget, 'cleanLeatherBudget')}
                                    name="cleanLeatherBudget"
                                    color="default"
                                />
                            }/>
                        {displayLeatherBudget &&
                            <Box mb={2}>
                                <FormLabel component="legend">Position Sitze</FormLabel>

                                <FormControlLabel
                                    label="Vorne"
                                    control={<Checkbox
                                        onChange={e => handlePositionChange(e, 'cleanLeatherBudget')}
                                        checked={taskConfig?.cleanLeatherBudget?.front ?? false}
                                        value="front"
                                        color="default"
                                    />}
                                />

                                <br/>

                                <FormControlLabel
                                    label="Hinten"
                                    control={<Checkbox
                                        onChange={e => handlePositionChange(e, 'cleanLeatherBudget')}
                                        checked={taskConfig?.cleanLeatherBudget?.back ?? false}
                                        value="back"
                                        color="default"
                                    />}
                                />
                            </Box>}

                    </Box>
            </div>}
        </div>
        </div>
    );
};

export default Cleaning;