import {useState} from "react";

// Material UI imports
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    selectLabel: {
        left: '1rem'

    },
}));

const CreateCustomer = ({hide, updateParent, cancelNewCustomer, customerToUpdate, taskGetsUpdated}) => {
    const [firstname, setFirstname] = useState(customerToUpdate?.firstname ?? '');
    const [surname, setSurname] = useState(customerToUpdate?.surname ?? '');
    const [gender, setGender] = useState(customerToUpdate?.gender ?? '');
    const [street, setStreet] = useState(customerToUpdate?.street ?? '');
    const [plz, setPlz] = useState(customerToUpdate?.plz ?? '');
    const [place, setPlace] = useState(customerToUpdate?.place ?? '');
    const [phone, setPhone] = useState(customerToUpdate?.phone ?? '');
    const [email, setEmail] = useState(customerToUpdate?.email ?? '');
    const [company, setCompany] = useState(customerToUpdate?.company ?? '');

    const classes = useStyles();

    return (
        <div className={hide ? "container container--mb hide" : "container container--mb"}>
            <div className="row row-justify-center">
                <div className="gr-24">
                    <div className="page__title">{taskGetsUpdated ? "Kunden aktualisieren" : "Neuen Kunden erfassen"}</div>
                </div>
                <div className="gr-24">
                    <div className="row">
                        <div className=" gr-4">
                            <div className="page__label">Firma</div>
                        </div>
                        <div className=" gr-8">
                            <FormGroup>
                                <TextField
                                    label="Firma"
                                    inputProps={{pattern: '[a-zA-Z0-9-]+'}}
                                    variant="filled"
                                    value={company}
                                    onChange={e => {
                                        setCompany(e.target.value);
                                        updateParent('company', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className=" gr-4">
                            <div className="page__label">Anrede</div>
                        </div>
                        <div className=" gr-8">
                            <FormGroup>
                                <FormControl>
                                    <InputLabel id="gender-select-label"
                                                className={classes.selectLabel}>Anrede</InputLabel>
                                    <Select
                                        labelId="gender-select-label"
                                        onChange={e => {
                                            setGender(e.target.value);
                                            updateParent('gender', e.target.value);
                                        }}
                                        variant="filled"
                                        required
                                        name="maker"
                                        value={gender}>
                                        <MenuItem value="female">Frau</MenuItem>
                                        <MenuItem value="male">Herr</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className=" gr-4">
                            <div className="page__label">Vorname</div>
                        </div>
                        <div className=" gr-8">
                            <FormGroup>
                                <TextField
                                    label="Vorname"
                                    inputProps={{pattern: '[a-zA-Z]+'}}
                                    variant="filled"
                                    required
                                    value={firstname}
                                    onChange={e => {
                                        setFirstname(e.target.value);
                                        updateParent('firstname', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                        <div className=" gr-4">
                        <div className="page__label">Nachname</div>
                    </div>
                        <div className=" gr-8">
                            <FormGroup>
                                <TextField
                                    label="Nachname"
                                    inputProps={{pattern: '[a-zA-Z]+'}}
                                    variant="filled"
                                    value={surname}
                                    required
                                    onChange={e => {
                                        setSurname(e.target.value);
                                        updateParent('surname', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className=" gr-4">
                            <div className="page__label">Strasse und Nr.</div>
                        </div>
                        <div className=" gr-8">
                            <FormGroup>
                                <TextField
                                    label="Strasse und Hausnummer"
                                    inputProps={{pattern: '[a-zA-Z0-9-]+'}}
                                    variant="filled"
                                    value={street}
                                    onChange={e => {
                                        setStreet(e.target.value);
                                        updateParent('street', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                        <div className=" gr-4">
                            <div className="page__label">Ort & PLZ</div>
                        </div>
                        <div className=" gr-8">
                            <div className="row">
                                <div className="gr-8">
                                    <FormGroup>
                                        <TextField
                                            label="PLZ"
                                            inputProps={{pattern: '[a-zA-Z0-9]+', maxLength: 6}}
                                            variant="filled"
                                            value={plz}
                                            onChange={e => {
                                                setPlz(e.target.value);
                                                updateParent('plz', e.target.value);
                                            }}
                                        /></FormGroup>
                                </div>
                                <div className="gr-16">
                                    <FormGroup>
                                        <TextField
                                            label="Ort"
                                            inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                            variant="filled"
                                            value={place}
                                            onChange={e => {
                                                setPlace(e.target.value);
                                                updateParent('place', e.target.value);
                                            }}
                                        /></FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className=" gr-4">
                            <div className="page__label">Telefon</div>
                        </div>
                        <div className=" gr-8">
                            <FormGroup>
                                <TextField
                                    label="Telefon"
                                    inputProps={{pattern: '[0-9-]+'}}
                                    variant="filled"
                                    value={phone}
                                    onChange={e => {
                                        setPhone(e.target.value);
                                        updateParent('phone', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                        <div className=" gr-4">
                            <div className="page__label">E-Mail</div>
                        </div>
                        <div className=" gr-8">
                            <FormGroup>
                                <TextField
                                    label="Email"
                                    inputProps={{pattern: '[0-9-]+'}}
                                    variant="filled"
                                    value={email}
                                    onChange={e => {
                                        setEmail(e.target.value);
                                        updateParent('email', e.target.value);
                                    }}
                                /></FormGroup>
                        </div>
                    </div>
                {(cancelNewCustomer && !taskGetsUpdated) &&
                    <div className="gr-24 gr-18@small">
                        <Button variant="outlined"
                                startIcon={<ClearIcon/>}
                                onClick={cancelNewCustomer}>Kunden erfassen abbrechen</Button>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default CreateCustomer;
