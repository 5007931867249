const ScrewsSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.28 595.28">
            <path
                d="M108.94 324.33 72.55 461.12l100.27 99.91 136.66-36.88 36.39-136.79-100.27-99.91-136.66 36.88Zm171.9 171.29-97.64 26.35-71.64-71.38 26-97.73 97.64-26.35 71.64 71.38-26 97.73Z"
                className="cls-1"/>
            <path
                d="m166.8 381.98-15.39 57.85 42.41 42.26 57.8-15.6 15.39-57.85-42.41-42.26-57.8 15.6Zm56.18 55.99-18.78 5.07-13.78-13.73 5-18.8 18.78-5.07 13.78 13.73-5 18.8Zm196.37-334.14-79.89-79.61-150.41 150.95 79.89 79.61 23.59-23.67L450.1 384.89l103.49-103.86-157.57-153.78 23.33-23.42Zm-10.46 84.88-1.53-.41-22.38 84.12-22.35-21.82 22.61-84.98 23.65 23.08Zm28.26 134.62-23.41-22.85 22.61-84.98 23.41 22.85-22.61 84.98ZM339.54 73.72l30.31 30.2-98.42 98.77-2.58 2.59-30.31-30.2 101-101.36Zm6.29 103.89-11.95 44.93-16.64-16.24 28.59-28.69Zm131.66 130.2 11.01-41.4 15.33 14.96-26.34 26.44Zm-374.2-188.27h42.79l-30.26 30.26 24.75 24.75 72.5-72.51-72.5-72.51-24.75 24.75 30.26 30.26H68.29v199.43h35V119.54zm390.2 359.2h-67.78l30.26-30.26-24.75-24.75-72.51 72.51 72.51 72.5 24.75-24.75-30.26-30.25h102.78V395.31h-35v83.43z"
                className="cls-1"/>
        </svg>    )
};

export default ScrewsSVG;