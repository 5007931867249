import React, {useState} from "react";
import TireSelection from "./TireSelection";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {updateTaskConfig} from "../../../../helpers/taskHelpers";

const BalanceTires = React.memo(function ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList}) {
    let defaultConfig = {
        frontLeft: false,
        frontRight: false,
        backLeft: false,
        backRight: false,
        balanceWarranty: false,
    }
    if(taskToUpdate.filter(t => t.task.id === task.id).length){
        defaultConfig = taskToUpdate.filter(t => t.task.id === task.id)[0].taskConfig;
    }
    const [taskConfig, setTaskConfig] = useState(taskToUpdate?.taskConfig ?? defaultConfig);
    // Set default values in main task
    return (
            <div className="row row-align-center">
                <div className="gr-4 gr-2@small task__config--checkbox">
                    <Checkbox
                        checked={taskList.filter(t => t.id === task.id).length}
                        onChange={(e) => {
                            if(taskList.filter(t => t.id === task.id).length){
                                updateTaskConfig('allTyres', false, setTaskConfig, taskConfig, updateMainTask, task)
                            }
                            toggleTask(task, setTaskConfig, taskConfig, updateMainTask, 'allTyres', e.target.checked)

                        }}
                        name="allTyresBalance"
                        color="default"
                    />
                </div>
                <div className="task__config config__title task__config--round gr-8 gr-4@small suffix-1">
                    {task.title}
                </div>
                <div className=" task__config gr-24 gr-16@small gr-17@large">
                    <TireSelection
                        taskConfig={taskConfig}
                        setTaskConfig={setTaskConfig}
                        task={task}
                        updateMainTask={updateMainTask}
                    />
                    {taskList.filter(t => t.id === task.id).length === 1 &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={taskConfig.balanceWarranty ?? false}
                                onChange={() => updateTaskConfig('balanceWarranty', !taskConfig.balanceWarranty, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="balanceWarranty"
                                color="default"
                            />
                        }
                        label="Garantiefall"
                    />}
                </div>

            </div>
    );
});

export default BalanceTires;
