import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "../../includes/LoadingButton";
import {updateTask} from "../../../actions/taskActions";
import {getAllPaymentStates} from "../../../actions/paymentStateActions";

// Material UI imports
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import {Alert} from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles({
    btn: {
        width: '100%',
        marginTop: '1rem',
        marginBottom: '2rem',
    },
});
// Reused component
const InvoiceForm = ({invoiceNumber, setInvoiceNumber, handleCustomerPay, paymentLoading}) => {
    return (
        <>
            <FormGroup>
                <TextField
                    label="Rechnungsnummer"
                    variant="filled"
                    type="text"
                    value={invoiceNumber}
                    name="dumpPrice"
                    onChange={e => setInvoiceNumber(e.target.value)}
                />
            </FormGroup>

            <LoadingButton
                disabled={!invoiceNumber}
                color="secondary"
                onClick={handleCustomerPay}
                loading={paymentLoading}
                text="Nr. eintragen"/>
        </>
    );
}


const PaymentStateButton = ({task, updateLoading}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [paymentLoading, setPaymentLoading] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [payed, setPayed] = useState(false);


    const {loading, allPaymentStates} = useSelector(state => ({
        loading: state.paymentStates.paymentStatesLoading,
        allPaymentStates: state.paymentStates.allPaymentStates,
    }));

    const loadAllPaymentStates = useRef(() => {
        dispatch(getAllPaymentStates());
    });

    useEffect(() => {
        loadAllPaymentStates.current();
    }, []);


    // Menu button helper functions
    const handleUpdate = async (item) => {

        const newTask = {
            ...task,
            paymentState: item,
        }

        await dispatch(updateTask(newTask));
        setAnchorEl(null);
    }

    const toggleMenu = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }


    const handleCustomerPay = async () => {
        setPaymentLoading(true);

        const newTask = {
            ...task,
            invoiceNumber: invoiceNumber,
        }

        await dispatch(updateTask(newTask));
        setPaymentLoading(false);
    }


    useEffect(() => {

        if (task?.paymentState?.stateTitle === 'bezahlt') {
            setPayed(true);
        }

    }, [task]);


    if (!payed) {
        return (
            <>
                <Button className={classes.btn}
                        disabled={loading || updateLoading}
                        aria-controls="menu-state"
                        color="primary"
                        variant="contained"
                        aria-haspopup="true"
                        onClick={toggleMenu}>Zahlungsstatus ändern</Button>
                <Menu
                    id="menu-state"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {allPaymentStates?.sort((a, b) => a?.order > b?.order ? 1 : -1)?.map(item => (
                        <MenuItem disabled={task?.paymentState?._id === item?._id}
                                  key={item._id}
                                  onClick={() => {
                                      handleUpdate(item);
                                  }}
                                  value={item}>{item.stateTitle}</MenuItem>
                    ))}
                </Menu>


                {!task?.invoiceNumber ? <InvoiceForm invoiceNumber={invoiceNumber}
                                                     setInvoiceNumber={setInvoiceNumber}
                                                     handleCustomerPay={handleCustomerPay}
                                                     paymentLoading={paymentLoading}/> :

                    <Box mb={2}>
                        <Alert variant="outlined" severity="info">
                            Rechnungsnummer wurde bereits erfasst: {task.invoiceNumber}
                        </Alert>
                    </Box>
                }
            </>
        );

    } else if (payed) {
        return (
            <>
                <Box mb={2}>
                    <Alert variant="filled" severity="success">Auftrag wurde bereits bezahlt.{task?.invoiceNumber &&
                        <>
                            <br/><br/>Rechnungsnummer:
                            <br/>{task.invoiceNumber}
                        </>
                    }</Alert>
                </Box>

                {!task?.invoiceNumber && <InvoiceForm invoiceNumber={invoiceNumber}
                                                      setInvoiceNumber={setInvoiceNumber}
                                                      handleCustomerPay={handleCustomerPay}
                                                      paymentLoading={paymentLoading}/>}
            </>
        );
    }
};

export default PaymentStateButton;