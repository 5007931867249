import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createTaskState, deleteTaskState, getAllStates, updateTaskState} from "../../../actions/taskStateActions";
import Loading from "../../includes/Loading";
import {ChromePicker} from "react-color";

// Material UI Includes
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import EditIcon from "@material-ui/icons/Edit";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import StatusChip from "../../includes/StatusChip";
import LoadingButton from "../../includes/LoadingButton";
import DeleteIcon from "@material-ui/icons/Delete";


const PaymentStates = () => {
    const dispatch = useDispatch();
    const [stateTitle, setStateTitle] = useState('');
    const [titleInvalid, setTitleInvalid] = useState(false);
    const [editState, setEditState] = useState(null);
    const [stateColor, setStateColor] = useState('#FFC900');
    const [order, setOrder] = useState('');
    const [showTaskStateModal, setShowTaskStateModal] = useState(false);
    const [toDelete, setToDelete] = useState(null);

    const {
        taskStatesLoading,
        taskStateLoading,
        allStates,
        success,
    } = useSelector(state => ({
        taskStatesLoading: state.taskStates.statesLoading,
        allStates: state.taskStates.allStates,
        taskStateLoading: state.taskStates.taskStateLoading,
        success: state.success,
    }));

    const loadAllStates = useRef(() => {
        dispatch(getAllStates());
    });

    const resetValues = () => {
        setStateTitle('');
        setStateColor('');
        setOrder('');
        setEditState(null);
        setTitleInvalid(false);
    }

    const handleClose = () => {
        resetValues();
        setShowTaskStateModal(false);
    }


    const handleSubmit = async () => {
        setTitleInvalid(false);

        if (!stateTitle) {
            setTitleInvalid(true);
            return;
        }

        // A state is edited and gets updated
        if (editState) {
            const edited = {
                ...editState,
                stateTitle,
                stateColor,
                order,
            }
            await dispatch(updateTaskState(edited));
            return;
        }

        // A new state is created
        const paymentState = {
            stateTitle,
            stateColor,
            order,
        }

        await dispatch(createTaskState(paymentState));
    }

    const handleEdit = state => {
        setEditState(state);
        setStateTitle(state.stateTitle);
        setStateColor(state.stateColor ? state.stateColor.toLowerCase() : '');
        setOrder(state.order ?? '');
        setShowTaskStateModal(true);
    }

    const handleDelete = async () => {
        await dispatch(deleteTaskState(toDelete));
    }

    // Handle action success
    const handleRes = useRef(() => {
        resetValues();
        setToDelete(null);
        setShowTaskStateModal(false);
    });

    useEffect(() => {
        if (success) {
            handleRes.current();
        }
    }, [success]);

    useEffect(() => {
        loadAllStates.current();
    }, []);

    if (taskStatesLoading) {
        return <Loading/>;
    }

    return (
        <>
            <Dialog fullWidth open={!!toDelete} onClose={() => setToDelete(null)}>
                {toDelete && <>
                    <DialogTitle>
                        Status «{toDelete.stateTitle}» löschen
                    </DialogTitle>

                    <DialogContent>
                        <div className="page__text">
                            Status: <StatusChip state={toDelete} size="medium"/> wird gelöscht.
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => setToDelete(null)} color="secondary">Abbrechen</Button>
                        <LoadingButton loading={taskStateLoading}
                                       onClick={handleDelete}
                                       text="Bestätigen"/>
                    </DialogActions>
                </>
                }

            </Dialog>

            <Dialog fullWidth open={showTaskStateModal} onClose={handleClose}>
                <DialogTitle>Task state erfassen</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        variant="outlined"
                        id="stateTitle"
                        margin="dense"
                        label="Titel"
                        type="text"
                        value={stateTitle}
                        onChange={e => setStateTitle(e.target.value)}
                        fullWidth
                        required
                        error={titleInvalid}
                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        id="stateTitle"
                        label="Reihenfolge"
                        type="number"
                        value={order}
                        onChange={e => setOrder(e.target.value)}
                        fullWidth
                    />

                    <Box>
                        <h5 className="page__subtitle">Farbe</h5>
                        <div className="admin__colors">
                            <ChromePicker disableAlpha={true} color={stateColor}
                                          onChange={data => setStateColor(data.hex)}/>
                            <Box mt={2} mb={2} onClick={() => setStateColor('#FFC900')} className="tac">
                                <Button variant="outlined" size="small" color="secondary">Farbe zurücksetzen</Button>
                            </Box>
                        </div>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} size="medium" variant="outlined" color="secondary">Abbrechen</Button>
                    <LoadingButton loading={taskStateLoading} size="medium" color="primary"
                                   onClick={handleSubmit} text="Speichern"/>
                </DialogActions>
            </Dialog>

            <h1 className="page__title">Task States</h1>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Titel</TableCell>
                            <TableCell>Reihenfolge</TableCell>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allStates?.sort((a, b) => a?.order > b?.order ? 1 : -1)?.map(state => (
                            <TableRow key={state._id}>
                                <TableCell><StatusChip state={state} size="small"/></TableCell>
                                <TableCell>{state?.order && state.order}</TableCell>
                                <TableCell>
                                    <IconButton color="secondary" size="small" onClick={() => handleEdit(state)}>
                                        <EditIcon/>
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton color="secondary" size="small" onClick={() => setToDelete(state)}>
                                        <DeleteIcon color="error"/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={2}>
                <Button type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowTaskStateModal(true)}>Zustand erfassen</Button>
            </Box>

        </>
    );
};

export default PaymentStates;
