import {
    CLEAR_LOGIN_LOADING,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
} from "../actions/types";

const initialState = {
    user: null,
    loginLoading: false,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:

            localStorage.setItem('token', action.payload.token);

            return {
                ...state,
                user: action.payload.user,
            }

        case LOGIN_LOADING:
            return {
                ...state,
                loginLoading: true,
            }

        case CLEAR_LOGIN_LOADING:
            return {
                ...state,
                loginLoading: false,
            }
        default:
            return state;
    }
}
export default authReducer;