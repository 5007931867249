import PageHeader from "../includes/PageHeader";
import React, {useEffect, useRef, useState} from "react";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import CheckIcon from "@material-ui/icons/Check";
import {getTaskById, updateTask, updateTaskWithState} from "../../actions/taskActions";
import Button from "@material-ui/core/Button";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {getAllStates} from "../../actions/taskStateActions";
import {getAllUser} from '../../actions/userActions';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TeamSVG from "../../assets/img/TeamworkSVG";
import {useHistory} from 'react-router-dom';
import {loggedInUser} from "../../helpers/authHelper";

const DoneTask = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const history = useHistory();


    const {singleTask, allStates, user, allUser} = useSelector(state => ({
        singleTask: state.tasks.singleTask,
        allStates: state.taskStates.allStates,
        user: state.auth.user,
        allUser: state.user.allUsers,
    }));
    const [digitalPressure, setDigitalP] = useState(false);
    const [torque, setTorque] = useState(false);
    const [resetGauge, setResetG] = useState(false);
    const [userBranch, setUserBranch] = useState('');

    const getTasksByIdRef = useRef(id => {
        dispatch(getTaskById(id));
    });
    const idRef = useRef(id);

    const loadAllUser = useRef(() => {
        dispatch(getAllUser());
    });

    const handleTaskUpdate = async newState => {
        await dispatch(updateTaskWithState(singleTask, newState));
        history.push("/");
    }

    const handleTaskUpdateWorker = async workerID => {
        const newTask = {
            ...singleTask,
            worker: workerID,
            confirmation: {
                digitalPressure: digitalPressure,
                torque: torque,
                resetGauge: resetGauge,
            }
        }
        await dispatch(updateTask(newTask));
    }

    const loadAllStates = useRef(() => {
        dispatch(getAllStates());
    });
    const [worker, setWorker] = useState("");

    useEffect(() => {
        loadAllStates.current();
    }, []);
    useEffect(() => {
        getTasksByIdRef.current(idRef.current);
    }, []);
    useEffect(() => {
        loadAllUser.current();
    }, []);
    useEffect(() => {
        if (singleTask) {
            setDigitalP(singleTask.confirmation.digitalPressure)
            setResetG(singleTask.confirmation.resetGauge)
            setTorque(singleTask.confirmation.torque)
            setWorker(singleTask.worker ?? "")
        }
    }, [singleTask]);

    useEffect(() => {
        if (user?.branch) {
            setUserBranch(user.branch);
        } else if (loggedInUser()) {
            setUserBranch(loggedInUser()?.branch);
        }
    }, [user]);

    return(
        <>
            <PageHeader pageTitle={"Zurück zum Auftrag"} backRoute={"previous"}/>
            <div className="container">
                <div className="row">
                    <div className="gr-24 gr-12@small">
                        <div className="row row-justify-center">
                            <div className="gr-8">
                                <div className="page__label">Lift Nr</div>
                            </div>
                            <div className="gr-16">
                                <FormGroup>
                                    <TextField
                                        label="Lift"
                                        inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                        variant="filled"
                                        value={singleTask?.additional?.preferredLift}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row row-justify-center">
                            <div className="gr-8">
                                <div className="page__label">Name</div>
                            </div>
                            <div className="gr-16">
                                <FormGroup>
                                    <Select
                                        value={worker}
                                        onChange={(e)=>{
                                            setWorker(e.target.value)
                                            handleTaskUpdateWorker(e.target.value)
                                        }}>
                                        {allUser.map((u) => {
                                            if((u.userGroup?.groupTitle === "Mitarbeiter" || u.userGroup?.groupTitle === "Filialleiter") && u.branch === userBranch._id){
                                                return   <MenuItem value={u._id}>{u.firstname} {u.surname}</MenuItem>
                                            }
                                        })}
                                    </Select>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="task__done--bottom">
                            <div className="row row-justify-center">
                                <div className="gr-8 tac">
                                    <Checkbox
                                        checked={digitalPressure}
                                        onChange={(e) =>{
                                            setDigitalP(e.target.checked)
                                         }}
                                        name="digitalPressure"
                                        color="default"
                                        id="digitalPressure"
                                    />
                                </div>
                                <div className="gr-16">
                                    <label for="digitalPressure" className="page__label--done">Luftdruck Digital Korrigiert</label>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="gr-8 tac">
                                    <Checkbox
                                        checked={torque}
                                        onChange={(e) =>{
                                            setTorque(e.target.checked)
                                        }}
                                        name="torque"
                                        color="default"
                                        id="torque"
                                    />
                                            </div>
                                <div className="gr-16">
                                    <label for="torque" className="page__label--done">Mit dem Drehmomentschlüssel alle 4 Räder Nachgezogen</label>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="gr-8 tac">
                                    <Checkbox
                                        checked={resetGauge}
                                        onChange={(e) => {
                                            setResetG(e.target.checked)
                                          }}
                                        name="resetGauge"
                                        color="default"
                                        id="resetGauge"
                                    />
                                </div>
                                <div className="gr-16">
                                    <label for="resetGauge" className="page__label--done">
                                    Luftdruckanzeige
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gr-24 gr-12@small">
                        <div className="task__done--svg">
                            <TeamSVG/>
                        </div>

                        <Button endIcon={<CheckIcon/>} variant="contained" color="primary" size="large" fullWidth={true}
                                disabled={worker === ""}
                                onClick={async () => {
                                    let s = allStates.filter((a) => a.stateTitle === "abgeschlossen")
                                    handleTaskUpdateWorker(worker)
                                    handleTaskUpdate(s[0])
                                }}
                        >Abschliessen</Button>
                    </div>
                </div>

            </div>
        </>
    )
}
export default DoneTask;