import {
    CLEAR_TASK,
    CLEAR_TASK_LOADING,
    CLEAR_TASKS_LOADING,
    CREATE_TASK,
    GET_TASK,
    GET_TASKS,
    TASK_LOADING,
    TASKS_ERROR, TASKS_LOADING, UPDATE_TASK
} from "../actions/types";

const initialState = {
    allTasks: [],
    singleTask: null,
    taskLoading: false,
    taskListLoading: false,
};

const taskReducer = (state = initialState, action) => {
    switch (action.type) {

        // Loading states
        case TASK_LOADING:
            return {
                ...state,
                taskLoading: true,
            }
        case CLEAR_TASK_LOADING:
            return {
                ...state,
                taskLoading: false,
            }

        // Single task handling
        case GET_TASK:
            return {
                ...state,
                singleTask: action.payload,
            }

        case CREATE_TASK:
            return {
                ...state,
                singleTask: action.payload,
            }

        case UPDATE_TASK :
            return {
                ...state,
                singleTask: action.payload,
            }

        case CLEAR_TASK:
            return {
                ...state,
                singleTask: {},
                taskLoading: false,
            }
        // Task list handling
        case GET_TASKS:
            return {
                ...state,
                allTasks: action.payload,
            }

        case TASKS_ERROR:
            return {
                ...state,
                allTasks: [],
            }

        case TASKS_LOADING:
            return {
                ...state,
                taskListLoading: true,
            }

        case CLEAR_TASKS_LOADING:
            return {
                ...state,
                taskListLoading: false,
            }
        default:
            return {
                ...state,
            }
    }
}

export default taskReducer;