import {axiosInstance as axios} from '../helpers/axios';
import {setErrors} from "./errorActions";
import {tokenConfig} from "./authActions";
import {
    CLEAR_TASK_STATE_LOADING,
    TASK_STATE_LOADING,
    CLEAR_STATES_LOADING,
    GET_STATES,
    STATES_ERROR,
    TASK_STATE_ERROR,
    STATES_LOADING,
} from "./types";
import {setSuccess} from "./successActions";

export const getAllStates = () => async (dispatch) => {
    try {
        dispatch({type: STATES_LOADING});

        const res = await axios.get('/api/v1/state', tokenConfig());

        dispatch({
            type: GET_STATES,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err, STATES_ERROR));
    } finally {
        dispatch({type: CLEAR_STATES_LOADING});
    }
}
export const createTaskState = taskState => async dispatch => {
    try {
        dispatch({type: TASK_STATE_LOADING});

        const res = await axios.post('/api/v1/state', taskState, tokenConfig());

        dispatch({
            type: GET_STATES,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASK_STATE_ERROR));
    } finally {
        dispatch({type: CLEAR_TASK_STATE_LOADING});
    }
}


export const updateTaskState = taskState => async dispatch => {
    try {
        dispatch({type: TASK_STATE_LOADING});

        const res = await axios.put('/api/v1/state', taskState, tokenConfig());

        dispatch({
            type: GET_STATES,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASK_STATE_ERROR));
    } finally {
        dispatch({type: CLEAR_TASK_STATE_LOADING});
    }
}

export const deleteTaskState = taskState => async dispatch => {
    try {
        dispatch({type: TASK_STATE_LOADING});

        const config = {
            ...tokenConfig(),
            data: taskState,
        }

        const res = await axios.delete('/api/v1/state', config);

        dispatch({
            type: GET_STATES,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASK_STATE_ERROR));
    } finally {
        dispatch({type: CLEAR_TASK_STATE_LOADING});
    }
}