import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles({
    input: {
        background: "#fff",
    },
    select: {
        marginBottom: '1rem',
    },
    button: {
        width: 'auto',
        alignSelf: 'center',
        justifySelf: 'center',
        marginBottom: '1.5rem',
    }
});

const StoreTireSelect = ({removeTireSelect, updateParent, configToUpdate, selectedPositions, configLength}) => {
    const [tirePosition, setTirePosition] = useState('');
    const [brand, setBrand] = useState('');
    const [profile, setProfile] = useState('');
    const [dot, setDot] = useState('');

    const classes = useStyles();

    const handleTirePosition = e => {
        setTirePosition(e.target.value);

        updateParent({
            _id: configToUpdate._id,
            tirePosition: e.target.value,
            brand,
            profile,
            dot,
        });
    }
    const updateSettings = (e, type) => {
        switch (type) {
            case 'brand':
                setBrand(e.target.value);

                updateParent({
                    _id: configToUpdate._id,
                    tirePosition,
                    brand: e.target.value,
                    profile,
                    dot,
                });
                break;
            case 'profile':
                setProfile(e.target.value);

                updateParent({
                    _id: configToUpdate._id,
                    tirePosition,
                    brand,
                    profile: e.target.value,
                    dot,
                });

                break;
            case 'dot':
                setDot(e.target.value);

                updateParent({
                    _id: configToUpdate._id,
                    tirePosition,
                    brand,
                    profile,
                    dot: e.target.value,
                });

                break;
            default:
                break;
        }
    }

    // Set values on update task
    useEffect(() => {
        if (!configToUpdate) return;

        setTirePosition(configToUpdate.tirePosition ?? '');
        setProfile(configToUpdate.profile ?? '');
        setBrand(configToUpdate.brand ?? '');
        setDot(configToUpdate.dot ?? '')

    }, [configToUpdate]);

    return (
        <div className="config__grid config__grid--5">
            <FormControl variant="filled" className={classes.select}>
                <InputLabel>Position</InputLabel>
                <Select
                    value={tirePosition}
                    onChange={handleTirePosition}
                >
                    <MenuItem
                        value={'VL'}
                        disabled={selectedPositions.filter(p => p === 'VL').length !== 0}>VL</MenuItem>
                    <MenuItem
                        value={'VR'}
                        disabled={selectedPositions.filter(p => p === 'VR').length !== 0}>VR</MenuItem>
                    <MenuItem
                        value={'HL'}
                        disabled={selectedPositions.filter(p => p === 'HL').length !== 0}>HL</MenuItem>
                    <MenuItem
                        value={'HR'}
                        disabled={selectedPositions.filter(p => p === 'HR').length !== 0}>HR</MenuItem>
                </Select>
            </FormControl>

            <FormGroup>
                <TextField
                    InputProps={{className: classes.input}}
                    label="Marke / Typ"
                    variant="filled"
                    name="brand"
                    value={brand}
                    onChange={e => updateSettings(e, 'brand')}
                />
            </FormGroup>
            <FormGroup>
                <TextField
                    InputProps={{inputProps: {className: classes.input, type: 'number', min: 0}}}
                    label="Profil (mm)"
                    variant="filled"
                    name="profile"
                    value={profile}
                    error={profile < 0}
                    onChange={e => updateSettings(e, 'profile')}
                />
            </FormGroup>

            <FormGroup>
                <TextField
                    InputProps={{inputProps: {className: classes.input}}}
                    label="DOT"
                    variant="filled"
                    name="profile"
                    value={dot}
                    onChange={e => updateSettings(e, 'dot')}
                />
            </FormGroup>

            <Button
                variant="outlined"
                color="secondary"
                startIcon={<DeleteIcon/>}
                className={classes.button}
                disabled={configLength === 1}
                onClick={removeTireSelect}>Löschen</Button>

        </div>
    );
};

export default StoreTireSelect;
