// import {createTheme} from "@material-ui/core/styles";
import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core';
import {deDE as coreDeDE} from "@material-ui/core/locale";
import {deDE} from "@material-ui/data-grid";

// Overrides default material ui design theme
export const createMaterialTheme = darkMode => {
    if (!darkMode) {
        return createMuiTheme({
                palette: {
                    type: 'light',
                    primary: {
                        light: '#FFD84D',
                        main: '#FFC900',
                        dark: '#EBAD00',
                        contrastText: '#000',
                    },
                    secondary: {
                        light: '#31312b',
                        main: '#27230B',
                        dark: '#0D0B26',
                        contrastText: '#fff',
                    },
                    error: {
                        light: '#e57373',
                        main: '#f44336',
                        dark: '#d32f2f',
                    },
                    success: {
                        light: 'rgba(70, 196, 24, .15)',
                        main: 'rgba(70, 196, 24, .25)',
                        dark: 'rgba(70, 196, 24, .5)',
                    }
                },
                typography: {
                    fontFamily: [
                        'Gilroy',
                        'sans-serif',
                        'Roboto',
                        'Arial',
                        'sans-serif',
                    ].join(','),
                },
                overrides: {
                    MuiButton: {
                        root: {
                            fontWeight: 600,
                        }
                    },
                    MuiFormLabel: {
                        root: {
                            "&$focused": {
                                color: '#000',
                            },
                        }
                    },
                    MuiFormGroup: {
                        root: {
                            height: '100%',
                            paddingBottom: '1.5rem',
                        }
                    },
                    MuiFormControl: {
                        root: {
                            height: '100%',

                        }
                    },
                    MuiAlert: {
                        filledSuccess: {
                            color: '#3A9520'
                        },
                    },
                    MuiAlertTitle: {
                        root: {
                            fontWeight: 600,
                        }
                    }

                }
            },
            coreDeDE,
            deDE,
        );

    } else {
        /*        return createMuiTheme({
                        palette: {
                            type: darkMode ? 'dark' : 'light',
                            primary: {
                                light: '#FFD84D',
                                main: '#FFC900',
                                dark: '#806400',
                                contrastText: '#fff',
                            },
                            secondary: {
                                light: '#59BFFF',
                                main: '#0DA3FF',
                                dark: '#065180',
                                contrastText: '#fff',
                            },
                            error: {
                                light: '#e57373',
                                main: '#f44336',
                                dark: '#d32f2f',
                            },
                            success: {
                                light: '#a2cf6e',
                                main: '#8bc34a',
                                dark: '#618833',
                            }
                        },
                        typography: {
                            fontFamily: [
                                'Gilroy',
                                'sans-serif',
                                'Roboto',
                                'Arial',
                                'sans-serif',
                            ].join(','),
                        },
                        overrides: {
                            MuiFormGroup: {
                                root: {
                                    height: '100%',
                                }
                            },
                            MuiFormControlLabel: {
                                root: {
                                    height: '100%',
                                }
                            },
                            MuiInputBase: {
                                root: {
                                    height: '100%',
                                }
                            },
                            MuiAlert: {
                                standardSuccess: {
                                    color: '#000',
                                },
                                root: {
                                    filledSuccess: {
                                        MuiAlertTitle: {
                                            color: '#000',
                                        }
                                    }
                                },
                            },
                            MuiAlertTitle: {
                                root: {
                                    fontWeight: 'bold',
                                },
                            }
                        }

                    },
                    coreDeDE,
                    deDE,
                );*/
    }

}
