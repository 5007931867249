import {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {clearErrors} from "../../actions/errorActions";

const DisplayError = ({error, component}) => {
    const dispatch = useDispatch();

    const mail = 'digital@hi-schweiz.ch';
    const subject = `?subject=Digitale Arbeitskarte: ${error?.id}`
    let body = `&body=${encodeURIComponent('URL: ')}${encodeURIComponent(window.location.href)}`;

    if (error?.status) {
        body += `%0D%0A${encodeURIComponent('Status: ')}${encodeURIComponent(error?.status)}`;
    }
    body += `%0D%0A${encodeURIComponent('ID: ')}${encodeURIComponent(error?.id)}`;
    body += `%0D%0A${encodeURIComponent('Message: ')}${encodeURIComponent(error?.msg)}`;
    body += `%0D%0A${encodeURIComponent('Component: ')}${encodeURIComponent(component)}`;
    const clearErrorRef = useRef(() => {
        dispatch(clearErrors());
    });


    // Clear errors on unmounting component
    useEffect(() => {
        let clearErrorFunction = () => {
        };
        clearErrorFunction = clearErrorRef.current;

        return () => {
            clearErrorFunction();
        }
    }, []);

    return (
        <div className="container">
            <div className="row row-justify-center">
                <div className="gr-24 gr-12@small gr-8@medium">
                    <div className="error__card">
                        <div className="error__title">
                            Es ist folgender Fehler aufgetreten:
                        </div>
                        <div className="error__desc">
                            <ul>
                                {error?.status && <li>Status: {error.status}</li>}
                                {error?.id && <li>ID: {error.id}</li>}
                                {error?.msg && <li>Message: {error.msg}</li>}
                            </ul>
                        </div>
                        <div className="error__cta">
                            <a className="btn__primary" href={"mailto:" + mail + subject + body}>Fehler melden</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisplayError;