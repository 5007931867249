import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAllStates} from '../../../actions/taskStateActions';

// @Material-ui/core components
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";


const useStyles = makeStyles({
    btn: {
        width: '100%',
        marginTop: '1rem',
        marginBottom: '2rem',
    },
});

const StateButton = React.memo(function ({state, updateLoading, updateFunction, task}){
        const dispatch = useDispatch();
        const history = useHistory();

        const {loading, allStates} = useSelector(state => ({
            loading: state.taskStates.statesLoading,
            allStates: state.taskStates.allStates,
        }));

        const [anchorEl, setAnchorEl] = useState(null);


        const classes = useStyles();


        const loadAllStates = useRef(() => {
            dispatch(getAllStates());
        });

        useEffect(() => {
            loadAllStates.current();
        }, []);

        // Menu button helper functions
        const handleUpdate = (item) => {
            updateFunction(item);
            setAnchorEl(null);
        }

        const handleDone = (item) => {
            history.push("/task/" + task.taskId + "/done")
        }

        const toggleMenu = e => {
            setAnchorEl(e.currentTarget)
        }

        const handleClose = () => {
            setAnchorEl(null);
        }

        // If state is abgeschlossen or higher
        if (state?.stateTitle === 'abgeschlossen') {
            return <></>
        }


        return (
            <>
                <Button className={classes.btn}
                        disabled={loading || updateLoading}
                        aria-controls="menu-state"
                        color="primary"
                        variant="contained"
                        aria-haspopup="true"
                        onClick={toggleMenu}>Auftragsstatus ändern</Button>
                <Menu
                    id="menu-state"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {allStates?.sort((a, b) => a?.order - b?.order)?.map(item => (
                        <MenuItem disabled={state?._id === item?._id}
                                  key={item._id}
                                  onClick={() => {
                                      if(item.stateTitle !== "abgeschlossen"){
                                          handleUpdate(item);
                                      }else {
                                        handleDone(item);
                                      }
                                  }}
                                  value={item}>{item.stateTitle}</MenuItem>
                    ))}
                </Menu>

            </>

        );
    }
);

export default StateButton;
