import {useDispatch, useSelector} from "react-redux";
import React, {useRef, useEffect, useState} from "react";
import {checkUserPermission, loggedInUser} from "../../../helpers/authHelper";
import {createBranch, deleteBranch, getAllBranches, updateBranch} from "../../../actions/branchActions";
import {validateForm} from "../../../helpers/helpers";
import NoAdmin from "./NoAdmin";
import PageHeader from "../../includes/PageHeader";
import LoadingButton from "../../includes/LoadingButton";

// Material UI imports
import {DataGridPro} from "@mui/x-data-grid-pro";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

// Licensed Data Grid Pro
import {LicenseInfo} from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
    'f46eec4ce6a948aab2dc3b60e13984d0T1JERVI6Mjk4MDQsRVhQSVJZPTE2NjM5NDQxMTEwMDAsS0VZVkVSU0lPTj0x',
);

const ManageBranches = () => {
    const user = loggedInUser();
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);


    // User feedback / flow
    const form = useRef(null);
    const [allFormErrors, setAllFormErrors] = useState([]);
    const [branchId, setBranchId] = useState(null);
    const [toDelete, setToDelete] = useState(null);

    // Branch values
    const [title, setTitle] = useState('');
    const [street, setStreet] = useState('');
    const [plz, setPlz] = useState('');
    const [place, setPlace] = useState('');


    const {allBranches, loading, success} = useSelector(state => ({
        allBranches: state.branch.allBranches,
        loading: state.branch.branchLoading,
        success: state.success,
    }));

    const loadBranches = useRef(() => {
        dispatch(getAllBranches());
    });


    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            hide: true,
        },
        {
            field: 'title',
            headerName: 'Standort',
            width: 250,
            headerClassName: 'overview__data',
        },
        {
            field: 'street',
            headerName: 'Strasse',
            width: 250,
            headerClassName: 'overview__data',
        },
        {
            field: 'plz',
            headerName: 'PLZ',
            width: 250,
            headerClassName: 'overview__data',
        },
        {
            field: 'place',
            headerName: 'Ort',
            width: 250,
            headerClassName: 'overview__data',
        },
        {
            field: 'update',
            width: 25,
            flex: true,
            headerClassName: 'hide__header',
            renderHeader: () => (<></>),
            renderCell: params => {
                return (<IconButton
                    color="secondary"
                    onClick={e => setBranchToUpdate(params, e)}
                    size="small">
                    <EditIcon/>
                </IconButton>)
            },
        },
        {
            field: 'delete',
            width: 25,
            flex: true,
            headerClassName: 'hide__header',
            renderHeader: () => (<></>),
            renderCell: params => {
                return (<IconButton
                    onClick={e => setBranchToDelete(params, e)}
                    size="small">
                    <DeleteIcon color="error"/>
                </IconButton>)
            },
        },
    ];

    const setBranchToDelete = (param, e) => {
        e.preventDefault();

        const id = param.id;
        const branch = allBranches.find(b => b._id === id);
        setToDelete(branch);
    }

    const setBranchToUpdate = (param, e) => {
        e.preventDefault();

        const id = param.id;
        const branch = allBranches.find(b => b._id === id);

        setBranchId(id);
        setTitle(branch.title);
        setStreet(branch.street ?? '');
        setPlz(branch.plz ?? '');
        setPlace(branch.place ?? '');
    }


    const resetValues = () => {
        setBranchId(null);
        setTitle('');
        setStreet('');
        setPlz('');
        setPlace('');
        setToDelete(null);
    }

    const save = async e => {
        e.preventDefault();

        const {errors, inputsValid} = validateForm(form.current);
        setAllFormErrors(errors);


        if (!inputsValid) return;

        // Check if a branch gets updated or created
        if (branchId) {
            dispatch(updateBranch({
                _id: branchId,
                title,
                street,
                plz,
                place,
            }));
        } else {
            dispatch(createBranch({
                title,
                street,
                plz,
                place,
            }));
        }
    }

    const handleDelete = async e => {
        e.preventDefault();
        dispatch(deleteBranch(toDelete));
    }

    useEffect(() => {
        if (loading || !allBranches?.length) return;

        const localBranches = [];

        allBranches?.forEach(branch => {
            localBranches.push({
                ...branch,
                id: branch._id,
                edit: branch,
                delete: branch,
            });
        });

        setRows(localBranches);
    }, [allBranches, setRows, loading]);

    // Reset values if action was a success
    useEffect(() => {
        if (success) {
            resetValues();
        }
    }, [success]);


    useEffect(() => {
        loadBranches.current();
    }, []);

    if (!checkUserPermission(user, 'editBranches')) {
        return <NoAdmin/>
    }

    return (
        <>
            <PageHeader pageTitle={`Filialen`} backRoute="/admin"/>

            <Dialog fullWidth open={!!toDelete} onClose={() => setToDelete(null)}>
                {toDelete && <>
                    <DialogTitle>
                        Standort «{toDelete.title}» löschen
                    </DialogTitle>

                    <DialogContent>
                        <div className="page__text">
                            {toDelete.title}
                            {toDelete.street && `, ${toDelete.street}`}
                            {toDelete.plz && toDelete.place && `, ${toDelete.plz} ${toDelete.place}`}
                            {' '}wird gelöscht.
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => setToDelete(null)} color="secondary">Abbrechen</Button>
                        <LoadingButton loading={loading}
                                       onClick={handleDelete}
                                       text="Bestätigen"/>
                    </DialogActions>
                </>}

            </Dialog>


            <div className="container">
                <div className="row">
                    <div className="gr-24">
                        <div className="page__title">Filialen</div>

                        <DataGridPro
                            columns={columns}
                            rows={rows}
                            disableSelectionOnClick={true}
                            disableMultipleSelection={true}
                            autoHeight={true}
                            loading={loading}
                            onCellDoubleClick={setBranchToUpdate}
                            style={{width: '100%', marginBottom: '2rem'}}
                        />
                    </div>


                    <div className="gr-24">
                        <div className="page__title">Filiale {branchId ? 'aktualisieren' : 'erfassen'}</div>
                        <form onSubmit={save} id="branch-form" ref={form}>
                            <div className="row">
                                <div className="gr-24 gr-12@small">
                                    <FormGroup>
                                        <TextField
                                            label="Standort"
                                            type="text"
                                            required
                                            variant="filled"
                                            name="title"
                                            value={title}
                                            error={allFormErrors.includes('title')}
                                            helperText={allFormErrors.includes('title') ? 'Standort eingeben' : ''}
                                            onChange={e => setTitle(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="gr-24 gr-12@small">
                                    <FormGroup>
                                        <TextField
                                            label="Strasse"
                                            type="text"
                                            variant="filled"
                                            name="street"
                                            value={street}
                                            onChange={e => setStreet(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="gr-24 gr-12@small">
                                    <FormGroup>
                                        <TextField
                                            label="PLZ"
                                            type="text"
                                            variant="filled"
                                            name="plz"
                                            value={plz}
                                            onChange={e => setPlz(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="gr-24 gr-12@small">
                                    <FormGroup>
                                        <TextField
                                            label="Ort"
                                            type="text"
                                            variant="filled"
                                            name="place"
                                            value={place}
                                            onChange={e => setPlace(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>


                                <div className="gr-24">
                                    <div className="branch__buttons">
                                        <LoadingButton type="submit" loading={loading}
                                                       variant="contained"
                                                       text="Speichern"
                                                       color="primary"
                                                       onClick={save}/>
                                        <Button type="reset"
                                                variant="contained"
                                                color="default"
                                                onClick={resetValues}>Zurücksetzen</Button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManageBranches;
