import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from '@material-ui/core/Snackbar';
import {useEffect, useState} from "react";

// Alert in Notification
const Alert = (props) => {
    return <MuiAlert elevation={3} variant="filled" {...props} />;
}
const InlineFeedback = ({
                            open,
                            closeFunc = () => {
                            },
                            error = false,
                            message,
                        }) => {

    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const [msg, setMsg] = useState(null);

    useEffect(() => {
        setFeedbackOpen(open);
        setMsg(message);
    }, [open, setFeedbackOpen, message]);

    const handleClose = () => {
        setFeedbackOpen(false);
        closeFunc();
    }

    return (
        <Snackbar open={feedbackOpen}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                  autoHideDuration={5000}
                  onClose={handleClose}>
            <div>
                <Alert onClose={handleClose} severity={error ? "error" : "success"}>
                    {msg}
                </Alert>
            </div>
        </Snackbar>
    )
}

export default InlineFeedback;