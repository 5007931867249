import DeleteIcon from "@material-ui/icons/Delete";

const DumpIconWithTitle = ({title, amount, price}) => {
    let displaytitle =" ";
    switch(title){
        case "wheels":
            displaytitle = amount > 1 ? "Räder" : "Rad";
            break;
        case "tire":
            displaytitle = "Reifen";
            break;
        case "rims":
            displaytitle = amount > 1 ? "Felgen" : "Felge";
            break;
    }
    return (
        <div className="to-check">
            <DeleteIcon style={{marginRight: '.5rem'}}/>
            <p>{amount}x {displaytitle} Preis: {price} CHF</p>
        </div>
    );
};

export default DumpIconWithTitle;
