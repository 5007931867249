import React from "react"
import DefaultTireSelection from './config/DefaultTireSelection';
import NewTire from "./config/NewTire";
import Repair from "./config/Repair";
import Cleaning from "./config/Cleaning";
import Buy from "./config/Buy";
import DumpTires from "./config/DumpTires";
import StoreTires from "./config/StoreTires";
import ChangeTire from "./config/ChangeTire";
import BalanceTires from "./config/BalanceTires";
import CleanTire from "./config/CleanTire";

const TaskHandler = React.memo(function ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList, additionalToUpdate, updateParentAdditional}){
    switch (task.category) {
        case 'newTires':
            return <NewTire task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                            taskToUpdate={taskToUpdate} taskList={taskList} additionalToUpdate={additionalToUpdate} updateParentAdditional={updateParentAdditional}/>
        case 'changeTire':
            // Reifen- & Pneuwechsel config
            return <ChangeTire task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                               taskToUpdate={taskToUpdate} taskList={taskList}/>
        case 'repair':
            // Reparatur config
            return <Repair task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                           taskToUpdate={taskToUpdate} taskList={taskList}/>
        case 'cleaning' :
            // Reinigung config
            return <Cleaning task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                             taskToUpdate={taskToUpdate} taskList={taskList}/>
        case 'buy':
            // Kauf config
            return <Buy task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                        taskToUpdate={taskToUpdate} taskList={taskList}/>
        case 'dumpTires' :
            // Reifen entsorgen config
            return <DumpTires task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                              taskToUpdate={taskToUpdate} taskList={taskList}/>
        case 'storeTires' :
            // Reifen einlagern config
            return <StoreTires task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                               taskToUpdate={taskToUpdate} taskList={taskList}/>
        case 'balance' :
            // Auswuchten config
            return <BalanceTires task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                                 taskToUpdate={taskToUpdate} taskList={taskList}/>
        case 'cleanTire' :
            // Auswuchten config
            return <CleanTire task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                                 taskToUpdate={taskToUpdate} taskList={taskList}/>
        default:
            // Default tire selection with title of task
            return <DefaultTireSelection task={task} removeFunction={removeFunction} toggleTask={toggleTask} updateMainTask={updateMainTask}
                                         taskToUpdate={taskToUpdate} taskList={taskList}/>
    }

});

export default TaskHandler;
