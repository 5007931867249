import {axiosInstance as axios} from '../helpers/axios';
import {clearLoading, setLoading} from "./loadingActions";
import {setErrors} from "./errorActions";
import {
    CLEAR_TASK,
    CLEAR_TASK_LOADING,
    CLEAR_TASKS_LOADING,
    CREATE_TASK,
    GET_TASK,
    GET_TASKS,
    TASK_ERROR,
    TASK_LOADING,
    TASKS_ERROR, TASKS_LOADING, UPDATE_TASK
} from "./types";
import {tokenConfig} from "./authActions";
import {setSuccess} from "./successActions";


export const getTasks = () => async dispatch => {
    try {
        dispatch({type: TASKS_LOADING});

        const res = await axios.get('/api/v1/task', tokenConfig());

        dispatch({
            type: GET_TASKS,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err, TASK_ERROR));
    } finally {
        dispatch({type: CLEAR_TASKS_LOADING});
    }
}

export const updateTaskList = res => async dispatch => {
    try {
        dispatch({type: TASKS_LOADING});

        dispatch({
            type: GET_TASKS,
            payload: res.data,
        });

    } catch (err) {
        dispatch(setErrors(err, TASK_ERROR));
    } finally {
        dispatch({type: CLEAR_TASKS_LOADING});
    }
}

export const getTaskById = id => async dispatch => {
    try {
        dispatch(setLoading());
        const res = await axios.get(`/api/v1/task/${id}`, tokenConfig());
        dispatch({
            type: GET_TASK,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err, TASK_ERROR));
    } finally {
        dispatch(clearLoading());
    }
}

export const createTask = (task) => async dispatch => {
    try {
        dispatch({type: TASK_LOADING});

        const res = await axios.post('/api/v1/task', task, tokenConfig());

        dispatch({
            type: CREATE_TASK,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASKS_ERROR));
    } finally {
        await dispatch({type: CLEAR_TASK_LOADING});
    }
}

export const updateTask = task => async dispatch => {
    try {
        dispatch({type: TASK_LOADING});

        const res = await axios.put('/api/v1/task', task, tokenConfig());

        await dispatch({
            type: UPDATE_TASK,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASK_ERROR));
    } finally {
        await dispatch({type: CLEAR_TASK_LOADING});
    }
}

export const updateTaskWithState = (task, state) => async dispatch => {
    try {
        dispatch({type: TASK_LOADING});

        const res = await axios.put('/api/v1/task/state', {task: task?._id, state}, tokenConfig());

        await dispatch({
            type: UPDATE_TASK,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASK_ERROR));
    } finally {
        await dispatch({type: CLEAR_TASK_LOADING});
    }
}

export const updateTaskWithTime = (task, time) => async dispatch => {
    try {
        dispatch({type: TASK_LOADING});

        const res = await axios.put('/api/v1/task/time', {task: task?._id, time}, tokenConfig());

        await dispatch({
            type: UPDATE_TASK,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASK_ERROR));
    } finally {
        await dispatch({type: CLEAR_TASK_LOADING});
    }
}

export const deleteTask = task => async dispatch => {
    try {
        dispatch({type: TASK_LOADING});

        const config = {
            ...tokenConfig(),
            data: task,
        }

        const res = await axios.delete('/api/v1/task', config);

        await dispatch({
            type: GET_TASKS,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, TASK_ERROR));
    } finally {
        await dispatch({type: CLEAR_TASK_LOADING});
    }
}


// Reset store value
export const clearTask = () => async dispatch => {
    try {
        dispatch({type: CLEAR_TASK})
    } catch (e) {
        dispatch(setErrors(e, TASK_ERROR));
    }
}