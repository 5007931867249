import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const InventoryIconWithTitle = ({title, amount, prodnr}) => {
    return (
        <div className="to-check">
            <ShoppingCartIcon style={{marginRight: '.5rem'}}/>
            <p>{amount} x {title} ProduktNr: {prodnr}</p>
        </div>
    );
};

export default InventoryIconWithTitle;
