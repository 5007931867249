import {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {isAuthenticated} from "../../helpers/authHelper";
import {checkRefreshToken, loginUser} from "../../actions/authActions";
import {validateForm} from "../../helpers/helpers";
import Header from "../includes/Header";

// Material UI Imports
import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import LoadingButton from "../includes/LoadingButton";
import Loading from "../includes/Loading";

const Login = () => {
    const dispatch = useDispatch();
    const form = useRef(null);
    const [formErrors, setFormErrors] = useState([]);

    const history = useHistory();
    const location = useLocation();
    const {from} = location.state || {from: {pathname: "/"}};

    const {loginLoading, loading, user} = useSelector(state => ({
        loginLoading: state.auth.loginLoading,
        loading: state.loading,
        user: state.auth.user,
    }));

    // Input Fields
    const [username, setUsername] = useState('');
    const [pass, setPass] = useState('');


    const handleLogin = async e => {
        e.preventDefault();

        const {inputsValid, errors} = validateForm(form.current);

        if (!inputsValid) {
            setFormErrors(errors);
        } else {
            await dispatch(loginUser({username, password: pass}));
        }
    }


    const redirect = useRef(() => {
        history.replace(from);
    });

    const checkToken = useRef(async () => {
        await dispatch(checkRefreshToken);

        // If the refresh token was valid, a new token is set in the local storage
        if (user && isAuthenticated()) {
            redirect.current();
        }
    });


    useEffect(() => {
        // Always check the refresh token
        checkToken.current();
    }, []);

    // Redirect if user is already logged in and the token is valid
    useEffect(() => {
        if (isAuthenticated()) {
            redirect.current();
        }
    }, [history, from]);

    // Check if the user got logged in
    useEffect(() => {
        if (user && isAuthenticated()) {
            redirect.current();
        }
    }, [user]);


    if (loading || loginLoading) {
        return (
            <>
                <Header/>
                <Loading/>
            </>
        );
    }

    return (
        <>
            <Header/>

            <div className="container">
                <div className="row row-justify-center">
                    <div className="gr-24 gr-18@tiny gr-14@small gr-8@large">
                        <div className="login__wrapper">
                            <form ref={form} className="form form__full login__form" id="login">
                                <div className="form__group">
                                    <TextField
                                        variant="filled"
                                        error={formErrors.includes("username")}
                                        type="text"
                                        label="Benutzername"
                                        required
                                        name="username"
                                        inputProps={{autoComplete: 'on'}}
                                        value={username.toLowerCase()}
                                        onChange={e => setUsername(e.target.value)}
                                    />
                                    {formErrors.includes("username") &&
                                        <FormHelperText>Benutzernamen eingeben</FormHelperText>}
                                </div>
                                <div className="form__group">
                                    <TextField
                                        variant="filled"
                                        type="password"
                                        error={formErrors.includes("pass")}
                                        name="pass"
                                        value={pass}
                                        label="Passwort"
                                        required
                                        inputProps={{autoComplete: 'on'}}
                                        onChange={e => setPass(e.target.value)}/>
                                    {formErrors.includes("pass") &&
                                        <FormHelperText>Passwort eingeben</FormHelperText>}
                                </div>
                                <Box pt={3}>
                                    <div className="tac">
                                        <LoadingButton form="login" type="submit" loading={loginLoading} color="primary"
                                                       size="large" onClick={e => handleLogin(e)}
                                                       variant="contained" text={'Login'}>Login</LoadingButton>
                                    </div>
                                </Box>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;