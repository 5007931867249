import {useEffect, useRef, useState} from 'react';
import {axiosInstance as axios} from '../../helpers/axios';
import {formatDate, validateFormInput} from "../../helpers/helpers";
import {tokenConfig} from "../../actions/authActions";
import {loggedInUser} from "../../helpers/authHelper";

// Material UI imports
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import {Alert, AlertTitle} from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from '@material-ui/icons/Close';

const ErrorBtn = () => {
    const [showDialogue, setShowDialogue] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('');
    const [textAreaError, setTextAreaError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);

    const emailRef = useRef(null);

    const handleSubmit = async () => {
        setEmailError(false);
        setTextAreaError(false);

        const emailInput = emailRef.current.querySelector('input')

        if (!errorMessage) {
            setTextAreaError(true);
            return;
        }

        if (email && !validateFormInput(emailInput)) {
            setEmailError(true);
            return;
        }

        const user = loggedInUser();


        // Send email
        const body = {
            email,
            message: errorMessage,
            time: formatDate(new Date().toString()),
            url: window.location.href,
            user: user ? {
                id: user.id,
                username: user.username,
                admin: user.isAdmin ? 'ja' : 'nein',
                branch: user.branch ? user.branch.title : '',
            } : null,
        }

        const res = await axios.post('/api/v1/email', body, tokenConfig())


        if (res.status === 200) {
            setDisplaySuccess(true);
            setErrorMessage('');
            setEmail('');
        }

    }

    const handleClose = () => {
        setShowDialogue(false);
    }

    // Reset feedback on load
    const resetFeedback = useRef(() => {
        setDisplaySuccess(false);
        setErrorMessage('');
        setEmail('');
    });

    useEffect(() => {
        resetFeedback.current();
    }, []);


    return (
        <div className="feedback">

            <div className="feedback__trigger">
                <div className="feedback__text">Fehler melden</div>
                <IconButton color="secondary" onClick={() => {
                    setDisplaySuccess(false);
                    setShowDialogue(!showDialogue)
                }}>
                    <WarningRoundedIcon fontSize="large"/>
                </IconButton>
            </div>

            <div className="feedback__wrapper">

                {showDialogue &&
                    <div className="feedback__dialogue">
                        {!displaySuccess &&
                            <>
                                <div className="dialogue__form">
                                    <div className="dialogue__close">
                                        <div className="page__text page__text--no-mb">Fehler melden</div>
                                        <CloseIcon fontSize="large" onClick={handleClose}/>
                                    </div>

                                    <FormGroup>
                                        <TextField
                                            label="E-Mail"
                                            variant="outlined"
                                            value={email}
                                            type="email"
                                            name="email"
                                            ref={emailRef}
                                            error={emailError}
                                            helperText={emailError && 'Bitte korrekte E-Mail Adresse eingeben'}
                                            onChange={e => setEmail(e.target.value)}
                                        /></FormGroup>
                                    <FormGroup>
                                        <TextField
                                            label="Fehlerbeschreibung"
                                            required
                                            variant="outlined"
                                            value={errorMessage}
                                            name="errorMessage"
                                            multiline
                                            rows={5}
                                            error={textAreaError}
                                            helperText={textAreaError && 'Bitte Fehlerbeschreibung eingeben'}
                                            onChange={e => setErrorMessage(e.target.value)}
                                        /></FormGroup>
                                </div>
                                <button onClick={handleSubmit} className="dialogue__submit">Senden</button>
                            </>}


                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={displaySuccess}
                            autoHideDuration={3000}
                            onClose={handleClose}>

                            <Alert onClose={handleClose} severity="success">
                                <AlertTitle>Vielen Dank!</AlertTitle>
                                Die Nachricht wurde erfolgreich übermittelt.
                            </Alert>
                        </Snackbar>

                    </div>
                }
            </div>
        </div>
    );
};

export default ErrorBtn;
