import jwt from 'jsonwebtoken';
import {axiosInstance as axios} from '../helpers/axios';

export const loggedInUser = () => {
    const token = localStorage.getItem("token");
    if (!token) return null;
    try {
        const {user} = jwt.decode(token);
        return user;
    } catch (err) {
        return null;
    }
}

export const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (!token) return false;
    try {
        const {exp} = jwt.decode(token);
        return exp >= (new Date().getTime() + 1) / 1000;
    } catch (err) {
        return false;
    }
}

export const setNewTokenInStorage = async headers => {
    if (!headers) return;
    const token = headers['x-refresh-token'];

    if (token) {
        // Get a new access token if the refresh token was sent with the request
        const res = await axios.get('/api/v1/auth/login');

        if (res?.data?.success) {
            localStorage.setItem('token', res.data.data.token);
        }
    }
}

// Checks users privileges based on the userGroup
export const checkUserPermission = (user, permission) => {
    if (!user || !permission) return false;

    // Check for old permission system for backwards compatibility
    if (!user?.userGroup?.privileges) {
        return user?.isAdmin ?? false;
    }
    return user?.userGroup?.privileges[permission] ?? false;
}
