import {useState} from 'react';

// Material UI imports
import {makeStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from "@material-ui/core/Checkbox";


const useStyles = makeStyles({
    input: {
        background: "#fff"
    },
});


const Buy = ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList}) => {
    const defaultConfig = {
        products: [
            {amount: 1, productDescription: '', productId: '', order: new Date()}
        ]
    }
    const [taskConfig, setTaskConfig] = useState(taskToUpdate.filter(t => t.task.id === task.id)[0]?.taskConfig ?? defaultConfig);

    const addProduct = () => {
        setTaskConfig({
            ...taskConfig,
            products: [
                ...taskConfig.products,
                {amount: 1, productDescription: '', productId: '', order: new Date()}
            ],
        });
        updateMainTask({
            ...taskConfig,
            products: [
                ...taskConfig.products,
                {amount: 1, productDescription: '', productId: '', order: new Date()}
            ],
        }, task);
    }

    const removeProduct = product => {
        setTaskConfig({
            ...taskConfig,
            products: [
                ...taskConfig.products.filter((p, index) => index !== product),
            ],
        });
        updateMainTask({
            ...taskConfig,
            products: [
                ...taskConfig.products.filter((p, index) => index !== product),
            ],
        }, task);
    }

    const updateProducts = (product, index, value) => {
        const productToUpdate = taskConfig.products[product];
        let newConfig = {};

        switch (index) {
            case 'amount':
                setTaskConfig({
                    ...taskConfig,
                    products: [
                        ...taskConfig.products.filter((p, index) => index !== product),
                        {
                            ...productToUpdate,
                            amount: value
                        }
                    ]
                });
                newConfig = {
                    ...taskConfig,
                    products: [
                        ...taskConfig.products.filter((p, index) => index !== product),
                        {
                            ...productToUpdate,
                            amount: value
                        }
                    ]
                }
                break;
            case 'productDescription':
                setTaskConfig({
                    ...taskConfig,
                    products: [
                        ...taskConfig.products.filter((p, index) => index !== product),
                        {
                            ...productToUpdate,
                            productDescription: value
                        }
                    ]
                });
                newConfig = {
                    ...taskConfig,
                    products: [
                        ...taskConfig.products.filter((p, index) => index !== product),
                        {
                            ...productToUpdate,
                            productDescription: value
                        }
                    ]
                }

                break;
            case 'productId':
                setTaskConfig({
                    ...taskConfig,
                    products: [
                        ...taskConfig.products.filter((p, index) => index !== product),
                        {
                            ...productToUpdate,
                            productId: value
                        }
                    ]
                });
                newConfig = {
                    ...taskConfig,
                    products: [
                        ...taskConfig.products.filter((p, index) => index !== product),
                        {
                            ...productToUpdate,
                            productId: value
                        }
                    ]
                }
                break;
            default:
                throw new Error(`Case with value ${index} not found in statement.`)
        }

        if (!newConfig) {
            throw new Error('New buy task config is invalid.');
        }

        updateMainTask(newConfig, task);
    }

    const classes = useStyles();


    return (
        <div className="row row-align-center">
            <div className="config__title gr-4 gr-2@small task__config--checkbox">
                <Checkbox
                    checked={taskList.filter(t => t.id === task.id).length}
                    onChange={(e) => {
                        toggleTask(task, setTaskConfig, taskConfig, updateMainTask, '', '')}}
                    onClick={() => removeFunction(task)}
                    name="allTyres"
                    color="default"
                />
            </div>
            <div className="config__title task__config task__config--round gr-8 gr-4@small suffix-1">
                {task.title}
            </div>
            <div className="config__content task__config gr-24 gr-16@small gr-17@large">
                {taskConfig.products.sort((a, b) => a.order - b.order).map((p, index) =>
                    <div key={index}
                         className="config__grid config__grid--buy">
                        <FormGroup>
                            <TextField
                                InputProps={{className: classes.input}}
                                type="number"
                                label="Anzahl"
                                inputProps={{pattern: '[0-9-]+', min: 1}}
                                variant="filled"
                                name="amount"
                                min={1}
                                value={p.amount}
                                onChange={e => updateProducts(index, 'amount', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextField
                                InputProps={{className: classes.input}}
                                label="Produktbezeichnung"
                                inputProps={{pattern: '[a-zA-Z0-9]+'}}
                                variant="filled"
                                name="productDescription"
                                value={p.productDescription}
                                onChange={e => updateProducts(index, 'productDescription', e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextField
                                InputProps={{className: classes.input}}
                                label="Produkt Nr."
                                inputProps={{pattern: '[0-9-]+'}}
                                variant="filled"
                                name="productId"
                                value={p.productId}
                                onChange={e => updateProducts(index, 'productId', e.target.value)}
                            />
                        </FormGroup>
                        {taskConfig.products.length > 1 &&
                            <IconButton style={{margin: '0 0 1.5rem 0', padding: '0 .75rem', justifySelf: 'flex-end'}}
                                        onClick={() => removeProduct(index)}>
                                <RemoveIcon/>
                            </IconButton>
                        }
                    </div>
                )}
                <Button startIcon={<Add/>}
                        onClick={addProduct}
                        variant="outlined">Weiters Produkt hinzufügen</Button>
            </div>
        </div>
    );
};

export default Buy;
