import React, {useState} from "react";
import TireSelection from "./TireSelection";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {updateTaskConfig} from "../../../../helpers/taskHelpers";
import Box from "@material-ui/core/Box";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const CleanTire = React.memo(function ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList}) {
    let defaultConfig = {
        frontLeft: false,
        frontRight: false,
        backLeft: false,
        backRight: false,
        cleanTireSummer: false,
        cleanTireWinter: false,
    }
    if(taskToUpdate.filter(t => t.task.id === task.id).length){
        defaultConfig = taskToUpdate.filter(t => t.task.id === task.id)[0].taskConfig;
    }
    const [taskConfig, setTaskConfig] = useState(taskToUpdate?.taskConfig ?? defaultConfig);
    // Set default values in main task
    return (
        <div className="row row-align-center">
            <div className="gr-4 gr-2@small task__config--checkbox">
                <Checkbox
                    checked={taskList.filter(t => t.id === task.id).length}
                    onChange={(e) => {
                        if(taskList.filter(t => t.id === task.id).length){
                            updateTaskConfig('allTyres', false, setTaskConfig, taskConfig, updateMainTask, task)
                        }
                        toggleTask(task, setTaskConfig, taskConfig, updateMainTask, 'allTyres', e.target.checked)

                    }}
                    name="allTyresClean"
                    color="default"
                />
            </div>
            <div className="task__config config__title task__config--round gr-8 gr-4@small suffix-1">
                {task.title}
            </div>
            <div className=" task__config gr-24 gr-16@small gr-17@large">
                <TireSelection
                    taskConfig={taskConfig}
                    setTaskConfig={setTaskConfig}
                    task={task}
                    updateMainTask={updateMainTask}
                />
                {taskList.filter(t => t.id === task.id).length === 1 &&
                   <div className="config__grid config__grid--2">
                       <FormControlLabel
                            control={
                                <Checkbox
                                checked={taskConfig.cleanTireSummer ?? false}
                                onChange={() => updateTaskConfig('cleanTireSummer', !taskConfig.cleanTireSummer, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="cleanTireSummer"
                                color="default"
                                />}
                        label="Sommer"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                checked={taskConfig.cleanTireWinter ?? false}
                                onChange={() => updateTaskConfig('cleanTireWinter', !taskConfig.cleanTireWinter, setTaskConfig, taskConfig, updateMainTask, task)}
                                name="cleanTireWinter"
                                color="default"
                                />}
                        label="Winter"
                        />
                   </div>

                }
            </div>

        </div>
    );
});

export default CleanTire;
