import {useState} from 'react';
import {updateTaskConfig} from '../../../../helpers/taskHelpers';

// Material UI imports
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
    input: {
        background: "#fff"
    }
});

const Lift = ({preferredLift, setPreferredLift, updateParent, index}) => {
    const number = index.toString();

    return (
        <div
            className={preferredLift === number ? "select__item select__item--mb select__item--selected" : "select__item select__item--mb"}
            onClick={() => {
                setPreferredLift(number);
                updateParent('preferredLift', number);
            }}>
            <div className="item__title">Lift {number}</div>
            <Radio checked={preferredLift === number} color="secondary"/>
        </div>
    )
}
const NewTire = ({task, removeFunction, updateMainTask, taskToUpdate, additionalToUpdate, updateParentAdditional}) => {
    let defaultConfig = {
        frontLeft: true,
        frontRight: true,
        backLeft: true,
        backRight: true,
        tireType: '',
        comment: '',
        changeTires: false,
        resetRDKS: false,
        resetEOBD: false,
        airSuspension: false,
        storeNumber: '',
        storageReady: false,
        rimSize: '',
        tiresFrontLeft: {
            size: '',
            name: '',
        },
        tiresFrontRight: {
            size: '',
            name: '',
        },
        tiresBackLeft: {
            size: '',
            name: '',
        },
        tiresBackRight: {
            size: '',
            name: '',
        }
    }

    if(taskToUpdate.filter(t => t.task.id === task.id).length){
        defaultConfig =taskToUpdate.filter(t => t.task.id === task.id)[0].taskConfig;
    }

    const [preferredLift, setPreferredLift] = useState(additionalToUpdate?.preferredLift ? additionalToUpdate.preferredLift.toString() : '');
    const [taskConfig, setTaskConfig] = useState(taskToUpdate?.taskConfig ?? defaultConfig);

    const lifts = Array.apply(null, Array(8));

    const classes = useStyles();

    return (
        <div className="">
            <div className="row">
                <div className="gr-24">
                    <FormGroup>
                    <RadioGroup className="task__config--tire">
                        <FormControlLabel checked={taskConfig.tireType === 'summerTires'}
                                          onChange={() => updateTaskConfig('tireType', 'summerTires', setTaskConfig, taskConfig, updateMainTask, task)}
                                          value="summerTires"
                                          control={<Radio/>}
                                          label="Sommerreifen"/>

                        <FormControlLabel value="winterTires"
                                          checked={taskConfig.tireType === 'winterTires'}
                                          onChange={() => updateTaskConfig('tireType', 'winterTires', setTaskConfig, taskConfig, updateMainTask, task)}
                                          control={<Radio/>}
                                          label="Winterreifen"/>

                        <FormControlLabel value="fullYearTires"
                                          checked={taskConfig.tireType === 'fullYearTires'}
                                          onChange={() => updateTaskConfig('tireType', 'fullYearTires', setTaskConfig, taskConfig, updateMainTask, task)}
                                          control={<Radio/>}
                                          label="Ganzjahresreifen"/>
                    </RadioGroup>
                    </FormGroup>
                </div>
            </div>
            <div className="row">
                <div className="gr-6">
                    <FormGroup>
                    <TextField
                        InputProps={{className: classes.input}}
                        label="Name"
                        inputProps={{pattern: '[a-zA-Z0-9]+'}}
                        variant="filled"
                        name="nameFrontLeft"
                        value={taskConfig.tiresFrontLeft.name}
                        onChange={e => updateTaskConfig('tiresFrontLeft', e.target.value, setTaskConfig, taskConfig, updateMainTask, task, 'name')}
                    /></FormGroup>
                </div>
                <div className="gr-6">
                    <FormGroup>
                    <TextField
                        InputProps={{className: classes.input}}
                        label="Name"
                        inputProps={{pattern: '[a-zA-Z0-9]+'}}
                        variant="filled"
                        name="nameFrontLeft"
                        value={taskConfig.tiresFrontRight.name}
                        onChange={e => updateTaskConfig('tiresFrontRight', e.target.value, setTaskConfig, taskConfig, updateMainTask, task, 'name')}
                    /></FormGroup>
                </div>
                <div className="gr-6">
                    <FormGroup>
                    <TextField
                        InputProps={{className: classes.input}}
                        label="Name"
                        inputProps={{pattern: '[a-zA-Z0-9]+'}}
                        variant="filled"
                        name="nameBackLeft"
                        value={taskConfig.tiresBackLeft.name}
                        onChange={e => updateTaskConfig('tiresBackLeft', e.target.value, setTaskConfig, taskConfig, updateMainTask, task, 'name')}
                    /></FormGroup>
                </div>
                <div className="gr-6">
                    <FormGroup>
                    <TextField
                        InputProps={{className: classes.input}}
                        label="Name"
                        inputProps={{pattern: '[a-zA-Z0-9]+'}}
                        variant="filled"
                        name="nameBackRight"
                        value={taskConfig.tiresBackRight.name}
                        onChange={e => updateTaskConfig('tiresBackRight', e.target.value, setTaskConfig, taskConfig, updateMainTask, task, 'name')}
                    /></FormGroup>
                </div>
            </div>
            <div className="row row-align-center row-justify-center tac">
                <div className="gr-4">
                    <Button variant="text" onClick={() => {
                        updateTaskConfig('tiresFrontRight', taskConfig.tiresFrontLeft.name, setTaskConfig, taskConfig, updateMainTask, task, 'name')
                    }}>auf Achse Kopieren</Button>
                </div>
                <div className="gr-8">
                    <Button variant="text" onClick={() => {
                        updateTaskConfig('all', taskConfig.tiresFrontLeft.name, setTaskConfig, taskConfig, updateMainTask, task, 'name')
                        }}>auf alle 4 Kopieren</Button>
                </div>
                <div className="gr-4">
                    <Button variant="text" onClick={() => {
                        updateTaskConfig('tiresBackRight', taskConfig.tiresBackLeft.name, setTaskConfig, taskConfig, updateMainTask, task, 'name')

                    }}>auf Achse Kopieren</Button>
                </div>
            </div>
            <div className="config__content">
                <div className="row row-align-center task__config--yellow">
                    <div className="gr-2 tac">
                        <div className="text__title">Zoll</div>
                    </div>
                    <div className="gr-4">
                        <div className="config__grid config__grid--2">
                            <TextField
                                InputProps={{className: classes.input}}
                                label="Felgen"
                                inputProps={{pattern: '[0-9-]+', maxLength: 2}}
                                variant="filled"
                                name="rimSize"
                                value={taskConfig.rimSize}
                                onChange={e => updateTaskConfig('rimSize', e.target.value, setTaskConfig, taskConfig, updateMainTask, task)}
                            />
                        </div>
                    </div>

                    <div className="gr-6">
                <FormControlLabel
                    label={`sind im Kofferraum`}
                    control={
                        <Radio
                            checked={(taskConfig?.storageOption === 'inBoot')}
                            onChange={() => {
                                updateTaskConfig('storageOption', 'inBoot', setTaskConfig, taskConfig, updateMainTask, task);
                            }}
                            name="inBoot"
                            color="default"
                        />
                    }
                />
                <FormControlLabel
                label={`sind Eingelagert`}
                control={
                    <Radio
                        checked={(taskConfig?.storageOption === 'inStorage')}
                        onChange={() => {
                            updateTaskConfig('storageOption', 'inStorage', setTaskConfig, taskConfig, updateMainTask, task);
                        }}
                        name="inBoot"
                        color="default"
                    />
                }
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={taskConfig.screws}
                            onChange={() => updateTaskConfig('screws', !taskConfig.screws, setTaskConfig, taskConfig, updateMainTask, task)}
                            name="screws"
                            color="default"
                        />
                    }
                    label="Andere Schrauben"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={taskConfig.resetEOBD}
                            onChange={() => updateTaskConfig('resetEOBD', !taskConfig.resetEOBD, setTaskConfig, taskConfig, updateMainTask, task)}
                            name="resetEOBD"
                            color="default"
                        />
                    }
                    label="OBD Reset"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={taskConfig.airSuspension}
                            onChange={() => updateTaskConfig('airSuspension', !taskConfig.airSuspension, setTaskConfig, taskConfig, updateMainTask, task)}
                            name="airSuspension"
                            color="default"
                        />
                    }
                    label="Luftfedern Sperren"
                /></div>
                    <div className="gr-2 tac">
                        <div className="text__title">Lift</div>
                    </div>
                    <div className="gr-10">
                        <div className="custom-radio custom-radio--4">
                            {lifts.map((value, key) => {
                                return <Lift key={key} preferredLift={preferredLift} setPreferredLift={setPreferredLift}
                                             updateParent={updateParentAdditional} index={key + 1}/>
                            })}

                            <div
                                className={preferredLift === '' ? "select__item select__item--mb select__item--selected" : "select__item select__item--mb"}
                                onClick={() => {
                                    setPreferredLift('');
                                    updateParentAdditional('preferredLift', '');
                                    updateParentAdditional('preferredLift', '');
                                }}>
                                <div className="item__title">Keiner</div>
                                <Radio checked={preferredLift === ''} color="secondary"/>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewTire;
