// Quick storage ready btn for warehouse task
import {useContext, useEffect, useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {updateTask} from "../../actions/taskActions";
import {isWarehouseTask} from "../../helpers/helpers";
import {useDispatch, useSelector} from "react-redux";
import {WebSocketContext} from "../../context/Websocket";

const StorageQuickBtn = ({task, setFeedback, taskId = null}) => {
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);

    // Update task list via websockets
    const {updateWsTaskList} = useContext(WebSocketContext);

    const {error} = useSelector(state => ({
        error: state.error,
    }));

    useEffect(() => {
        if (task) {
            const foundTask = task.tasks.filter(t => isWarehouseTask(t) && t.task.id === taskId)[0];


            if (foundTask) {
                setChecked(foundTask?.taskConfig?.storageReady);
            }
        }
    }, [task, taskId]);

    useEffect(() => {
        if (error) {
            setFeedback({
                error: true,
                text: error.msg,
            });
        }
    }, [error, setFeedback]);


    if (!task) {
        return <></>;
    }


    const handleChange = async e => {

        // Get the warehouse taskConfig
        const warehouseTask = task.tasks.filter(t => isWarehouseTask(t) && t.task.id === taskId)[0];
        if (!warehouseTask) return;

        warehouseTask.taskConfig = {
            ...warehouseTask.taskConfig,
            storageReady: e.target.checked,
        }

        const taskList = [
            ...task.tasks.filter(t => t.task.id !== warehouseTask.task.id),
            warehouseTask,
        ]

        const taskToSave = {
            ...task,
            tasks: taskList,
        }

        await dispatch(updateTask(taskToSave));
        setChecked(warehouseTask.taskConfig.storageReady);
        updateWsTaskList();
    }

    return (
        <FormControlLabel
            control={<Checkbox
                checked={checked}
                disabled={task?.state?.stateTitle === 'abgeschlossen'}
                onChange={e => handleChange(e)}/>}
            label={'bereit'}/>
    );
}

export default StorageQuickBtn;