import {CLEAR_SUCCESS, SET_SUCCESS} from "./types";
import {setNewTokenInStorage} from "../helpers/authHelper";

export const setSuccess = res => {

    setNewTokenInStorage(res?.headers);

    return {
        type: SET_SUCCESS,
        payload: {
            msg: res?.data?.message,
        },
    }
}

export const clearSuccess = () => {
    return {
        type: CLEAR_SUCCESS,
    }
}
