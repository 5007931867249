const OBDSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.28 595.28">
            <path
                d="M176.55 367.73v78.54c0 54.4 41.89 98.66 93.38 98.66h56.93c51.49 0 93.38-44.26 93.38-98.66v-78.54l29.82-63.78c1.08-2.32 1.65-4.85 1.65-7.41V149c0-54.4-41.89-98.66-93.38-98.66H238.47c-51.49 0-93.38 44.26-93.38 98.66v147.54c0 2.56.56 5.09 1.65 7.41l29.82 63.78ZM180.08 149c0-35.1 26.19-63.66 58.38-63.66h119.86c32.19 0 58.38 28.56 58.38 63.66v143.65l-29.82 63.78c-1.08 2.32-1.65 4.85-1.65 7.41v82.43c0 35.1-26.19 63.66-58.38 63.66h-56.93c-32.19 0-58.38-28.56-58.38-63.66v-82.43c0-2.56-.56-5.09-1.65-7.41l-29.82-63.78V149Z"/>
            <path
                d="M247.42 293.55h100.93c18.99 0 34.43-15.45 34.43-34.43V152.15c0-18.99-15.45-34.43-34.43-34.43H247.42c-18.98 0-34.43 15.45-34.43 34.43v106.97c0 18.99 15.45 34.43 34.43 34.43Zm.57-140.83h99.79v105.83h-99.79V152.72Zm67.9 213.7h-35v35.3H245.6v35h35.29v35.29h35v-35.29h35.3v-35h-35.3v-35.3z"
                className="cls-1"/>
        </svg>    );
};

export default OBDSVG;