const InlineLoading = ()  => {
    return (
        <div className="loading__wrapper loading__wrapper--inline">
            <div className="loading__inner">
                <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                </div>
            </div>
        </div>
    )
}

export default InlineLoading;