import {
    CLEAR_ERRORS,
    CLEAR_LOGIN_LOADING,
    LOGIN_ERROR,
    LOGIN_LOADING,
    LOGIN_SUCCESS, LOGOUT_ERROR, LOGOUT_SUCCESS,
} from "./types";
import {axiosInstance as axios} from '../helpers/axios';
import {setErrors} from "./errorActions";
import {setNewTokenInStorage} from "../helpers/authHelper";
import {setSuccess} from "./successActions";

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const loginUser = (user) => async dispatch => {
    try {
        dispatch({type: CLEAR_ERRORS});

        dispatch({type: LOGIN_LOADING});

        const res = await axios.post('/api/v1/auth/login', user);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err, LOGIN_ERROR));
    } finally {
        dispatch({type: CLEAR_LOGIN_LOADING});
    }
}

export const logoutUser = () => async dispatch => {
    try {
        dispatch({type: CLEAR_ERRORS});

        localStorage.removeItem('token');
        const res = await axios.delete('/api/v1/auth');

        dispatch({
            type: LOGOUT_SUCCESS,
            payload: res.data.data,
        });


        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, LOGOUT_ERROR));
    } finally {
        dispatch({type: CLEAR_LOGIN_LOADING});
    }
}

export const checkRefreshToken = async (dispatch) => {
    try {
        dispatch({type: CLEAR_ERRORS});

        dispatch({type: LOGIN_LOADING});

        const res = await axios.get('/api/v1/auth/login', tokenConfig());

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data.data,
        });

    } catch (err) {
        setNewTokenInStorage(err.response?.headers);
    } finally {
        dispatch({type: CLEAR_LOGIN_LOADING});
    }
}

// Setup config / headers and token
export const tokenConfig = () => {

    // Get token from localstorage
    const token = localStorage.getItem('token');

    // Set headers
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    }

    // If a token exists, add it to headers
    if (token) {
        config.headers['X-Auth-Token'] = token;
    }

    return config;
};