import React from 'react';
import Header from "../../includes/Header";
import Button from "@material-ui/core/Button";

const NoAdmin = () => {
    return (
        <>
            <Header/>
            <div className="container">
                <div className="row">
                    <div className="gr-24">
                        <div className="page__title">Sie haben keine Berechtigung zum Anzeigen dieser Seite</div>
                        <Button variant="contained" color="primary" href="/">Zurück zur Startseite</Button>
                    </div>
                </div>

            </div>
        </>
    );
};

export default NoAdmin;
