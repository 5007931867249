import {useEffect, useRef, useState} from "react";
import {updateTaskConfig} from "../../../../helpers/taskHelpers";
import Box from "@material-ui/core/Box";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StoreTireSelect from "./StoreTireSelect";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    input: {
        background: "#fff"
    }
});

const StoreTires = ({task, removeFunction, toggleTask, updateMainTask, taskToUpdate, taskList}) => {
    const [tiresToStore, setTiresToStore] = useState( taskToUpdate.filter(t => t.task.id === task.id)[0]?.taskConfig?.tiresToStore ?? [{
        _id: new Date().valueOf(),
        tirePosition: '',
        brand: '',
        profile: '',
        dot: '',
    }]);

    let defaultConfig = {
        tireType: '',
        wheelType: '',
        storageReady: false,
        montageKitInCar: false,
        offerWished: false,
        tiresToStore,
    }

    const classes = useStyles();
    if(taskToUpdate.filter(t => t.task.id === task.id).length){
        defaultConfig = taskToUpdate.filter(t => t.task.id === task.id)[0].taskConfig;
    }
    // Task config state obj
    const [taskConfig, setTaskConfig] = useState(taskToUpdate?.taskConfig ?? defaultConfig);

    // Helper array to check, which tire positions are disabled or not
    const [selectedPositions, setSelectedPositions] = useState([])

    const updateOnLoad = useRef(() => {
        if (taskToUpdate) {
            // Set selected dropdown positions
            if(taskToUpdate.filter(t => t.task.id === task.id).length){
                const selected = taskToUpdate.filter(t => t.task.id === task.id)[0].taskConfig.tiresToStore.map(tire => tire.tirePosition)
                if(selected){
                    setSelectedPositions(selected);
                }
            }
        }
    });

    // Add a new config element to task
    const addTireSelect = () => {
        if (tiresToStore?.length >= 4) return;

        setTiresToStore([
            ...tiresToStore,
            {
                _id: new Date().valueOf(),
                tirePosition: '',
                brand: '',
                profile: '',
                dot: '',
            }
        ]);
    }

    const updateSelectedFromConfig = config => {
        const selected = config.map(tire => tire.tirePosition)
        setSelectedPositions(selected);
    }

    // Remove config from tires to store
    const removeTireSelect = index => {
        if (tiresToStore?.length <= 1) return;
        // Update local state
        const newConfig = [...tiresToStore.filter(config => config._id !== index)];
        setTiresToStore(newConfig);

        // Update selected positions
        updateSelectedFromConfig(newConfig);

        // Update parent
        updateTaskConfig('tiresToStore', newConfig, setTaskConfig, taskConfig, updateMainTask, task);
    }

    // Update main task with settings from children components
    const updateTiresToStore = config => {
        // Update local state
        const newConfig = [...tiresToStore.filter(c => c._id !== config._id), config];
        setTiresToStore(newConfig);

        // Update selected positions
        updateSelectedFromConfig(newConfig);

        // Update parent
        updateTaskConfig('tiresToStore', newConfig, setTaskConfig, taskConfig, updateMainTask, task);
    }

    useEffect(() => {
        updateOnLoad.current();
    }, []);

    return (
        <div className="row row-align-center">
            <div className="gr-4 gr-2@small task__config--checkbox">
                <Checkbox
                    checked={taskList.filter(t => t.id === task.id).length}
                    onChange={(e) => {
                        toggleTask(task,setTaskConfig, taskConfig, updateMainTask,'', e.target.checked)}}
                    name="allTyres"
                    color="default"
                />
            </div>
            <div className="task__config config__title task__config--round gr-8 gr-4@small suffix-1">
                {task.title}
            </div>
            <div className="gr-24 gr-16@small gr-17@large task__config">
            {taskList.filter(t => t.id === task.id).length === 1 &&
            <div className="config__content">
                {tiresToStore?.sort((a, b) => a._id - b._id)?.map((config) => {
                    return <StoreTireSelect key={config._id}
                                            configToUpdate={config}
                                            updateParent={updateTiresToStore}
                                            removeTireSelect={() => removeTireSelect(config._id)}
                                            selectedPositions={selectedPositions}
                                            configLength={tiresToStore.length}
                    />
                })}

                <Button variant="outlined"
                        onClick={addTireSelect}
                        disabled={tiresToStore?.length >= 4}
                        startIcon={<Add/>}>Rad / Reifen hinzufügen</Button>

                    <div className="">
                        <RadioGroup>
                            <FormControlLabel checked={taskConfig.tireType === 'summerTires'}
                                              onChange={() => updateTaskConfig('tireType', 'summerTires', setTaskConfig, taskConfig, updateMainTask, task)}
                                              value="summerTires"
                                              control={<Radio/>}
                                              label="Sommer"/>

                            <FormControlLabel value="winterTires"
                                              checked={taskConfig.tireType === 'winterTires'}
                                              onChange={() => updateTaskConfig('tireType', 'winterTires', setTaskConfig, taskConfig, updateMainTask, task)}
                                              control={<Radio/>}
                                              label="Winter"/>
                        </RadioGroup>
                        <RadioGroup>
                            <FormControlLabel checked={taskConfig.wheelType === 'aluminium'}
                                              onChange={() => updateTaskConfig('wheelType', 'aluminium', setTaskConfig, taskConfig, updateMainTask, task)}
                                              value="aluminium"
                                              control={<Radio/>}
                                              label="Rad-Aluminium"/>

                            <FormControlLabel value="steel"
                                              checked={taskConfig.wheelType === 'steel'}
                                              onChange={() => updateTaskConfig('wheelType', 'steel', setTaskConfig, taskConfig, updateMainTask, task)}
                                              control={<Radio/>}
                                              label="Rad Stahl"/>

                            <FormControlLabel value="tires"
                                              checked={taskConfig.wheelType === 'tires'}
                                              onChange={() => updateTaskConfig('wheelType', 'tires', setTaskConfig, taskConfig, updateMainTask, task)}
                                              control={<Radio/>}
                                              label="Reifen"/>
                        </RadioGroup>
                    </div>

                    <div className="config__grid config__grid--center config__grid--2">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={taskConfig.montageKitInCar ?? false}
                                    onChange={() => updateTaskConfig('montageKitInCar', !taskConfig.montageKitInCar, setTaskConfig, taskConfig, updateMainTask, task)}
                                    name="montageKitInCar"
                                    color="default"
                                />
                            }
                            label="Befestigungskit im Auto"
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={taskConfig.offerWished ?? false}
                                    onChange={() => updateTaskConfig('offerWished', !taskConfig.offerWished, setTaskConfig, taskConfig, updateMainTask, task)}
                                    name="offerWished"
                                    color="default"
                                />
                            }
                            label="Offerte gewünscht"
                        />
                    </div>

                <div className="page__subtitle">Lager</div>
                <div className="config__grid config__grid--center config__grid--2">
                    <FormGroup>
                        <TextField
                            InputProps={{className: classes.input}}
                            label="Einlagerungsnummer / Ort"
                            variant="filled"
                            name="storeNumber"
                            value={taskConfig.storeNumber ?? ''}
                            onChange={e => updateTaskConfig('storeNumber', e.target.value, setTaskConfig, taskConfig, updateMainTask, task)}
                        />
                    </FormGroup>
                    <Box mb={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={taskConfig.storageReady ?? false}
                                    onChange={() => updateTaskConfig('storageReady', !taskConfig.storageReady, setTaskConfig, taskConfig, updateMainTask, task)}
                                    name="storageReady"
                                    color="default"
                                />
                            }
                            label="Bereit"
                        />
                    </Box>
                </div>
            </div>}
            </div>
        </div>
    );
};

export default StoreTires;
