const GanzSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.28 595.28">
            <path d="m372.64 82.51-22.5 22.5v49.49l47.25-47.24-24.75-24.75z"/>
            <path
                d="m350.14 490.27 22.5 22.5 24.75-24.75-47.25-47.25v49.5zm181.79-167.9-85.03 22.79-82.31-47.52 82.3-47.52 85.04 22.78 9.06-33.81-51.23-13.72 47.85-27.63-17.5-30.31-47.85 27.62 13.73-51.22-33.81-9.06-22.78 85.03-79.26 45.76v64.15l79.25 45.76 22.79 85.03 33.81-9.06-13.73-51.23 47.85 27.63 17.5-30.31-47.85-27.62 51.23-13.73-9.06-33.81zm-402.91-24.73c0-5.92.35-11.76 1.03-17.5H47.54v35h82.51c-.68-5.74-1.03-11.58-1.03-17.5Zm31.93 91.94-58.39 58.39 24.75 24.75 58.39-58.39c-9.19-7.25-17.5-15.56-24.75-24.75Zm24.75-208.63-58.39-58.39-24.75 24.75 58.39 58.39c7.25-9.19 15.56-17.5 24.75-24.75Zm-21.68 116.69c0 51.36 34.25 94.87 81.12 108.87v-37.29c-27.18-12.38-46.12-39.81-46.12-71.58s18.94-59.2 46.12-71.58v-37.29c-46.87 14-81.12 57.51-81.12 108.87Zm116.12-113.62v343.72h35V67.54h-35v116.48z"
                className="cls-1"/>
        </svg>
           );
};

export default GanzSVG;