import React, {useEffect, useState, useRef} from "react";
import Button from "@material-ui/core/Button";
import Add from "@material-ui/icons/Add";
import TaskHandler from "./TaskHandler";
import Comment from "./config/Comment";
import {updateTaskConfig} from "../../../helpers/taskHelpers";

const Tasks = React.memo(function({hide, setNextBtnDisabled, updateParent, taskListFromParent, comment, updateParentComment, additionalToUpdate, updateParentAdditional}) {

    /**
    /**
     * All Task with config are stored in the main task. As soon as the main task gets updated,
     * the overall task in ConfigureTask gets updated too.
     */
    const [mainTask, setMainTask] = useState({
        tasks: [],
    });

    const [taskList, setTaskList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [enableComment, setEnableComment] = useState(false);


    const setTaskListToUpdate = useRef((list) => {
        const newTaskList = [];
        const mainTaskItems = [];

        list.forEach(item => {
            const task = item.task;
            newTaskList.push(task);
            mainTaskItems.push(item);
        });

        setTaskList([
            ...taskList,
            ...newTaskList,
        ]);

        setMainTask({
            ...mainTask,
            tasks: [
                ...mainTask.tasks,
                ...mainTaskItems,
            ]
        });
    });

    useEffect(() => {
        if (comment) {
            setEnableComment(true);
        }
    }, [comment]);

    useEffect(() => {
        // Check if a task gets updated
        if (taskListFromParent?.length) {
            setTaskListToUpdate.current(taskListFromParent);
        }
    }, [taskListFromParent]);

    const availableTasks = [
        {
            id: 3,
            title: 'Neue Pneu',
            category: 'newTires',
        },
        {
            id: 1,
            title: 'Pneuwechsel',
            textInBoot: 'Pneu sind im Kofferraum',
            category: 'changeTire',
        },
        {
            id: 2,
            title: 'Radwechsel',
        },
        {
            id: 4,
            title: 'Auswuchten',
            category: 'balance'
        },
        {
            id: 10,
            title: 'Entsorgen',
            category: 'dumpTires',
        },
        {
            id: 11,
            title: 'Luftverlust',
        },
        {
            id: 5,
            title: 'Einlagern',
            category: 'storeTires',
        },
        {
            id: 7,
            title: 'Reparatur',
            category: 'repair',
        },
        {
            id: 12,
            title: 'Radwäsche',
            category: 'cleanTire',
        },
        {
            id: 6,
            title: 'Lenkgeometrie',
        },

        {
            id: 8,
            title: 'Reinigung',
            category: 'cleaning',
        },
        {
            id: 9,
            title: 'Kauf Zubehör',
            category: 'buy',
        },
    ];


    const addTask = (task) => {
        setAnchorEl(null);
        if (taskList.filter(t => t.id === task.id).length) return;

        const newTask = {
            createdAt: new Date().valueOf(),
            ...task
        };

        setTaskList([
            ...taskList,
            newTask,
        ]);

    }
    const removeTask = task => {
        const newTaskList = taskList.filter(t => t.id !== task.id);
        setTaskList(newTaskList);

        let newMainTask;

        if (newTaskList.length > 0) {
            newMainTask = {
                ...mainTask,
                tasks: [...mainTask.tasks.filter(t => t.task.id !== task.id)],
            }
        } else {
            newMainTask = {
                ...mainTask,
                tasks: []
            }
        }
        updateParent(newMainTask);
        setMainTask(newMainTask);
    }
    const toggleTask = (task, setTaskConfig, taskConfig, updateMainTask, index, value) =>{
        if(taskList.filter(t => t.id === task.id).length){
            removeTask(task)
        }else{
            addTask(task)
            updateTaskConfig(index, value, setTaskConfig, taskConfig, updateMainTask, task)
        }
    }

    const removeComment = () => {
        setEnableComment(false);
        updateParentComment('');
    }

    // This function is passed down to the children to update the main task
    const updateMainTask = (newConfig, newTask) => {
        setMainTask({
            ...mainTask,
            tasks: [
                ...mainTask.tasks.filter(t => t?.task?.id !== newTask.id),
                {
                    task: newTask,
                    taskConfig: newConfig,
                }
            ]
        });
        const configForParent = {
            ...mainTask,
            tasks: [
                ...mainTask.tasks.filter(t => t?.task?.id !== newTask.id),
                {
                    task: newTask,
                    taskConfig: newConfig,
                }
            ]
        }
        updateParent(configForParent);
    }


    useEffect(() => {
        if (hide) return;
        setNextBtnDisabled(true);
        if (taskList.length > 0) {
            setNextBtnDisabled(false);
        }
    }, [setNextBtnDisabled, hide, taskList]);

    return (
        <div className={hide ? "container container--mb hide" : "container container--mb"}>
            <div className="row row-justify-center">
                <div className="gr-24">
                    <div className="page__title">Aufgaben</div>
                </div>

                <div className="gr-24">
                    {availableTasks.sort((a, b) => {
                        return a.createdAt - b.createdAt;
                    }).map(task => <TaskHandler
                        key={task.id}
                        task={task}
                        removeFunction={removeTask}
                        updateMainTask={updateMainTask}
                        toggleTask={toggleTask}
                        taskToUpdate={taskListFromParent}
                        taskList={taskList}
                        additionalToUpdate={additionalToUpdate}
                        updateParentAdditional={updateParentAdditional}
                    />)}
                </div>

                {enableComment &&
                    <div className="gr-24 gr-18@small">
                        <Comment removeFunction={removeComment}
                                 title={'Bemerkung'}
                                 updateMainTask={updateParentComment}
                                 commentToUpdate={comment}
                        />
                    </div>}

                <div className="gr-24 gr-18@small">
                    <Button variant="contained"
                            color="primary"
                            onClick={e => setAnchorEl(e.currentTarget)}
                            style={{marginRight: '1rem'}}
                            startIcon={<Add/>}>
                        Neue Aufgabe
                    </Button>


                    <Button variant="outlined"
                            onClick={() => setEnableComment(true)}
                            disabled={enableComment}
                            startIcon={<Add/>}>Bemerkung hinzufügen</Button>
                </div>
            </div>
        </div>
    )
});

export default Tasks;