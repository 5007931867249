import {axiosInstance as axios} from '../helpers/axios';
import {CLEAR_BRANCH_LOADING, BRANCH_ERROR, BRANCH_LOADING, GET_BRANCHES} from "./types";
import {setErrors} from "./errorActions";
import {tokenConfig} from "./authActions";
import {setSuccess} from "./successActions";

export const getAllBranches = () => async (dispatch) => {
    try {
        dispatch({type: BRANCH_LOADING});

        const res = await axios.get('/api/v1/branch', tokenConfig());

        dispatch({
            type: GET_BRANCHES,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err, BRANCH_ERROR));
    } finally {
        dispatch({type: CLEAR_BRANCH_LOADING});
    }
}

export const createBranch = branch => async (dispatch) => {
    try {
        dispatch({type: BRANCH_LOADING});

        const res = await axios.post('/api/v1/branch', branch, tokenConfig());


        dispatch({
            type: GET_BRANCHES,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, BRANCH_ERROR));
    } finally {
        dispatch({type: CLEAR_BRANCH_LOADING});
    }
}

export const updateBranch = branch => async (dispatch) => {
    try {
        dispatch({type: BRANCH_LOADING});

        const res = await axios.put('/api/v1/branch', branch, tokenConfig());

        dispatch({
            type: GET_BRANCHES,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, BRANCH_ERROR));
    } finally {
        dispatch({type: CLEAR_BRANCH_LOADING});
    }
}

export const deleteBranch = branch => async (dispatch) => {
    try {
        dispatch({type: BRANCH_LOADING});

        const config = {
            ...tokenConfig(),
            data: branch,
        }

        const res = await axios.delete('/api/v1/branch', config);

        dispatch({
            type: GET_BRANCHES,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, BRANCH_ERROR));
    } finally {
        dispatch({type: CLEAR_BRANCH_LOADING});
    }
}
