import {Redirect, Route} from 'react-router-dom';
import {isAuthenticated} from "../helpers/authHelper";

const GuardedRoute = ({comp: Component, ...rest}) => {

    return (<Route {...rest}
                   render={(props) => (
                       isAuthenticated()
                       ? <Component {...props} {...rest}  />
                       : <Redirect to={{pathname: '/auth', state: {from: props.location}}}/>)}/>)
};

export default GuardedRoute;