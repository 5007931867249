import {axiosInstance as axios} from '../helpers/axios';
import {
    CUSTOMER_ERROR,
    CUSTOMER_LOADING,
    CLEAR_CUSTOMER_LOADING,
    SET_CUSTOMER,
    GET_ALL_CUSTOMER, GET_CUSTOMER
} from "./types";
import {setErrors} from "./errorActions";
import {tokenConfig} from "./authActions";
import {clearLoading, setLoading} from "./loadingActions";
import {setSuccess} from "./successActions";

export const getCustomer = (params) => async dispatch => {
    try {
        dispatch({type: CUSTOMER_LOADING});

        const res = await axios.get('/api/v1/customer', {...tokenConfig(), params});

        dispatch({
            type: GET_ALL_CUSTOMER,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err), CUSTOMER_ERROR);
    } finally {
        dispatch({type: CLEAR_CUSTOMER_LOADING})
    }
}

export const findCustomer = () => async dispatch => {
    try {
        dispatch({type: CUSTOMER_LOADING});

        const res = await axios.post('/api/v1/customer', {}, tokenConfig());

        dispatch({
            type: GET_ALL_CUSTOMER,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err), CUSTOMER_ERROR);
    } finally {
        dispatch({type: CLEAR_CUSTOMER_LOADING})
    }
}

export const getCustomerById = (id) => async dispatch => {
    try {
        dispatch(setLoading());

        const res = await axios.get(`/api/v1/customer/${id}`, tokenConfig());

        dispatch({
            type: GET_CUSTOMER,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err), CUSTOMER_ERROR);
    } finally {
        dispatch(clearLoading());
    }
}

export const updateCustomer = (customer) => async dispatch => {
    try {
        dispatch({
            type: CUSTOMER_LOADING,
        });
        const res = await axios.put('/api/v1/customer', customer, tokenConfig());

        dispatch({
            type: GET_CUSTOMER,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, CUSTOMER_ERROR));

    } finally {
        dispatch({
            type: CLEAR_CUSTOMER_LOADING,
        });
    }
}

export const clearCustomersInStore = () => dispatch => {
    try {
        dispatch({
            type: SET_CUSTOMER,
            payload: null,
        });
        dispatch({
            type: GET_ALL_CUSTOMER,
            payload: [],
        });
    } catch (err) {
        dispatch(setErrors(err, CUSTOMER_ERROR))
    }
}