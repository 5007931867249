import {
    GET_STATES,
    STATES_ERROR,
    STATES_LOADING,
    CLEAR_STATES_LOADING,
} from "../actions/types";

const initialState = {
    allStates: [],
    statesLoading: false,
    taskStateLoading: false,
};

const stateReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATES:
            return {
                ...state,
                allStates: action.payload,
            }

        case STATES_ERROR:
            return {
                ...state,
                allStates: [],
            }
        case STATES_LOADING:
            return {
                ...state,
                statesLoading: true,
            }
        case CLEAR_STATES_LOADING:
            return {
                ...state,
                statesLoading: false,
            }

        default:
            return {
                ...state,
            }
    }
}

export default stateReducer;