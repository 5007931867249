    import {useEffect, useState, useRef} from 'react';
    import {useDispatch, useSelector} from 'react-redux';
    import {getCustomer} from '../../../actions/customerActions';
    import Loading from '../../includes/Loading';
    import {getLastKilometreReading, formatDateWithMonth} from "../../../helpers/helpers";

    // Material UI imports
    import FormControlLabel from '@material-ui/core/FormControlLabel';
    import Radio from '@material-ui/core/Radio';
    import Alert from '@material-ui/lab/Alert';
    import AlertTitle from '@material-ui/lab/AlertTitle';
    import CreateCustomer from './CreateCustomer';


    const Customer = ({
                          hide,
                          setNextBtnDisabled,
                          setNewCustomer,
                          updateParent,
                          setCustomer,
                          customerToUpdate,
                          taskGetsUpdated,
                          carToSearch,
                      }) => {
        const dispatch = useDispatch();


        const {allCustomer, customerLoading} = useSelector(state => ({
            customerLoading: state.customer.customerLoading,
            allCustomer: state.customer.allCustomer,
        }));

        const [showCreateCustomer, setShowCreateCustomer] = useState(false);
        const [selectedRadio, setSelectedRadio] = useState('');

        const searchCustomer = useRef((params) => {
            if (customerLoading) return;
            dispatch(getCustomer(params))
        });

        useEffect(() => {
            if (hide) return;
                setNextBtnDisabled(true);

            if (selectedRadio) {
                setNextBtnDisabled(false);

            }

            if(taskGetsUpdated){
                setNextBtnDisabled(false);
            }

        }, [hide, setNextBtnDisabled, selectedRadio, customerToUpdate, taskGetsUpdated]);

        useEffect(() => {
            if (hide) return;

            // Check if a customers should be searched by controlSign and canton
            if ((!carToSearch?.controlSign && !carToSearch?.canton && !taskGetsUpdated) || showCreateCustomer) return;
            searchCustomer.current(carToSearch);
        }, [hide, carToSearch, taskGetsUpdated, showCreateCustomer]);


        // Create new / update customer form
        if (showCreateCustomer || (taskGetsUpdated && customerToUpdate)) {
            return (<CreateCustomer hide={hide}
                                    setNextBtnDisabled={setNextBtnDisabled}
                                    customerToUpdate={customerToUpdate}
                                    updateParent={updateParent}
                                    taskGetsUpdated={taskGetsUpdated}
                                    cancelNewCustomer={() => {
                                        setCustomer(null);
                                        setShowCreateCustomer(false);
                                    }}
                />
            )
        }


        return (
            <div className={hide ? "container container--mb hide" : "container container--mb"}>
                <div className="row row-justify-center">
                    <div className="gr-24 gr-18@small">
                        <div className="page__title">Kunde</div>
                    </div>

                    {customerLoading ? <Loading/> : <>
                        {allCustomer?.length > 0 && <div className="gr-24 gr-18@small">
                            <Alert variant="filled" severity="success" icon={false}>
                                <div className="custom-alert">
                                    <AlertTitle>Die eingegebene Autonummer wurde bereits einmal verwendet.</AlertTitle>
                                    Wählen Sie einen vorgeschlagenen Kunden aus oder erfassen Sie einen Neuen.
                                </div>
                            </Alert>
                        </div>}


                        <div className="gr-24 gr-18@small">
                            <div className="page__subtitle page__subtitle--mt"></div>
                        </div>

                        <div className="gr-24 gr-18@small">
                            <div style={{display: 'grid'}}>
                                {allCustomer?.map(customer => <FormControlLabel
                                    key={customer._id}
                                    style={{alignItems: customer?.kilometreReading && customer?.kilometreReading.length > 0 ? 'flex-start' : 'center'}}
                                    onClick={() => {
                                        setSelectedRadio(customer._id);
                                        setCustomer(customer);
                                    }}
                                    checked={selectedRadio === customer?._id}
                                    label={
                                        (<div className='custom-radio__customer'>
                                            <span>{`${customer.firstname} ${customer.surname}${customer.place ? ', ' + customer.place : ''}`}</span>
                                            <span className='custom-radio__customer--gray'>
                                            {customer?.canton} {customer?.controlSign}
                                            </span>
                                            {customer?.kilometreReading && customer?.kilometreReading?.length > 0 &&
                                                <span className='custom-radio__customer--gray'>
                                                    Letzter Kilometerstand am {formatDateWithMonth(getLastKilometreReading(customer?.kilometreReading)?.date)}:<br/>
                                                    {getLastKilometreReading(customer?.kilometreReading)?.reading} km
                                                </span>
                                            }
                                        </div>)
                                    }
                                    control={
                                        <Radio
                                            checked={selectedRadio === customer._id}
                                            onChange={() => {
                                                setSelectedRadio(customer._id);
                                            }}/>
                                    }
                                />)}
                                <FormControlLabel
                                    value="empty"
                                    label="Kunde (vorerst) leer lassen"
                                    control={
                                        <Radio
                                            checked={selectedRadio === 'empty'}
                                            onChange={() => {
                                                setCustomer(null);
                                                setSelectedRadio('empty');
                                            }}/>
                                    }
                                />
                                <FormControlLabel
                                    value="new"
                                    label="Neuer Kunde"
                                    control={
                                        <Radio
                                            checked={showCreateCustomer}
                                            onChange={() => {
                                                setSelectedRadio('new');
                                                setShowCreateCustomer(true);
                                            }}/>
                                    }
                                />
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        )
    }

    export default Customer;