import {axiosInstance as axios} from '../helpers/axios';
import {setErrors} from "./errorActions";
import {tokenConfig} from "./authActions";
import {setSuccess} from "./successActions";
import {
    CLEAR_USER_GROUPS_LOADING,
    GET_USER_GROUPS,
    GET_USER_GROUPS_PRIVILEGES,
    USER_GROUPS_ERROR,
    USER_GROUPS_LOADING
} from "./types";

export const getAllUserGroups = () => async (dispatch) => {
    try {
        dispatch({type: USER_GROUPS_LOADING});

        // Get the group and the privileges
        const res = await axios.get('/api/v1/userGroup', tokenConfig());

        dispatch({
            type: GET_USER_GROUPS,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err, USER_GROUPS_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_GROUPS_LOADING});
    }
}

export const getAllUserGroupsPrivileges = () => async (dispatch) => {
    try {
        dispatch({type: USER_GROUPS_LOADING});

        const res = await axios.get('/api/v1/userGroup/privileges', tokenConfig());

        dispatch({
            type: GET_USER_GROUPS_PRIVILEGES,
            payload: res.data.data,
        });

    } catch (err) {
        dispatch(setErrors(err, USER_GROUPS_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_GROUPS_LOADING});
    }
}

export const createUserGroup = userGroup => async dispatch => {
    try {
        dispatch({type: USER_GROUPS_LOADING});

        const res = await axios.post('/api/v1/userGroup', userGroup, tokenConfig());

        dispatch({
            type: GET_USER_GROUPS,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, USER_GROUPS_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_GROUPS_LOADING});
    }
}

export const updateUserGroup = userGroup => async dispatch => {
    try {
        dispatch({type: USER_GROUPS_LOADING});

        const res = await axios.put('/api/v1/userGroup', userGroup, tokenConfig());

        dispatch({
            type: GET_USER_GROUPS,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));
    } catch (err) {
        dispatch(setErrors(err, USER_GROUPS_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_GROUPS_LOADING});
    }
}

export const deleteUserGroup = userGroup => async dispatch => {
    try {
        dispatch({type: USER_GROUPS_LOADING});

        const config = {
            ...tokenConfig(),
            data: userGroup,
        }

        const res = await axios.delete('/api/v1/userGroup/', config);

        dispatch({
            type: GET_USER_GROUPS,
            payload: res.data.data,
        });

        dispatch(setSuccess(res));
    } catch (err) {
        dispatch(setErrors(err, USER_GROUPS_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_GROUPS_LOADING});
    }
}