import Button from "@material-ui/core/Button";
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const LoadingButton = ({
                           text = 'submit',
                           onClick = () => {
                               console.error("No Click function on LoadingButton")
                           },
                           disabled = false,
                           loading = false,
                           variant = 'contained',
                           color = 'default',
                           form = '',
                           type = "default",
                           size = 'large'
                       }) => {
    const classes = useStyles();
    return (

        <div style={{display: "block"}}>
            <div style={{position: "relative", display: "inline-block"}}>
                <Button
                    variant={variant}
                    color={color}
                    disabled={disabled || loading}
                    onClick={onClick}
                    form={form}
                    type={type}
                    size={size}
                >{text}</Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
            </div>
        </div>
    );
};

export default LoadingButton;
