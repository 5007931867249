import {useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import ErrorBtn from "./ErrorBtn";
import {logoutUser} from "../../actions/authActions";

// Material UI import
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Box from '@material-ui/core/Box';


const PageHeader = ({pageTitle, backRoute}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogout = async () => {
        await dispatch(logoutUser());
        window.location.reload();
    }

    const redirect = (e, path) => {
        e.preventDefault();
        if (path === 'previous') {
            history.goBack();
            return;
        }

        if (path) {
            history.push(path);
        } else {
            history.goBack();
        }
    }

    return (
        <header className="header">

            <AppBar position="static">
                <Toolbar color="secondary" style={{width: "100%", display: "flex", justifyContent: "space-between"}}>


                    <Box style={{display: "flex", alignItems: "center"}}>
                        {pageTitle &&
                            <div className="header__title header__title--sub" onClick={e => redirect(e, backRoute)}>
                                <ArrowBackIcon color="secondary" style={{marginRight: '1.5rem'}}/>
                                {pageTitle}
                            </div>}
                    </Box>
                    <Box>
                        <div className="header__flex">
                            <ErrorBtn/>
                            {window.location.pathname !== '/auth' &&
                                <Button color="secondary" onClick={handleLogout}>Logout</Button>}
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>
        </header>
    );
}

export default PageHeader;