import {axiosInstance as axios} from '../helpers/axios';
import {
    REGISTER_SUCCESS,
    REGISTER_ERROR,
    USER_LOADING,
    CLEAR_USER_LOADING,
    USERS_LOADING,
    CLEAR_USERS_LOADING,
    USERS_ERROR,
    GET_USERS,
    GET_USER,
    USER_ERROR,
    CLEAR_USER
} from "./types";

import {tokenConfig} from "./authActions";
import {setErrors} from "./errorActions";
import {setSuccess} from "./successActions";

export const registerUser = (user) => async (dispatch) => {
    try {

        dispatch({type: USER_LOADING});

        const res = await axios.post('/api/v1/user/register', user, tokenConfig());

        dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data.data
            }
        );

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, REGISTER_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_LOADING});
    }
}


export const updateUser = (user) => async (dispatch) => {
    try {
        dispatch({type: USER_LOADING});

        const res = await axios.put('/api/v1/user/', user, tokenConfig());

        dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data.data
            }
        );

        dispatch(setSuccess(res));

    } catch (err) {
        dispatch(setErrors(err, REGISTER_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_LOADING});
    }
}

export const getAllUser = () => async dispatch => {
    try {
        dispatch({type: USERS_LOADING});

        const res = await axios.get('/api/v1/user', tokenConfig());

        dispatch({
            type: GET_USERS,
            payload: res.data.data
        });

    } catch (err) {
        dispatch(setErrors(err, USERS_ERROR));

    } finally {
        dispatch({type: CLEAR_USERS_LOADING});
    }
}

export const getSingleUser = id => async dispatch => {
    try {
        dispatch({type: USER_LOADING});

        const res = await axios.get(`/api/v1/user/${id}`, tokenConfig());

        dispatch({
            type: GET_USER,
            payload: res.data.data
        });

    } catch (err) {
        dispatch(setErrors(err, USER_ERROR));
    } finally {
        dispatch({type: CLEAR_USER_LOADING});
    }
}

export const clearUser = () => dispatch => {
    dispatch({
        type: CLEAR_USER
    });
}