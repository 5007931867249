import {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAllUser} from '../../../actions/userActions';
import {checkUserPermission, loggedInUser} from "../../../helpers/authHelper";
import {useHistory} from "react-router-dom";
import NoAdmin from "../dashboard/NoAdmin";
import InlineLoading from "../../includes/InlineLoading";

import {DataGridPro} from "@mui/x-data-grid-pro";

// Licensed Data Grid Pro
import {LicenseInfo} from '@mui/x-data-grid-pro';

// Material UI imports
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";


LicenseInfo.setLicenseKey('f46eec4ce6a948aab2dc3b60e13984d0T1JERVI6Mjk4MDQsRVhQSVJZPTE2NjM5NDQxMTEwMDAsS0VZVkVSU0lPTj0x',);

const UserOverview = () => {

    const dispatch = useDispatch()
    const user = loggedInUser();
    const history = useHistory();

    const [rows, setRows] = useState([]);

    const {loading, allUser} = useSelector(state => ({
        loading: state.user.usersLoading, allUser: state.user.allUsers,
    }));

    const loadAllUser = useRef(() => {
        dispatch(getAllUser());
    });

    useEffect(() => {
        loadAllUser.current();
    }, []);

    useEffect(() => {
        const localUsers = [];
        const loggedInAdmin = checkUserPermission(loggedInUser(), 'isAdmin');

        allUser.forEach(user => {
            // Check if logged-in user is admin and can see other admins
            if (checkUserPermission(user, 'isAdmin')) {
                if (loggedInAdmin) {
                    localUsers.push({
                        ...user,
                        id: user._id,
                    });
                }
            } else {
                localUsers.push({
                    ...user,
                    id: user._id,
                });
            }
        });

        setRows(localUsers)

    }, [allUser, setRows]);


    const columns = [{
        field: 'id', headerName: 'id', hide: true,
    }, {
        field: 'username', headerName: 'Benutzername', width: 250, headerClassName: 'overview__data',
    }, {
        field: 'userGroup',
        headerName: 'Gruppe',
        width: 200,
        headerClassName: 'overview__data',
        valueGetter: (params) => {
            return params?.value?.groupTitle;
        }
    }, {
        field: 'firstname', headerName: 'First name', width: 200, headerClassName: 'overview__data',
    }, {
        field: 'surname', headerName: 'Last name', width: 200, headerClassName: 'overview__data',
    }, {
        field: 'active', headerName: 'Login aktiv', type: 'boolean', width: 175, headerClassName: 'overview__data',
    },];

    if (!checkUserPermission(user, 'editUsers')) {
        return <NoAdmin/>
    }


    if (loading) {
        return <InlineLoading/>;
    }

    return (<>
        <DataGridPro
            loading={loading}
            disableSelectionOnClick
            autoHeight={true}
            style={{width: '100%', marginBottom: '2rem'}}
            columns={columns}
            disableMultipleSelection={true}
            onCellDoubleClick={(params) => {
                history.push(`/admin/user/manage/${params.id}`);
            }}
            rows={rows}/>
        <Box mb={5}>
            <Button variant="contained" color="primary" onClick={() => {
                history.push('/admin/user/manage');
            }}>
                Benutzer erstellen</Button>
        </Box>
    </>);
};

export default UserOverview;
