// Global Types
export const SET_LOADING = 'LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_SUCCESS = 'SET_SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';

// User types
export const USER_LOADING = 'USER_LOADING';
export const USERS_LOADING = 'USERS_LOADING';
export const CLEAR_USER_LOADING = 'CLEAR_USER_LOADING';
export const CLEAR_USERS_LOADING = 'CLEAR_USERS_LOADING';
export const USER_ERROR = 'USER_ERROR';
export const USERS_ERROR = 'USERS_ERROR';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const CLEAR_REGISTER_ERROR = 'CLEAR_REGISTER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';

// Auth types
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const CLEAR_LOGIN_LOADING = 'CLEAR_LOGIN_LOADING';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

// Single task types
export const TASK_LOADING = 'TASK_LOADING';
export const CLEAR_TASK_LOADING = 'CLEAR_TASK_LOADING';
export const TASK_ERROR = 'TASK_ERROR';
export const CLEAR_TASK_ERROR = 'CLEAR_TASK_ERROR';
export const GET_TASK = 'GET_TASK';
export const CREATE_TASK = 'CREATE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const CLEAR_TASK = 'CLEAR_TASK';

// State types
export const STATES_LOADING = 'STATES_LOADING';
export const CLEAR_STATES_LOADING = 'CLEAR_STATES_LOADING';
export const GET_STATES = 'GET_STATES';
export const STATES_ERROR = 'STATES_ERROR';

// Single state types
export const TASK_STATE_LOADING = 'TASK_STATE_LOADING';
export const CLEAR_TASK_STATE_LOADING = 'CLEAR_TASK_STATE_LOADING';
export const TASK_STATE_ERROR = 'TASK_STATE_ERROR';

// Payment States types
export const PAYMENT_STATES_LOADING = 'PAYMENT_STATES_LOADING';
export const CLEAR_PAYMENT_STATES_LOADING = 'CLEAR_PAYMENT_STATES_LOADING';
export const GET_PAYMENT_STATES = 'GET_PAYMENT_STATES';
export const PAYMENT_STATES_ERROR = 'PAYMENT_STATES_ERROR';

// User group types
export const USER_GROUPS_LOADING = 'USER_GROUPS_LOADING';
export const CLEAR_USER_GROUPS_LOADING = 'CLEAR_USER_GROUPS_LOADING';
export const GET_USER_GROUPS = 'GET_USER_GROUPS';
export const USER_GROUPS_ERROR = 'USER_GROUPS_ERROR';
export const GET_USER_GROUPS_PRIVILEGES = 'GET_USER_GROUPS_PRIVILEGES';

// Single Payment State
export const PAYMENT_STATE_LOADING = 'PAYMENT_STATE_LOADING';
export const CLEAR_PAYMENT_STATE_LOADING = 'CLEAR_PAYMENT_STATE_LOADING';
export const PAYMENT_STATE_ERROR = 'PAYMENT_STATE_ERROR';

// Task list types
export const TASKS_ERROR = 'TASKS_ERROR';
export const GET_TASKS = 'GET_TASKS';
export const TASKS_LOADING = 'TASKS_LOADING';
export const CLEAR_TASKS_LOADING = 'CLEAR_TASKS_LOADING';

// Customer types
export const CUSTOMER_LOADING = 'CUSTOMER_LOADING';
export const CLEAR_CUSTOMER_LOADING = 'CLEAR_CUSTOMER_LOADING';
export const CUSTOMER_ERROR = 'CUSTOMER_ERROR';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const GET_ALL_CUSTOMER = 'GET_ALL_CUSTOMER';

// Tire types
export const TIRES_LOADING = 'SET_TIRES_LOADING';
export const CLEAR_TIRES_LOADING = 'CLEAR_TIRES_LOADING';
export const TIRE__ERROR = 'TIRE__ERROR';
export const GET_TIRES = 'GET_TIRES';

// Branch types
export const BRANCH_LOADING = 'BRANCH_LOADING';
export const CLEAR_BRANCH_LOADING = 'CLEAR_BRANCH_LOADING';
export const GET_BRANCHES = 'GET_BRANCHES';
export const BRANCH_ERROR = 'BRANCH_ERROR';

// Car types
export const GET_CAR = 'GET_CAR';
export const CAR_ERROR = 'CAR_ERROR';
export const CAR_LOADING = 'CAR_LOADING';
export const CLEAR_CAR_LOADING = 'CLEAR_CAR_LOADING';