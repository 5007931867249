import {
    CLEAR_CUSTOMER_LOADING,
    CUSTOMER_ERROR,
    CUSTOMER_LOADING,
    GET_ALL_CUSTOMER,
    GET_CUSTOMER,
    SET_CUSTOMER
} from "../actions/types";

const initialState = {
    allCustomer: [],
    singleCustomer: null,
    customerLoading: false,
}

const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        // All customer
        case GET_ALL_CUSTOMER:
            return {
                ...state,
                allCustomer: action.payload
            }

        // Single customer handling
        case GET_CUSTOMER:
            return {
                ...state,
                singleCustomer: action.payload,
            }

        case SET_CUSTOMER:
            return {
                ...state,
                singleCustomer: action.payload,
            }

        case CUSTOMER_LOADING:
            return {
                ...state,
                customerLoading: true,
            }

        case CLEAR_CUSTOMER_LOADING:
            return {
                ...state,
                customerLoading: false,
            }


        case CUSTOMER_ERROR:
            return {
                ...state,
                customer: null,
            }
        default:
            return {
                ...state,
            }
    }
}

export default customerReducer;