import {
    GET_PAYMENT_STATES,
    PAYMENT_STATES_ERROR,
    PAYMENT_STATES_LOADING,
    CLEAR_PAYMENT_STATES_LOADING, PAYMENT_STATE_LOADING, CLEAR_PAYMENT_STATE_LOADING,
} from "../actions/types";

const initialState = {
    // All payment states
    allPaymentStates: [],
    paymentStatesLoading: false,

    // Single payment State
    singlePaymentState: {},
    paymentStateLoading: false,
};

const paymentStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PAYMENT_STATES:
            return {
                ...state,
                allPaymentStates: action.payload,
            }

        case PAYMENT_STATES_ERROR:
            return {
                ...state,
                allPaymentStates: [],
            }
        case PAYMENT_STATES_LOADING:
            return {
                ...state,
                paymentStatesLoading: true,
            }
        case CLEAR_PAYMENT_STATES_LOADING:
            return {
                ...state,
                paymentStatesLoading: false,
            }

        // Single payment state
        case PAYMENT_STATE_LOADING:
            return {
                ...state,
                paymentStateLoading: true,
            }
        case CLEAR_PAYMENT_STATE_LOADING:
            return {
                ...state,
                paymentStateLoading: false,
            }

        default:
            return {
                ...state,
            }
    }
}

export default paymentStateReducer;