import {useEffect, useState} from "react";
import {checkUserPermission, isAuthenticated, loggedInUser} from "../../helpers/authHelper";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import ErrorBtn from "./ErrorBtn";
import {logoutUser} from "../../actions/authActions";

// Material UI imports
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';


const Header = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [openNav, setOpenNav] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (isAuthenticated()) {
            setUser(loggedInUser());
        }
    }, []);


    const handleLogout = async () => {
        await dispatch(logoutUser());
        window.location.reload();
    }

    const redirect = (e, path) => {
        e.preventDefault();
        setOpenNav(false);
        history.push(path);
    }

    return (
        <header className="header">

            <AppBar position="static">
                <Toolbar color="secondary" style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <Box style={{display: "flex", alignItems: "center"}}>

                        {user ?
                            <IconButton onClick={() => setOpenNav(!openNav)} edge="start" color="inherit"
                                        aria-label="menu">
                                <MenuIcon color="secondary"/>
                            </IconButton>
                            : <div className="header__title">Pneuhaus Frank - Digitale Arbeitskarte</div>}

                    </Box>
                    <Box>
                        <div className="header__flex">
                            {user &&
                                <>
                                    <ErrorBtn/>
                                    <Button color="secondary" onClick={handleLogout}>Logout</Button>
                                </>}
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>

            <Drawer open={openNav}
                    onClose={() => setOpenNav(false)}>
                <List style={{width: "250px"}}>
                    <ListItem role="presentation" button key={"Übersicht"} onClick={e => redirect(e, '/')}>
                        <ListItemIcon>
                            <ListAltIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Übersicht"/>
                    </ListItem>

                    <ListItem role="presentation" button key={"Lageransicht"} onClick={e => redirect(e, '/warehouse')}>
                        <ListItemIcon>
                            <ListAltIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Ansicht Lagerist"/>
                    </ListItem>


                    {checkUserPermission(user, 'showAdminPage') &&
                        <ListItem role="presentation" button key={"Admin"} onClick={e => redirect(e, '/admin')}>
                            <ListItemIcon>
                                <SupervisedUserCircleIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Admin"/>
                        </ListItem>}
                </List>
            </Drawer>
        </header>
    );
}

export default Header;
