/*
 * Custom axios instance with response interceptor
 * Gets a new token from the server and sets it in the localstorage
 * The refresh token in the cookies is used to get a new token
 */
import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger

        if (error.response.status === 401) {
            // Check refresh token

            const res = await axiosInstance.get('/api/v1/auth/login');

            if (res.data.success) {
                // Set new token
                localStorage.setItem('token', res.data.data.token);

                // Set new axios config
                const config = {
                    ...error.config,
                    headers: {
                        'Content-type': 'application/json'
                    }
                }

                // Add the new token to the headers
                config.headers['X-Auth-Token'] = res.data.data.token;

                // Retry the request
                return axiosInstance(config);
            }

        }

        return Promise.reject(error);
    }
);

export {axiosInstance};