import {combineReducers} from 'redux';
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import successReducer from "./successReducer";
import loadingReducer from "./loadingReducer";
import taskReducer from "./taskReducer";
import customerReducer from "./customerReducer";
import tireReducer from "./tireReducer";
import branchReducer from "./branchReducer";
import userReducer from "./userReducer";
import carReducer from "./carReducer";
import stateReducer from "./stateReducer";
import paymentStateReducer from "./paymentStateReducer";
import userGroupReducer from "./userGroupReducer";

export default combineReducers({
    auth: authReducer,
    error: errorReducer,
    success: successReducer,
    tasks: taskReducer,
    customer: customerReducer,
    loading: loadingReducer,
    tires: tireReducer,
    branch: branchReducer,
    user: userReducer,
    car: carReducer,
    taskStates: stateReducer,
    paymentStates: paymentStateReducer,
    userGroups: userGroupReducer,
});