import React, {useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getTaskById, updateTaskWithState, updateTaskWithTime} from "../../actions/taskActions";
import CustomMenuButton from "../overview/CustomMenuButton";
import {WebSocketContext} from "../../context/Websocket";
import PaymentStateButton from './details/PaymentStateButton';
import Loading from "../includes/Loading";
import StatusChip from "../includes/StatusChip";
import DetailHandler from "./details/DetailHandler";
import {formatDate, formatDateWithMonth, getLastKilometreReading} from "../../helpers/helpers";
import CheckIconWithTitle from "./details/CheckIconWithTitle";
import ArrowDown from "../../assets/img/Arrow";
import PageHeader from "../includes/PageHeader";
import StateButton from "./details/StateButton";

const TaskDetails = () => {
    // Get task id from url params
    const {id} = useParams();
    const dispatch = useDispatch();
    const [carVisible, setCarVisible] = useState(true);
    const [customerVisible, setCustomerVisible] = useState(true);
    const [additionalVisible, setAdditionalVisible] = useState(true);
    let interval;

    // Update task list via websockets
    const {updateWsTaskList} = useContext(WebSocketContext);
    const heightCar = useRef(null);
    const heightCustomer = useRef(null);
    const heightAdditional = useRef(null);

    const {loading, singleTask, taskLoading, success} = useSelector(state => ({
        loading: state.loading,
        success: state.success,
        singleTask: state.tasks.singleTask,
        taskLoading: state.tasks.taskLoading,

    }));
    const getTasksByIdRef = useRef(id => {
        dispatch(getTaskById(id));
    });

    let [time, setTime] = useState(0);
    const [running, setRunning] = useState(false);
    const handleTaskUpdate = async newState => {
        if(newState.stateTitle === "in Bearbeitung"){
            let date = new Date()
            await dispatch(updateTaskWithTime(singleTask, date.getTime()));
            setRunning(true)
        }else{
            setRunning(false)

        }
        await dispatch(updateTaskWithState(singleTask, newState));
    }


    useEffect(() => {
        if (success) {
            updateWsTaskList()
        }
        // eslint-disable-next-line
    }, [success]);

    useEffect(() => {
        if (running) {
            interval = setInterval(() => {
                let newDate = new Date()
                setTime(newDate.getTime() - singleTask?.workingTime);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [running]);



    const idRef = useRef(id);
    // Load task details based on id
    useEffect(() => {
        getTasksByIdRef.current(idRef.current);
    }, []);

    useEffect(() => {
        if (singleTask?.timeCompleted) {
           setTime(singleTask?.workingTimeCompleted - singleTask?.workingTime)
        }
        if (singleTask?.state?.stateTitle === "in Bearbeitung") {
            setRunning(true)
        }

        // eslint-disable-next-line
    }, [singleTask]);

    if (loading) {
        return (<>
            <PageHeader pageTitle={"Auftragsübersicht"} backRoute={"/"}/>
            <div className="container">
                <div className="row">
                    <div className="gr-24"><Loading/></div>
                </div>
            </div>
        </>)
    }

    if (!singleTask) {
        return (
            <>
                <PageHeader pageTitle={"Auftragsübersicht"} backRoute={"/"}/>
                <div className="container">
                    <div className="row">
                        <div className="gr-24">
                            <div className="page__title"> Kein Task mit ID {id} gefunden.</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return (
        <>
            <PageHeader pageTitle={"Auftragsübersicht"} backRoute={"previous"}/>
            <div className="container">
                <div className="row">
                    <div className="gr-24 gr-17@small suffix-1@small order-2 order-1@small">
                        <div className="page__title page__title--flex"><span>Auftrag {singleTask?.taskId}</span>
                            {singleTask && <CustomMenuButton task={{
                                state: singleTask.state,
                                customer: singleTask?.customer,
                                id: singleTask.taskId,
                            }}/>}
                        </div>
                        <div className="row">
                            <div className="gr-4">
                                <div className="page__subtitle">Reihenfolge Nr.</div>
                                <div className="page__order tac">{singleTask.carOrder !== "" ? singleTask.carOrder : "-"}</div>
                            </div>
                            <div className="gr-16">
                                <div className="page__subtitle"></div>
                                <div className="page__order"></div>
                            </div>
                            <div className="gr-4 tac">
                                <div className="stopwatch">
                                    <div className="numbers task__detail__stoppwatch">
                                        <span>{("0" + Math.floor((time  / 3600000) % 60)).slice(-1)}:</span>
                                        <span>{("0" + Math.floor((time  / 60000) % 60)).slice(-2)}:</span>
                                        <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DetailHandler key={1}
                                       tasks={singleTask.tasks}/>
                        {singleTask.comment &&
                            <div className="task__config task__config--white">
                                <div className="config__content">
                                    <div className="page__subtitle">Bemerkung</div>
                                    <div className="page__text">{singleTask.comment}</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="gr-24 gr-5@small prefix-1@small  order-1 order-2@small">
                        <div className="task__detail">
                            <div className="detail__status">
                                <div className="page__subtitle">Status</div>
                                {singleTask.state && <StatusChip state={singleTask.state} size="medium"/>}
                            </div>
                            <div className="page__subtitle page__subtitle--no-mb page__subtitle--gray">
                                Eingangszeit
                            </div>

                            <div className="page__text">{singleTask.createdAt && formatDate(singleTask.createdAt)}</div>
                            {singleTask.timeCompleted &&
                                <>
                                    <div
                                        className="page__subtitle page__subtitle--no-mb page__subtitle--gray">Abschlusszeit
                                    </div>
                                    <div
                                        className="page__text">{singleTask.timeCompleted && formatDate(singleTask.updatedAt)}</div>
                                </>}


                            <StateButton updateLoading={taskLoading} state={singleTask?.state}
                                         updateFunction={handleTaskUpdate} task={singleTask}
                                         style={{marginBottom: '1rem'}}/>


                            <div className="detail__status">
                                <div className="page__subtitle">Bezahlstatus</div>
                                {singleTask?.paymentState &&
                                    <StatusChip state={singleTask.paymentState} size="medium"/>}
                            </div>

                            <PaymentStateButton task={singleTask}
                                                updateLoading={taskLoading}
                            />


                            <div className="detail__accordions">
                                {singleTask.car &&
                                    <div className="detail__accordion">

                                        <div className="accordion__header" onClick={() => setCarVisible(!carVisible)}>
                                            <div className="accordion__title">Fahrzeug</div>
                                            <div
                                                className={carVisible ? "accordion__icon" : "accordion__icon accordion__icon--closed"}>
                                                <ArrowDown/>
                                            </div>
                                        </div>
                                        <div className="accordion__content" ref={heightCar}
                                             style={{height: carVisible ? heightCar.current?.scrollHeight + "px" : 0}}>
                                            <div className="content__inner">

                                                <div className="page__subtitle page__subtitle--gray">Kontrollschild
                                                </div>
                                                <div
                                                    className="page__text">{singleTask.car.canton} {singleTask.car.controlSign}</div>

                                                {singleTask.car?.leasing &&
                                                    <CheckIconWithTitle title={'Leasingfahrzeug'}/>}

                                                {singleTask.car.color &&
                                                    <>
                                                        <div className="page__subtitle page__subtitle--gray">Modell und
                                                            Ausführung
                                                        </div>
                                                        <div className="page__text">
                                                            {singleTask.car.color &&
                                                                <div className="overview__badge"
                                                                     style={{background: singleTask.car.color}}/>}
                                                            {singleTask.car?.maker} {singleTask.car?.model}</div>
                                                    </>}
                                                {singleTask?.car?.description &&
                                                    <>
                                                        <div
                                                            className="page__subtitle page__subtitle--gray">Beschreibung
                                                        </div>
                                                        <div className="page__text">{singleTask.car.description}</div>
                                                    </>}
                                            </div>

                                        </div>

                                    </div>}

                                {singleTask?.customer &&
                                    <div className="detail__accordion">
                                        <div className="accordion__header"
                                             onClick={() => setCustomerVisible(!customerVisible)}>
                                            <div className="accordion__title">Kunde</div>
                                            <div
                                                className={customerVisible ? "accordion__icon" : "accordion__icon accordion__icon--closed"}>
                                                <ArrowDown/></div>
                                        </div>
                                        <div className="accordion__content" ref={heightCustomer}
                                             style={{height: customerVisible ? heightCustomer.current?.scrollHeight + "px" : 0}}>
                                            <div className="content__inner">
                                                <div
                                                    className="page__text">
                                                    {singleTask.customer.company && (<>{singleTask.customer.company}<br/></>)}
                                                    {singleTask.customer?.gender ? singleTask.customer.gender === 'male' ? (<>Herr<br/></>) : (<>Frau<br/></>) : ""}
                                                    {singleTask.customer?.firstname} {singleTask.customer.surname && (<>{singleTask.customer.surname}<br/></>)}
                                                    {singleTask.customer.street && (<>{singleTask.customer.street}<br/></>)}
                                                    {singleTask.customer?.plz} {singleTask.customer.place && (<>{singleTask.customer.place}<br/></>)}
                                                </div>

                                                <div className="page__text">
                                                    {singleTask.customer.mail && (<><a
                                                        href={`mailto:${singleTask.customer.mail}`}>{singleTask.customer.mail}</a><br/></>)}
                                                    {singleTask.customer?.phone}
                                                </div>

                                                {singleTask.customer?.kilometreReading && getLastKilometreReading(singleTask.customer?.kilometreReading) &&
                                                    <div className="page__text">
                                                        Letzter Kilometerstand
                                                        am {formatDateWithMonth(getLastKilometreReading(singleTask.customer?.kilometreReading)?.date)}:<br/>
                                                        {getLastKilometreReading(singleTask.customer?.kilometreReading)?.reading} km
                                                    </div>}

                                            </div>
                                        </div>
                                    </div>}

                                {singleTask?.additional?.preferredLift &&
                                    <div className="detail__accordion">

                                        <div className="accordion__header"
                                             onClick={() => setAdditionalVisible(!additionalVisible)}>
                                            <div className="accordion__title">Zusätzliches</div>
                                            <div
                                                className={additionalVisible ? "accordion__icon" : "accordion__icon accordion__icon--closed"}>
                                                <ArrowDown/></div>
                                        </div>
                                        <div className="accordion__content" ref={heightAdditional}
                                             style={{height: additionalVisible ? heightAdditional.current?.scrollHeight + "px" : 0}}>
                                            <div className="content__inner">
                                                {singleTask.additional?.preferredLift !== 'none' &&
                                                    <>
                                                        <div
                                                            className="page__subtitle page__subtitle--gray">Liftempfehlung
                                                        </div>
                                                        <div
                                                            className="page__text">Lift {singleTask.additional?.preferredLift}</div>
                                                    </>}

                                                {singleTask.additional?.deliveryNote &&
                                                    <CheckIconWithTitle title={'Lieferschein gewünscht'}/>}


                                                {singleTask.car?.leased &&
                                                    <CheckIconWithTitle title={'Leasing Kunde'}/>}
                                            </div>

                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*     {feedback &&
                <InlineFeedback open={true} message={feedback?.text} closeFunc={handleClose} error={feedback?.error}/>}*/}
        </>
    )
}

export default TaskDetails;